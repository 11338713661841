import React, { useMemo, useContext } from "react";
import { AppStore } from "../../Store";

import "./index.sass";

export const WelcomeView = () => {
  const { user } = useContext(AppStore);

  const name = useMemo(() => {
    if (!user) return "lucky one!";

    return user.firstName;
  }, [user]);

  return (
    <div className="welcome-log-in-container">
      <p className="m-0 fw-bold">Hi {name}</p>
      <p>Track the status of every single health indicator</p>
    </div>
  );
};

export default WelcomeView;
