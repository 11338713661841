import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useSync } from "../sync";
import { useNavigate } from "@tanstack/react-router";
import classNames from "classnames";
import {
  magic,
  magicAutoComplete,
  query as queryRunSearch,
} from "../../../api/query";
import { runCommand } from "../../utils";
import { Messages, OsEventType } from "../../../types";
import { AppStore } from "../../Store";
import { AutoComplete } from "../autocomplete";

// Hooks
import { useGetLocation } from "../../hooks/useGetPosition";

// Assets
import sync_black from "../../assets/sync_black.svg";
import search_black from "../../assets/search_black.svg";
import search_close from "../../assets/close_black.svg";
import search_voice_black from "../../assets/search_voice_black.svg";
import magic_icon from "../../assets/magic.png";
import magic_icon_v2 from "../../assets/brand_icon_v6.svg";
import attach_file from "../../assets/attach_file.svg";

// Styles
import "./style.sass";

export const MAX_TITLE_CHARACTERS = 40;
export const LOCK_SEARCH_DELAY = 1000;

export const textEllipsis = (str, maxChr) => {
  return str.length > maxChr ? str.slice(0, maxChr).concat("...") : str;
};

export const SEARCH_OPTIONS_IS_ENABLED = false;
export const FORCE_PROMPT_MODE = true;
export const IS_AUTO_COMPLETE_ENABLED = false;

export const htmlToPlainText = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const value = doc.body.textContent || "";
  return value;
};

export const getInnerContent = (element) => element.textContent;

export const Search = ({
  className,
  syncClassName = "floating",
  isHeadless,
  appendMessage,
  isDisabled,
  setIsDisabled,
                         onFilesUploadCallback
}) => {
  const { user, setChat, setData, setSnapshots, setLoader } =
    useContext(AppStore);
  const fileInputRef = useRef(null);
  const location = useGetLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState({ value: "" });
  const [searchValueTemp, setSearchValueTemp] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const inputRef = useRef(null);
  const [lastSearchRequest, setLastSearchRequest] = useState(null);
  const [searchLock, setSearchLock] = useState({ state: false, query: null });
  const searchRef = useRef(null);
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [isPromptMode, setIsPromptMode] = useState(FORCE_PROMPT_MODE);
  const [promptMeta, setPromptMeta] = useState({});
  const [isMetaDetailsShown, setIsMetaDetailsShown] = useState(false);
  const isLoading = useMemo(() => {
    return lastSearchRequest !== null || searchLock.state;
  }, [lastSearchRequest, searchLock]);

  const { handleFilesUpload, setDroppedFiles, render } = useSync({
    className: syncClassName,
    isCommit: true,
    isFilePickerEnabled: true,
    onFilesUploadCallback
  });

  const clearPrompt = useCallback(
    (event) => {
      if (!FORCE_PROMPT_MODE) setIsPromptMode(false);

      if (event) event.target.textContent = "";

      setPromptMeta({});
      setSearchValue({ value: "" });
      setSearchValueTemp("");
    },
    [setIsPromptMode, setPromptMeta, setSearchValue, setSearchValueTemp]
  );

  const runPrompt = useCallback(
    (prompt) => {
      if (isDisabled) return;

      setLoader({
        isFirstPrompt: true,
      });
      setIsDisabled(true);

      const getMessagePersona = (persona) => {
        const defaultPersona = {
          abbr: "WU",
          color: "#e74c3c",
          sender: "Wild Unicorn",
        };

        if (!persona) return defaultPersona;

        return {
          abbr: persona.abbr,
          color: persona.color,
          sender: persona.sender,
        };
      };

      appendMessage("chat", {
        ...getMessagePersona(user.persona),
        output: {
          raw: prompt,
        },
        direction: "outgoing",
      });

      const startMagic = (refs = null) => {
        return magic({
          event: OsEventType.PromptSnapshot,
          prompt: {
            raw: prompt,
            refs,
          },
          location,
          createdAt:
            promptMeta?.createdAt?.toISOString() || new Date().toISOString(),
        }).then((response) => {
          console.log("Prompt Response: ", response);

          setChat(response["chat"]);
          setSnapshots(response?.["snapshots"] ?? []);
          setData(response["state"]);
        });
      };

      let handle = handleFilesUpload();
      if (handle) {
        handle = handle.then((response) => {
          const { tasks } = response;
          setData(response["state"]);
          return startMagic([
            {
              tasks: tasks,
              type: "documents",
            },
          ]);
        });
      } else {
        handle = startMagic();
      }

      handle
        .then(() => {
          // After successfully completing all the tasks redirect to main view.
          if (isHeadless) {
            navigate({
              to: "/",
            });
          }
        })
        .catch((err) => {
          // Do error handling.
          console.log("Error handling: ", err);
        })
        .finally(() => {
          setLoader({
            isFirstPrompt: false,
          });
          setIsDisabled(false);
        });
    },
    [
      user,
      location,
      promptMeta,
      clearPrompt,
      isDisabled,
      setIsDisabled,
      isHeadless,
    ]
  );

  useEffect(() => {
    if (!IS_AUTO_COMPLETE_ENABLED) return;
    if (!searchValueTemp) return;

    magicAutoComplete({
      query: searchValueTemp,
    }).then((response) => {
      console.log("Auto Complete: ", response);
    });
  }, [searchValueTemp]);

  useEffect(() => {
    if (isPromptMode) return;

    const { value: query } = searchValue;
    if (query.length === 0) {
      setSearchResult(null);
      setLastSearchRequest(null);
    } else if (searchLock.query !== query) {
      if (!searchLock.state) {
        let querySearch = query;
        if (lastSearchRequest) {
          querySearch = lastSearchRequest;
          setLastSearchRequest(null);
        }

        setSearchLock({ state: true, query: querySearch });
        queryRunSearch({ query: querySearch, location })
          .then(setSearchResult)
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setSearchLock((state) => ({ ...state, state: false }));
          });
      } else {
        setLastSearchRequest(query);
      }
    }
  }, [searchValue, searchLock, lastSearchRequest, isPromptMode]);

  useEffect(() => {
    let callback = (event) => {
      if (!searchRef.current) return;

      if (!searchRef.current.contains(event.target)) {
        setSearchIsFocused(false);
      }
    };

    document.addEventListener("click", callback);
    return () => {
      document.removeEventListener("click", callback);
    };
  }, [searchRef.current]);

  const searchOptions = [
    <div
      key="info"
      className="player-option"
      onClick={() => {
        // setToggleInfo((state) => !state);
      }}
    >
      <img
        className={classNames("player-option-icon controls-option-info", {
          // active: search,
        })}
        src={search_voice_black}
        alt="Info"
      />
    </div>,
  ];

  const openDir = (path) => {
    runCommand(Messages.OpenDir, { path });
  };

  const openFile = (path) => {
    runCommand(Messages.OpenFile, { path });
  };

  // Matches the YouTube UX.
  const isSearching = isDisabled;

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = () => {
      if (inputRef) inputRef.current.focus();
    };

    window.ipcRenderer.on(Messages.AutoFocus, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.AutoFocus, listener);
    };
  }, []);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (_, args) => {
      const { createdAt } = args;
      setIsPromptMode(true);
      setPromptMeta({ createdAt });
      if (inputRef && inputRef.current) inputRef.current.focus();
    };

    window.ipcRenderer.on(Messages.Intent, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.Intent, listener);
    };
  }, []);

  useEffect(() => {
    if (!isPromptMode) return;

    if (inputRef && inputRef.current) inputRef.current.focus();
  }, [isPromptMode, inputRef.current]);

  if (syncClassName.includes("multi-step")) {
    return <>{render()}</>;
  }

  return (
    <div
      className={classNames(
        "search-bar",
        {
          active: searchIsFocused,
          filled: !!searchResult,
          extended: isPromptMode,
          disabled: isDisabled,
        },
        className
      )}
      ref={searchRef}
      onFocus={() => setSearchIsFocused(true)}
    >
      {render()}
      <>
        <div className="search-container">
          {isPromptMode ? (
            <>
              {isHeadless ? (
                <div
                  className="prompt"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <img src={attach_file} alt="Magic Icon" />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const selectedFiles = Array.from(event.target.files);
                      setDroppedFiles(selectedFiles);
                    }}
                    multiple
                  />
                </div>
              ) : (
                <div className="prompt">
                  <img src={magic_icon_v2} alt="Magic Icon" />
                  {/*<span className="prompt-title">Prompt</span>*/}
                </div>
              )}
              <div className="prompt-input">
                <span
                  ref={inputRef}
                  className={classNames("search-input editable", {
                    empty: !searchValueTemp,
                  })}
                  placeholder="Enter your input"
                  role="textbox"
                  contentEditable={true}
                  // contentEditable="plaintext-only" // On Firefox not working.
                  suppressContentEditableWarning={true}
                  autoFocus={true}
                  dangerouslySetInnerHTML={{
                    __html: htmlToPlainText(searchValue.value),
                  }}
                  onKeyDown={(event) => {
                    let value = getInnerContent(event.target);

                    if (
                      event.key === "Enter" &&
                      !event.shiftKey &&
                      !isDisabled
                    ) {
                      event.preventDefault();
                      clearPrompt(event);
                      runPrompt(value);
                      return;
                    }

                    if (
                      value.trim().length === 0 &&
                      event.key === "Backspace"
                    ) {
                      clearPrompt();
                      return;
                    }

                    if (event.key === "Enter" && event.shiftKey) {
                      document.execCommand("insertLineBreak");
                      event.preventDefault();
                    }

                    setSearchValueTemp(value);
                  }}
                  onBlur={(event) => {
                    if (isDisabled) return;

                    const value = getInnerContent(event.target);
                    setSearchValue({ value });
                    setSearchValueTemp(value);
                  }}
                  aria-label="Search"
                />
                <AutoComplete
                  inputRef={inputRef}
                  query={searchValueTemp}
                  setQuery={setSearchValue}
                />
              </div>
            </>
          ) : (
            <>
              <img
                className={classNames("search-icon search-loading", {
                  hidden: !isSearching,
                })}
                src={sync_black}
                alt="Search Loading"
              />
              <img
                className={classNames("search-icon", {
                  hidden: isSearching,
                })}
                src={search_black}
                alt="Search Icon"
              />
              <input
                ref={inputRef}
                className="search-input"
                type="text"
                value={searchValue.value}
                disabled={isDisabled}
                onChange={(event) => {
                  const value = event.target.value;
                  setSearchValueTemp(value);
                  setSearchValue({ value });
                }}
                placeholder="Search, chat, magic"
                aria-label="Search"
              />
            </>
          )}
          {!isDisabled && (
            <img
              className={classNames("search-clear", {
                active: searchValueTemp.length > 0,
              })}
              src={search_close}
              onClick={() => {
                setSearchValueTemp("");
                setSearchValue({ value: "" });
              }}
              alt="Search Clear"
            />
          )}
          {!isPromptMode && (
            <img
              className="btn-power-key"
              src={magic_icon}
              onClick={() => {
                magic({
                  event: OsEventType.Status,
                  location,
                }).then((response) => {
                  setChat(response["chat"]);
                  clearPrompt();
                });
              }}
              alt="Magic Icon"
            />
          )}
        </div>
        <div
          className={classNames("search-results", {
            active: !!searchResult && searchIsFocused,
          })}
        >
          {searchResult && (
            <div>
              <div className="search-options-label">
                <span>{isLoading ? "Searching..." : searchResult.label}</span>
              </div>
              {searchResult.items.map((result) => {
                const resultTitle = textEllipsis(
                  result.title,
                  MAX_TITLE_CHARACTERS
                );

                const ext = result.title
                  .slice(result.title.lastIndexOf(".") + 1)
                  .toLowerCase();
                const key = `${result.id}${result.path}`;
                return (
                  <div
                    key={key}
                    className="player-info"
                    onClick={() => openFile(result.path)}
                  >
                    {["svg", "png", "jpg", "jpeg", "gif", "ico"].includes(
                      ext
                    ) ? (
                      <img
                        className="result-thumbnail"
                        src={`atom://${encodeURI(result.path)}`}
                        alt="Thumbnail"
                      />
                    ) : (
                      <i
                        className={classNames(
                          "result-icon bi",
                          `bi-filetype-${ext}`
                        )}
                      />
                    )}

                    <div className="track-meta">
                      <p className="track-meta-label track-title">
                        {resultTitle}
                      </p>
                      {isMetaDetailsShown && (
                        <div className="track-meta-metrics">
                          <span className="track-meta-label track-views">
                            {result.score.toFixed(2)} score
                          </span>
                        </div>
                      )}
                      <p className="track-meta-label track-uploader">
                        {textEllipsis(result.path, MAX_TITLE_CHARACTERS)}
                      </p>
                    </div>
                    <i
                      className={classNames(
                        "track-action-secondary bi bi-box-arrow-up-right"
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                        openDir(result.path);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {SEARCH_OPTIONS_IS_ENABLED && (
          <>
            <div className="divider" />
            <div className="player-options">{searchOptions}</div>
          </>
        )}
      </>
    </div>
  );
};

export default Search;
