import React from "react";

export const TaskHeader = ({ task }) => {
  return (
    <div className="d-flex flex-row">
      <h5 className="d-inline-flex card-title">{task.name}</h5>
      <div>
        <span className="badge badge-secondary badge-outline">{task.type}</span>
      </div>
    </div>
  );
};

const LeadTask = ({ task }) => (
  <div>
    <p>{task.description}</p>
  </div>
);

const TodoTask = ({ task }) => (
  <div>
    <p>{task.description}</p>
  </div>
);

const FeatureTask = ({ task }) => (
  <div>
    <p>{task.description}</p>
  </div>
);

const TaskComponent = ({ task }) => {
  switch (task.type) {
    case "lead":
      return <LeadTask task={task} />;
    case "to-do":
      return <TodoTask task={task} />;
    case "feature":
      return <FeatureTask task={task} />;
    default:
      return null;
  }
};

export default TaskComponent;
