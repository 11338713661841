import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TargetBox } from "./TargetBox.js";
import { Messages } from "../../../types";
import { AppStore } from "../../Store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getAuthorizeUrl, getUUIDv4, runCommand } from "../../utils";
import { uploadFiles } from "../../../api/query";
import classNames from "classnames";
import { useNavigate } from "@tanstack/react-router";
import { maxTotalFileSize, allowedFileTypes } from "../../config";

import "./style.sass";
import { AppRoute, AuthorizeMode } from "../../../interfaces";

export const useSync = ({
  className,
  isDisabled = false,
  isFilePickerEnabled = false,
  isCommit,
  onFilesUploadCallback,
}) => {
  const { jobs, errors } = useContext(AppStore);
  const navigate = useNavigate();
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [processingId, setProcessingId] = useState(null);

  const clearAttachments = useCallback(() => {
    setProcessingId(null);
    setDroppedFiles([]);
  }, [setProcessingId, setDroppedFiles]);

  useEffect(() => {
    if (
      jobs.some(({ id, status }) => id === processingId && status !== -1) ||
      errors.some(({ id }) => id === processingId)
    ) {
      clearAttachments();
    }
  }, [jobs, errors, clearAttachments]);

  const isDropped = useMemo(() => {
    return droppedFiles.length > 0 && processingId !== null;
  }, [droppedFiles, processingId]);

  const totalFileSize = useMemo(() => {
    return droppedFiles.reduce((acc, file) => acc + file.size, 0);
  }, [droppedFiles]);

  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const files = item.files;

        const validFiles = [];
        for (const file of files) {
          if (!allowedFileTypes.includes(file.type)) {
            continue;
          }

          validFiles.push(file);
        }

        setDroppedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      }
    },
    [setDroppedFiles]
  );

  const handleFileRemoval = useCallback(
    (target) => {
      setDroppedFiles((files) => files.filter((file) => file !== target));
    },
    [setDroppedFiles]
  );

  const handleFilesUpload = useCallback(() => {
    if (droppedFiles.length === 0) return;

    // Signal file upload event.
    if (onFilesUploadCallback) onFilesUploadCallback();

    const formData = new FormData();
    for (const file of droppedFiles) {
      formData.append("file[]", file, file.name);
    }

    return uploadFiles(formData)
      .then((response) => {
        console.log("Response: ", response);
        if (response && response.upload_session_id != null) {
          // Persist to local storage.
          localStorage.setItem("upload_session", JSON.stringify(response));

          // The user is not authenticated, redirect with login session.
          navigate({
            to: getAuthorizeUrl(AuthorizeMode.SignUp),
          });
        }

        if (className && className.includes("normal-flow")) {
          // Navigate to artifacts view if the upload process started from another view.
          navigate({
            to: AppRoute.Artifacts,
          });
        }
      })
      .finally(() => {
        clearAttachments();
      });
  }, [onFilesUploadCallback, droppedFiles, navigate]);

  useEffect(() => {
    if (droppedFiles.length > 0) {
      // Send all the files for processing.
      const files = droppedFiles.map((file) => ({
        path: file.path,
        type: file.type,
      }));

      // Generate a hash or avoid processing same folders.
      const id = getUUIDv4(false);
      setProcessingId(id);

      runCommand(Messages.FileUpload, { files, id });
    }
  }, [droppedFiles]);

  return {
    handleFilesUpload,
    setDroppedFiles,
    render: () => (
      <DndProvider backend={HTML5Backend} dis>
        <TargetBox
          className={classNames("sync", className, {
            picker: isFilePickerEnabled,
          })}
          isFilePickerEnabled={isFilePickerEnabled}
          isCommit={isCommit}
          onDrop={handleFileDrop}
          onRemove={handleFileRemoval}
          isDropped={isDropped}
          totalFileSize={totalFileSize}
          droppedFiles={droppedFiles}
          setDroppedFiles={setDroppedFiles}
          handleFilesUpload={handleFilesUpload}
          isDisabled={isDisabled}
        />
      </DndProvider>
    ),
  };
};

export default useSync;
