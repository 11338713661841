import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import classNames from "classnames";
import { Messages } from "../../../types";
import { runCommand } from "../../utils";

// Assets workspace
import generic_icon from "../../assets/dummy/generic_icon.svg";

// Styles
import "./style.sass";

export const WorkspacePanel = ({
  container,
  show,
  target,
  setShow,
  workspaces,
}) => {
  const openDir = (path) => {
    runCommand(Messages.OpenFile, { path });
  };

  return (
    <Overlay
      rootClose
      onHide={() => setShow(false)}
      show={show}
      target={target}
      placement="bottom"
      container={container}
      containerPadding={40}
      style={{ backgroundColor: "rgb(106, 71, 183)" }}
    >
      <Popover style={{ backgroundColor: "rgb(106, 71, 183)" }}>
        <Popover.Body>
          <div className="workspaces-container mb-4 gap-2">
            {workspaces.map((workspace) => {
              const { name, asset, path, description } = workspace;
              return (
                <div
                  className="workspace-container"
                  onClick={() => openDir(path)}
                >
                  <div className="workspace">
                    <img
                      src={asset ?? generic_icon}
                      className={classNames({ generic: asset === null })}
                      alt={description}
                    />
                  </div>
                  <span className="workspace-title m-0">{name}</span>
                </div>
              );
            })}
          </div>
          <figcaption className="figure-caption text-white text-end mt-2">
            Based on the most recent activity.
          </figcaption>
        </Popover.Body>
      </Popover>
    </Overlay>
  );
};

export default WorkspacePanel;
