import { IAction } from "../api/query";

export enum Messages {
  Ready = "Ready",
  FileUpload = "FileUpload",
  Notification = "Notification",
  Intent = "Intent",
  Job = "Job",
  OpenDir = "OpenDir",
  OpenFile = "OpenFile",
  Undo = "Undo",
  Sync = "Sync",
  SyncDevice = "SyncDevice",
  AppConfig = "AppConfig",
  AutoFocus = "AutoFocus",
  ListFileDir = "ListFileDir",
  Progress = "Progress",
  OpenBrowseSystem = "OpenBrowseSystem",
  CancelBrowseSystem = "CancelBrowseSystem",
  SaveBrowseSystem = "SaveBrowseSystem",
}

export enum WatchActionType {
  IncludeFile = "IncludeFile",
  ExcludeFile = "ExcludeFile",
}

export enum AppFileDir {
  Home,
  Desktop,
  Root,
  Any,
  Watch,
}

export enum ActionType {
  RENAME = "RENAME",
  MOVE = "MOVE",
  COPY = "COPY",
  CLICK = "CLICK",
}

export enum OsEventType {
  Download = "Download",
  PromptSnapshot = "PromptSnapshot",
  Status = "Status",
  Clean = "Clean",
  State = "State",
  Revert = "Revert",
}

export enum NotificationType {
  Download = "Download",
  Rename = "Rename",
  Magic = "Magic",
}

export interface NotificationActionBase {
  type: NotificationType;
  description: string;
  timestamp: number;
}

export interface NotificationDownload extends NotificationActionBase {
  type: NotificationType.Download;
  title: string;
  newTitle: string;
  dir: string;
  newDir: string;
  path: string;
}

export interface NotificationSimple extends NotificationActionBase {
  type: NotificationType.Rename;
  title: string;
  newTitle: string;
}

export interface NotificationMagic extends NotificationActionBase {
  type: NotificationType.Magic;
  title: string;
  body: string;
  actions: IAction[];
}

export type Notifications =
  | NotificationDownload
  | NotificationSimple
  | NotificationMagic;
