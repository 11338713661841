import React from "react";

import Form from "react-bootstrap/Form";

import folder_icon from "../../assets/tree/files/folder_icon.svg";
import file_icon from "../../assets/tree/files/file_icon.svg";

export const FileNode = ({ tree, node, style, dragHandle }) => {
  return (
    <div
      className={`node-container ${node.state.isSelected ? "isSelected" : ""}`}
      style={style}
      ref={dragHandle}
    >
      <div
        className="node-content"
        onClick={() => node.isInternal && node.toggle()}
      >
        {node.isLeaf || !node.data.isDirectory ? (
          <>
            <span className="arrow"></span>
          </>
        ) : (
          <>
            <span className="arrow">
              {node.isOpen ? (
                <i className="bi bi-chevron-down"></i>
              ) : (
                <i className="bi bi-chevron-right"></i>
              )}
            </span>
          </>
        )}
        <span className="file-folder-icon">
          {node.data.isDirectory ? (
            <img src={folder_icon} className="node-asset" alt="Folder" />
          ) : (
            <img src={file_icon} className="node-asset" alt="File" />
          )}
        </span>
        <span className="node-text">
          {node.isEditing ? (
            <input
              type="text"
              defaultValue={node.data.name}
              onFocus={(e) => e.currentTarget.select()}
              onBlur={() => node.reset()}
              onKeyDown={(e) => {
                if (e.key === "Escape") node.reset();
                if (e.key === "Enter") node.submit(e.currentTarget.value);
              }}
              autoFocus
            />
          ) : (
            <span>{node.data.name}</span>
          )}
        </span>
      </div>

      <div className="file-actions">
        <div className="folder-file-actions">
          <Form.Check
            checked={!!node.data.isChecked}
            type="checkbox"
            label={null}
            onClick={() => tree.handle(node)}
          />
        </div>
      </div>
    </div>
  );
};

export default FileNode;
