import { v4 as uuidv4 } from "uuid";
import { severBaseURL } from "../../api";
import { IS_HASH_HISTORY_ENABLED } from "../config";
import { AppRoute } from "../../interfaces";

export const getTimeAgo = (input) => {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat("en");

  // Record<RelativeTimeFormatUnit, number>
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 60,
  };

  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (let key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(Math.round(delta), key);
    }
  }

  return "just now";
};

export const inspectObj = (obj) => {
  console.log(obj, typeof obj);
  for (const [key, value] in Object.entries(obj)) {
    console.log(key, typeof value);
  }
};

export const getUUIDv4 = (dev = false) => {
  if (dev) return "f18b8ed1-94b3-4974-8cdc-6b0f18164a9f";

  return uuidv4();
};

export const runCommand = (command, args) => {
  // Sort-it out: ...args
  if (!window.ipcRenderer) return;

  console.log(args);
  window.ipcRenderer.send(command, args);
};

export const attachListener = (command, listener) => {
  if (!window.ipcRenderer) return;

  window.ipcRenderer.on(command, listener);

  return () => {
    window.ipcRenderer.removeListener(command, listener);
  };
};

export const parseDate = (date) => {
  try {
    if (date.includes("+")) return new Date(date);

    return new Date(date + "Z");
  } catch {
    return null;
  }
};

export const parseStrToInt = (value, defaultValue = null) => {
  try {
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue)) return defaultValue;

    return parsedValue;
  } catch (e) {
    return defaultValue;
  }
};

export const getDateNowFormatted = (date) => {
  try {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(parseDate(date));
  } catch {
    return date;
  }
};

export const getResourceDataUrl = (url, type) => {
  return `${severBaseURL}/static?url=${url}&type=${type}`;
};

export const getAuthorizeUrl = (mode = null, planId = null) => {
  const searchQuery = new URLSearchParams();
  if (mode) searchQuery.set("mode", mode);

  if (planId) searchQuery.set("plan_id", planId);

  return `${AppRoute.Authorize}?${searchQuery.toString()}`;
};

export const matchUrl = (value, full = false, locationRef = null) => {
  if (locationRef == null) {
    locationRef = location;
  }

  if (full) return locationRef.href.includes(value);

  if (IS_HASH_HISTORY_ENABLED) {
    return locationRef.hash.includes(value);
  } else {
    return locationRef.pathname.includes(value);
  }
};

export const isUserAdmin = (user) => {
  return user && user.role === "admin";
};
