import React from "react";
import { Link } from "@tanstack/react-router";
import { AppRoute } from "../../../interfaces";
import { HeaderBrand } from "../header";

import "./style.sass";

export const DefaultComponent = (props) => {
  const { type } = props;

  // TODO: Error logger.
  return (
    <div className="default-component">
      <HeaderBrand className="mb-2" />
      <p className="default-component__message">{type === "error" ? props.error : "Not found!"}</p>
      <Link to={AppRoute.Main}>Go home</Link>
    </div>
  );
};
