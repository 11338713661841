import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState, useMemo, useContext } from "react";
import router from "../../router/index";
import { AppStore } from "../../Store";
import { Messages } from "../../../types";
import classNames from "classnames";

// Assets
import close_icon from "../../assets/close_black.svg";
import privacy_icon from "../../assets/privacy.svg";
import edit_icon from "../../assets/edit.png";

// Styles
import "./style.sass";

export const AppConfig = () => {
  const { config } = useContext(AppStore);

  const onBack = () => router.history.back();

  // TODO: Might make it reusable by having custom object for all the keys.
  const [apiKey, setApiKey] = useState("");
  const [isFullyPrivateMode, setIsFullyPrivateMode] = useState(true);
  const isDisabled = useMemo(() => {
    return apiKey.length === 0;
  }, [apiKey]);

  return (
    <div className="config p-4">
      <div className="config-header">
        <img
          className="config-close"
          src={close_icon}
          onClick={onBack}
          alt="Close App Settings"
        />
      </div>
      <p className="config-header">App Settings</p>
      <Form className="mt-4">
        <p className="config-category">Account</p>
        <p className="small text-success fw-bold">Status: License activated</p>
        <Form.Label className="m-0">Email</Form.Label>
        <Form.Control
          id="account-email"
          type="email"
          size="sm"
          placeholder="Cchivriga@hotmail.com"
          disabled={true}
        />
      </Form>
      {config && (
        <Form className="mt-4">
          <p className="config-category">Search</p>
          <p className="small text-muted m-0">
            {" "}
            {config.sizeDir} files in sync
          </p>
          <Form.Text id="searchSyncInclude" muted>
            Directories to sync: {config.watchFiles.length} |{" "}
          </Form.Text>
          <Form.Text id="searchSyncExclude" muted>
            Excluded directories: {config.excludedFiles.length}
          </Form.Text>
          <br />
          {/*{config.watchFiles.map((dir) => (*/}
          {/*  <div key={dir} className="mb-1">*/}
          {/*    <Form.Check*/}
          {/*      className="small"*/}
          {/*      id={dir}*/}
          {/*      checked={true}*/}
          {/*      type="checkbox"*/}
          {/*      label={dir}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*))}*/}
          <div
            className="config-action-btn mt-2"
            onClick={() => {
              window.ipcRenderer.send(Messages.OpenBrowseSystem);
            }}
          >
            <img
              className="config-icon"
              src={edit_icon}
              alt="Edit Search Directories"
            />
            <span className="small">Edit</span>
          </div>
        </Form>
      )}
      <Form className="mt-4">
        <p className="config-category">Privacy</p>
        <Form.Check
          checked={isFullyPrivateMode}
          type="checkbox"
          label="Fully private mode"
          onChange={() => setIsFullyPrivateMode((state) => !state)}
        />
        <div
          className={classNames("config-subcategory mt-2", {
            disabled: isFullyPrivateMode,
          })}
        >
          <div className="config-subheader">
            <img
              className="config-icon overlay"
              src={privacy_icon}
              alt="Key Protected"
            />
            <Form.Label className="m-0 ms-2">OpenAI API Key</Form.Label>
          </div>
          <div className="d-flex">
            <Form.Control
              id="openai-api-key"
              type="password"
              size="sm"
              placeholder="My Key"
              disabled={isFullyPrivateMode}
              onChange={(event) => {
                setApiKey(event.target.value);
              }}
              aria-describedby="apiKeyHelpBlock"
            />
            <Button
              className="config-action"
              type="submit"
              size="sm"
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
          <Form.Text id="apiKeyHelpBlock" muted>
            Note: Improves text quality generation with better suggestions and
            reliability, with the{" "}
            <b>trade-off of a third party processing your personal data</b>.
          </Form.Text>
        </div>
      </Form>
    </div>
  );
};

export default AppConfig;
