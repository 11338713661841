import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import Badge from "react-bootstrap/Badge";

import { AppStore } from "../../Store";
import classNames from "classnames";
import { Messages } from "../../../types";
import { getAuthorizeUrl, getUUIDv4, runCommand } from "../../utils";
import {
  Link,
  useNavigate,
  useRouter,
  useRouterState,
} from "@tanstack/react-router";
import { Persona } from "../persona/persona";
import { ViewMapping } from "../views";
import { Action } from "../action/action";
import { Lang } from "../lang/lang";
import { NotificationPanel } from "./notifications";
import WorkspacePanel from "./workspace";
import ChatApp from "../chat";
import useModal from "../modal/modal";
import { groupBy } from "lodash";

// Assets
import add_icon from "../../assets/add_icon.svg";
import settings from "../../assets/more_horitz.svg";
import icon_sync from "../../assets/sync_black.svg";
import notification_bell from "../../assets/notification_bell.svg";
import memory from "../../assets/memory.svg";
import logo from "../../assets/brand_icon_v4.png";
import logo_v2 from "../../assets/brand_icon_v6.svg";
import arrow_right from "../../assets/arrow_right.svg";

// Styles
import "./style.sass";
import { AuthorizeMode } from "../../../interfaces";

export const EnabledViews = {
  persona: false,
  config: false,
  memory: false,
  notifications: false,
  sync: false,
  workspaces: false,
};

export const HeaderBeta = () => {
  return (
    <div>
      <img className="header-icon" src={logo} alt="logo" />
      <div className="header-badge">
        <Badge bg="primary">Beta</Badge>
      </div>
    </div>
  );
};

export const HeaderBrand = ({
  className = "fill fill_shadow",
  hasLinks = true,
  link = "/",
}) => (
  <div className={classNames("header-brand v2", className)}>
    <Link to={link} className="header-brand-link">
      <div className="header-logo">
        <img className="header-brand-logo" src={logo_v2} alt="Cindi logo" />
        <p className="header-brand-text">Cindi</p>
      </div>
    </Link>
    {hasLinks && className && className.includes("fill") && (
      <div className="header-links">
        <Lang />
        <Action
          btnClassName="outline"
          to={getAuthorizeUrl(AuthorizeMode.SignIn)}
          text="Login"
        />
        <Action
          to={getAuthorizeUrl(AuthorizeMode.SignUp)}
          text="Try For Free"
        />
      </div>
    )}
  </div>
);

export const Header = ({
  onTriggerUploadModal = null,
  showSimpleView = false,
}) => {
  const { location } = useRouterState();
  const navigate = useNavigate();
  const { history } = useRouter();

  const { user, jobs, workspaces, errors } = useContext(AppStore);
  const {
    activeControl,
    data: { local_files: localFiles },
  } = useContext(AppStore);

  const ref = useRef(null);
  const notificationsRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [target, setTarget] = useState(null);
  const [notificationTarget, setNotificationTarget] = useState(null);
  const [syncId, setSyncId] = useState(null);
  const isInSync = useMemo(() => {
    return syncId !== null;
  }, [syncId]);

  useEffect(() => {
    if (jobs.some(({ id }) => id === syncId)) {
      setSyncId(null);
    }
  }, [jobs, syncId]);

  const handleWorkspaceClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleNotificationClick = (event) => {
    setShowNotification(!showNotification);
    setNotificationTarget(event.target);
  };

  const documentsById = useMemo(() => {
    if (localFiles == null) return {};

    return Object.fromEntries(
      Object.entries(Object.groupBy(localFiles, (x) => x.id)).map(
        ([key, values]) => [key, values?.[0]]
      )
    );
  }, [localFiles]);

  const activeRoute = ViewMapping[activeControl];
  const secondaryView =
    activeRoute.secondary && activeRoute.secondary({ location, documentsById });
  return (
    <div className="header">
      {!showSimpleView && (
        <>
          {activeRoute && (
            <div className="header-status">
              <span
                className="header-title"
                onClick={() => {
                  navigate({
                    to: `/#${activeRoute.match}`,
                  });
                }}
              >
                {activeRoute.title}
              </span>
              {secondaryView && (
                <span className="header-secondary-text">
                  <img
                    className="header-secondary-icon"
                    src={arrow_right}
                    alt="Secondary Level"
                  />
                  {secondaryView}
                </span>
              )}
            </div>
          )}
          <div className="header-status me-2 gap-2">
            {EnabledViews.sync && (
              <div className="sync-action">
                <small
                  className="ms-1 text-primary"
                  onClick={handleWorkspaceClick}
                >
                  Workspaces ({workspaces.length})
                </small>
              </div>
            )}
            {EnabledViews.sync && (
              <div className="sync-action">
                <img
                  className={classNames("sync-icon", { disabled: isInSync })}
                  src={icon_sync}
                  alt="Sync"
                  onClick={() => {
                    const id = getUUIDv4(false);
                    setSyncId(id);

                    runCommand(Messages.SyncDevice, { id });
                  }}
                />
              </div>
            )}
            {EnabledViews.memory && (
              <div className="sync-action">
                <Link to="/memory">
                  <img
                    className="sync-icon"
                    src={memory}
                    alt="Cindi Memory"
                    onClick={handleNotificationClick}
                  />
                </Link>
              </div>
            )}
            {EnabledViews.notifications && (
              <div className="sync-action">
                <img
                  className="sync-icon"
                  src={notification_bell}
                  alt="Notification Bell"
                  onClick={handleNotificationClick}
                />
                {errors.length > 0 && (
                  <span className="position-absolute top-50 start-50 badge rounded-pill bg-primary">
                    {errors.length}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </div>
            )}
            {EnabledViews.config && (
              <Link to="/config">
                <div className="sync-action hoverable">
                  <img className="sync-icon" src={settings} alt="Settings" />
                </div>
              </Link>
            )}
            {EnabledViews.persona && user && (
              <Persona className="sync-action" />
            )}
          </div>
          <Action
            text="Upload Lab Test"
            icon={add_icon}
            onClick={() => {
              if (!onTriggerUploadModal) return;

              onTriggerUploadModal();
            }}
            alt="Add Lab Test"
          />
          <NotificationPanel
            container={notificationsRef}
            target={notificationTarget}
            show={showNotification}
            setShow={setShowNotification}
            errors={errors}
          />
          <WorkspacePanel
            container={ref}
            target={target}
            show={show}
            setShow={setShow}
            workspaces={workspaces}
          />
        </>
      )}
    </div>
  );
};

export default Header;
