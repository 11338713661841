import React from "react";

// Icons
import sort_icon from "../../assets/sort-icon.svg";

import "./index.sass";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

// Custom sorting logic for one of our enum columns.
export const sortStatusFn = (rowA, rowB, _columnId) => {
  const statusA = rowA.original.status;
  const statusB = rowB.original.status;
  const statusOrder = ["single", "complicated", "relationship"];
  return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
};

export const TableView = ({
  className,
  columns,
  initialState,
  columnVisibility,
  data,
  hideIfEmpty = true,
  hoverable,
  onRowClick,
  defaultHeaderComponent,
  getSubRenderer,
  isExpandFullRow = false,
  usePagination = false,
}) => {
  const [sorting, setSorting] = React.useState(initialState?.sorting || []);

  const [expanded, setExpanded] = React.useState({});
  const table = useReactTable({
    columns,
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    // sortingFns: {
    //   sortStatusFn, //or provide our custom sorting function globally for all columns to be able to use
    // },
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      expanded,
      columnVisibility,
      sorting,
    },
    getSubRows: (row) => row?.subRows,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    // autoResetPageIndex: false, // turn off page index reset when sorting or filtering - default on/true
    // enableMultiSort: false, //Don't allow shift key to sort multiple columns - default on/true
    // enableSorting: false, // - default on/true
    // enableSortingRemoval: false, //Don't allow - default on/true
    // isMultiSortEvent: (e) => true, //Make all clicks multi-sort - default requires `shift` key
    // maxMultiSortColCount: 3, // only allow 3 columns to be sorted at once - default is Infinity
  });

  if (hideIfEmpty && (data == null || data.length === 0)) return null;

  return (
    <div className={classNames("table-view p-2", className)}>
      {defaultHeaderComponent ?? <div className="h-2" />}
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames("table-header-element", {
                          "cursor-pointer select-none":
                            header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <div className="sortFilter">
                            <img
                              src={sort_icon}
                              className={classNames("filter asc", {
                                active: header.column.getIsSorted() === "asc",
                              })}
                              alt="Sort Icon Asc"
                            />
                            <img
                              src={sort_icon}
                              className={classNames("filter desc", {
                                active: header.column.getIsSorted() === "desc",
                              })}
                              alt="Sort Icon Desc"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={classNames({ hoverable: hoverable })}>
          {table
            .getRowModel()
            .rows.slice(0, usePagination ? 10 : undefined)
            .map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <tr
                    key={row.id}
                    className={classNames({
                      "parent-expanded": row.getIsExpanded(),
                    })}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row.original);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() && isExpandFullRow && getSubRenderer && (
                    <tr key={row.id + "expanded"} className="expanded full">
                      <td colSpan={table.getAllLeafColumns().length}>
                        {getSubRenderer({ row })}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
