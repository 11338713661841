import React, { useContext, useEffect, useMemo, useState } from "react";

import Nav from "react-bootstrap/Nav";
import classNames from "classnames";
import { countBy } from "lodash";
import CalendarPicker from "../../filters/date-picker/DatePicker";
import { useSelectPicker } from "../../filters/select-picker/SelectPicker";
import { AppStore } from "../../../Store";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { ArtifactViewMode } from "../../../../interfaces";

// Icons
import close_icon from "../../../assets/close_black.svg";

// Styles
import "./../header.sass";

export const getNavBarTabs = ({ location }) => [
  {
    title: "Diagnosis",
    mode: ArtifactViewMode.Diagnosis,
    isActive: (state, item) => {
      return state?.search?.mode == null || location.search?.mode === item.mode;
    },
  },
  {
    title: "Biomarkers",
    mode: ArtifactViewMode.Biomarkers,
  },
  {
    title: "Recommendations",
    mode: ArtifactViewMode.Recommendations,
  },
];

export const ArtifactViewHeader = ({ model }) => {
  const { location } = useRouterState();
  const navigate = useNavigate({});
  const { filters, setFilters } = useContext(AppStore);
  const { local_files: localFiles } = model;

  const navBarTabs = useMemo(() => {
    return getNavBarTabs({ location });
  }, [location]);

  const labTypeOptions = useMemo(() => {
    if (!localFiles) return [];

    const labTypes = localFiles
      .map(({ metadata }) => metadata?.type)
      .filter((x) => x);

    return Object.entries(countBy(labTypes)).map(([labType, count]) => ({
      value: labType,
      label: labType,
      count,
    }));
  }, [localFiles]);

  const { component: selectPicker } = useSelectPicker({
    defaultSelectedValue: filters.labTestFilter,
    onSelectChange: (value) => {
      setFilters((state) => ({
        ...state,
        labTestFilter: value,
      }));
    },
    options: labTypeOptions,
  });

  const isFilterActive = useMemo(() => {
    return filters.length !== 0 && Object.values(filters).some((x) => !!x);
  }, [filters]);

  const isMainView = !Object.hasOwn(location.search, "id");
  return (
    <div className={classNames("view-header-container", { tabs: !isMainView })}>
      {isMainView ? (
        <div className="filters">
          <span className="filters-header">Filters</span>
          <CalendarPicker
            defaultSelected={filters.dateFilter}
            onSelectChange={(value) => {
              setFilters((state) => ({
                ...state,
                dateFilter: value,
              }));
            }}
          />
          {selectPicker}
          {isFilterActive && (
            <div
              className="filter-reset"
              onClick={() => {
                setFilters({});
              }}
            >
              <img
                className="filter-reset__icon"
                src={close_icon}
                alt="Clear Filters"
              />
              <span className="filter-reset__text">Clear Filters</span>
            </div>
          )}
        </div>
      ) : (
        <Nav variant="tabs">
          {navBarTabs.map((navItem) => (
            <Nav.Item key={navItem.mode}>
              <Nav.Link
                active={
                  navItem.isActive
                    ? navItem.isActive(location, navItem)
                    : location.search?.mode === navItem.mode
                }
                onClick={() => {
                  navigate({
                    search: (state) => ({ ...state, mode: navItem.mode }),
                    replace: true,
                    hash: location.hash,
                  });
                }}
              >
                {navItem.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      )}
    </div>
  );
};

export default ArtifactViewHeader;
