import React, { useState, useMemo, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { authorize, getUser } from "../../../api/auth";
import Alert from "react-bootstrap/Alert";
import { AppStore } from "../../Store";
import { InputGroup, Spinner } from "react-bootstrap";
import InputGroupText from "react-bootstrap/InputGroupText";
import Action from "../../components/action/action";
import { HeaderBrand } from "../../components/header";
import { getAuthorizeUrl, parseStrToInt } from "../../utils";
import { AppRoute, AuthorizeMode } from "../../../interfaces";

// Hooks
import {
  Link,
  useNavigate,
  useRouter,
  useRouterState,
} from "@tanstack/react-router";

// Assets
import arrow_left from "../../assets/arrow_left.svg";
import logo_v2 from "../../assets/brand_icon_v6.svg";

// Styles
import "./style.sass";

export const getAuthorizationMode = (location) => {
  const availableModes = new Set(
    Object.values(AuthorizeMode).filter((x) => typeof x === "number")
  );
  const mode = parseStrToInt(location.search?.mode);

  if (availableModes.has(mode)) {
    return mode;
  }

  return AuthorizeMode.SignIn;
};

export const AppLogin = () => {
  const { location } = useRouterState();
  const { history } = useRouter();
  const { user, setUser } = useContext(AppStore);
  const [isAuthVerified, setIsAuthVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [pendingTime, setPendingTime] = useState(null);
  // "Mon, 16 Dec 2024 02:53:19 GMT"
  const [timeoutReset, setTimeoutReset] = useState(null);
  const [authorizationMode, setAuthorizationMode] = useState(
    getAuthorizationMode(location)
  );

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // const saveAppLoginState = () => {
  //   localStorage.setItem(
  //     "appLoginState",
  //     JSON.stringify({
  //       email,
  //       authorizationMode,
  //     })
  //   );
  // };

  useEffect(() => {
    const token = location.search?.token;
    if (!token) return;

    // Verification token found.
    onAuthorizeCallback({ forced: true, mode: authorizationMode, token });
  }, [location, authorizationMode]);

  useEffect(() => {
    setAuthorizationMode(getAuthorizationMode(location));
  }, [location]);

  const isAuthorization =
    authorizationMode === AuthorizeMode.SignIn ||
    authorizationMode === AuthorizeMode.SignUp;

  const isDisabled = useMemo(() => {
    const isEmptyEmail = email.length === 0;
    const isEmptyPassword = password.length === 0;
    return (
      (isAuthorization && (isEmptyEmail || isEmptyPassword)) ||
      (authorizationMode === AuthorizeMode.ResetPassword && isEmptyEmail)
    );
  }, [email, password]);

  useEffect(() => {
    setIsAuthVerified(true);
    setIsLoading(true);

    getUser(false)
      .then((user) => {
        if (user) {
          setUser(user);

          // If the user is successfully logged in, redirect to main page.
          navigate({
            to: AppRoute.Main,
          });
        }
      })
      .catch((err) => {
        // Do nothing.
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [navigate]);

  const isFormControlsHidden =
    authorizationMode === AuthorizeMode.SignUpTwoStep ||
    authorizationMode === AuthorizeMode.ResetPasswordTwoStep;

  const onAuthorizeCallback = ({ token, mode, forced = false }) => {
    if (
      !forced &&
      (isDisabled ||
        isFormControlsHidden ||
        (pendingTime != null && pendingTime >= 0))
    )
      return;

    setError(null);
    setIsAuthorizing(true);

    const other =
      location.search?.plan_id !== null
        ? JSON.stringify({
            plan_id: location.search.plan_id,
          })
        : undefined;

    // TODO: Remove unnecessary props.
    authorize(
      {
        token,
        email,
        password,
        mode: mode || authorizationMode,
        other,
      },
      true
    )
      .then((response) => {
        switch (authorizationMode) {
          case AuthorizeMode.ResetPasswordRedirect: {
            setAuthorizationMode(AuthorizeMode.SignIn);
            break;
          }
          case AuthorizeMode.SignUpTwoRedirect:
          case AuthorizeMode.SignIn: {
            return getUser()
              .then((user) => {
                setUser(user);
              })
              .then(() => {
                localStorage.removeItem("token");
                navigate({ to: "/" });
              });
          }
          case AuthorizeMode.SignUp: {
            setAuthorizationMode(AuthorizeMode.SignUpTwoStep);
            setTimeoutReset(response.reset_in);
            break;
          }
          case AuthorizeMode.ResetPassword: {
            setAuthorizationMode(AuthorizeMode.ResetPasswordTwoStep);
            setTimeoutReset(response.reset_in);
            break;
          }
        }

        return Promise.resolve();
      })
      .catch((err) => {
        setError(err.toString());
      })
      .finally(() => {
        setIsAuthorizing(false);
      });
  };

  useEffect(() => {
    if (!timeoutReset) return;

    const handle = setInterval(() => {
      setPendingTime(
        Math.floor((new Date(timeoutReset).getTime() - Date.now()) / 1000)
      );
    }, 1000);

    return () => {
      if (handle) clearInterval(handle);
    };
  }, [timeoutReset]);

  if (isLoading || !isAuthVerified) return null;

  return (
    <div className="login">
      <HeaderBrand link={AppRoute.Welcome} />
      <div className="background-assets">
        <img className="background-asset-1" src={logo_v2} alt="Cindi logo bg" />
        <img className="background-asset-2" src={logo_v2} alt="Cindi logo bg" />
      </div>
      <div className="login-container position-relative">
        {(authorizationMode === AuthorizeMode.ResetPassword ||
          authorizationMode === AuthorizeMode.SignUpTwoStep ||
          authorizationMode === AuthorizeMode.ResetPasswordTwoStep) && (
          <div
            className="login-back"
            onClick={() => {
              history.back();
            }}
          >
            <img src={arrow_left} alt="Back" />
          </div>
        )}
        <p className="login-welcome-text">
          {authorizationMode === AuthorizeMode.SignUp
            ? "Create your account"
            : authorizationMode === AuthorizeMode.ResetPassword
            ? "Forgot Password?"
            : authorizationMode === AuthorizeMode.SignUpTwoStep
            ? "Verify your email"
            : authorizationMode === AuthorizeMode.ResetPasswordTwoStep
            ? "Check your email"
            : "Welcome back"}
        </p>
        {authorizationMode === AuthorizeMode.SignUp ? (
          <p className="login-welcome-subtitle">
            Already have an account?{" "}
            <Link to={getAuthorizeUrl(AuthorizeMode.SignIn)}>
              <span className="login-accent">Sign In</span>
            </Link>
          </p>
        ) : authorizationMode === AuthorizeMode.ResetPassword ? (
          <p className="login-welcome-subtitle">
            Please enter your email and we will send you a link to reset a
            password
          </p>
        ) : authorizationMode === AuthorizeMode.SignIn ? (
          <p className="login-welcome-subtitle">
            Don't have an account?{" "}
            <Link to={getAuthorizeUrl(AuthorizeMode.SignUp)}>
              <span className="login-accent">Sign Up</span>
            </Link>
          </p>
        ) : authorizationMode === AuthorizeMode.SignUpTwoStep ? (
          <p className="login-welcome-subtitle">
            We sent an email to <span className="accent">{email}</span>. Click the link inside to
            get started.
          </p>
        ) : (
          authorizationMode === AuthorizeMode.ResetPasswordTwoStep && (
            <p className="login-welcome-subtitle">
              We have sent password recovery instructions to{" "}
              <span className="accent">{email}</span>
            </p>
          )
        )}
        {!isFormControlsHidden && (
          <Form className="w-100 mt-4">
            <Form.Label>Email Address*</Form.Label>
            <Form.Control
              id="account-email"
              className="form-c-input"
              type="email"
              size="lg"
              placeholder="Enter your email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              autoComplete="one-time-code"
              disabled={false}
            />
            {isAuthorization && (
              <>
                <Form.Label className="mt-4">Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="account-password"
                    className="form-c-input"
                    type={!showPassword ? "password" : "text"}
                    size="lg"
                    placeholder="Enter your password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    autoComplete="one-time-code"
                    disabled={false}
                  />
                  <InputGroupText
                    onClick={() => {
                      setShowPassword((state) => !state);
                    }}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye-slash" id="togglePassword" />
                    ) : (
                      <i className="bi bi-eye" id="togglePassword" />
                    )}
                  </InputGroupText>
                </InputGroup>
              </>
            )}
            {authorizationMode === AuthorizeMode.SignIn && (
              <Link to={getAuthorizeUrl(AuthorizeMode.ResetPassword)}>
                <span className="form-link">Forgot Password?</span>
              </Link>
            )}
          </Form>
        )}
        {isFormControlsHidden ? (
          <div className="login-progress">
            <Spinner size="sm" />
            Didn't receive the link?{" "}
            {pendingTime && pendingTime >= 0 ? (
              <span className="accent disabled">
                Retry in {pendingTime.toString().padStart(2)}s
              </span>
            ) : (
              <span className="accent" onClick={onAuthorizeCallback}>
                Retry
              </span>
            )}
          </div>
        ) : (
          <div className="d-grid gap-2 w-100">
            <Action onClick={onAuthorizeCallback} btnClassName="expand">
              {isAuthorizing ? (
                <div>
                  <Spinner />
                </div>
              ) : authorizationMode === AuthorizeMode.SignUp ? (
                "Create Account"
              ) : authorizationMode === AuthorizeMode.ResetPassword ? (
                "Send"
              ) : (
                "Sign In"
              )}
            </Action>
          </div>
        )}
        {error && (
          <Alert className="w-100 position-absolute top-100" variant="danger">
            {error}
          </Alert>
        )}
        {isAuthorization && (
          <p className="login-privacy">
            {authorizationMode === AuthorizeMode.SignUp
              ? "By creating a Cindi account"
              : "By signing in"}
            , you agree to the{" "}
            <Link to="terms-conditions">Terms & Condition</Link> and{" "}
            <Link to="privacy">Privacy Policies</Link>
          </p>
        )}
      </div>
      <div className="login-footer" />
    </div>
  );
};

export default AppLogin;
