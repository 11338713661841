import dotenv from "dotenv";

// TODO: This fails, resolve the dotenv config.
// dotenv.config();

export const CACHE_PATH = "cache";
export const CACHE_DOCS = "docs";
export const SCRIPT_PATH = "../scripts/helpers";
export const settings = {
  prod: true,
  debug: true,
  safe: false,
};

// export const MAX_CHARACTERS = 20000;
export const MAX_CHARACTERS = -1;
// export const MAX_DOC_LEN = 100000;
export const MAX_DOC_LEN = -1;

// Max file size is 1MB.
export const MAX_FILE_SIZE = 1000000;
export const MAX_ENTRY_SIZE = 10;

// Max files to process.
export const MAX_DIR_LEN = 500;
export const MAX_STOP_LEN = 100000;
// TODO: Make it configurable per-user.
export const MAX_DIR_REC_LEN = 200;

export const GENERIC_ENABLED = false;
export const SCRAPING_ENABLED = false;

// Our dataset for evaluation.
export const devDataset = [
  "cchivriga-jun-05-23",
  "tchivriga-jun-18-23",
  "tchivriga-aug-26-23-1",
  "tchivriga-aug-26-23-2",
  "tchivriga-aug-26-23-3",
];

export type Matcher = (value: string) => boolean;

// Ignore dotfiles, node_modules, artifacts.
export const ignoreMatcher: Matcher[] = [
  (value) => !/^(?!\.).*$/.test(value),
  (value) => value === "node_modules",
  // (value) => value === app?.getPath("appData"),
  // (value) => value === app?.getPath("documents"),
  // (value) => value === app?.getPath("temp"),
];

export const isExeUnpacked = true;
export const MODEL_DIR = process.env.MODEL_DIR;
export const LIB_DIR = process.env.LIB_DIR;
export const proxyApp = "http://localhost:3000";
export const cindiExe = "libc.exe";
export const downloadExe = "libd.exe";
export const appExtExe = "libs.exe";
export const linkExe = "libl.exe";
export const modelsDir = "ctmp";
export const ocrDataDir = "ocr";

export const isDevRun = process.env.NODE_ENV === "development";
