import { useMemo } from "react";
import "chartjs-adapter-date-fns";
// import 'chartjs-adapter-moment';
import { Bar, Line } from "react-chartjs-2";
import classNames from "classnames";

// Styles
import "./Chart.sass";

import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  if (!legendContainer) return null;

  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.justifyContent = "flex-end";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    if (!ul) return;

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    // const metas = chart.data.datasets.map((_, index) => chart.getDatasetMeta(index));
    // console.log(metas);

    items.forEach((item) => {
      const meta = chart.getDatasetMeta(item.datasetIndex);

      const li = document.createElement("li");
      li.className = "legend-item";
      li.style.alignItems = "center";
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.marginLeft = "10px";
      li.style.opacity = meta.hidden ? "0.4" : "1.0";
      li.style.userSelect = "none";

      li.onclick = () => {
        const { type } = chart.config;

        if (type === "pie" || type === "doughnut") {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.className = "legend-item-box";
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.className = "legend-item-text";
      textContainer.style.color = item.fontColor;

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

const customBackgroundFillPlugin = {
  id: "customBackgroundFill",
  beforeDraw: (chart) => {
    const options = chart.config.options;
    const ctx = chart.ctx;
    const yScale = chart.scales.y;

    const yAxisMin = yScale.min;
    const yAxisMax = yScale.max;

    const capValue = (y) => Math.max(Math.min(y, yAxisMax), yAxisMin);

    const { backgroundFills } = options.plugins;
    for (const backgroundFill of backgroundFills) {
      const y0Pixel = yScale.getPixelForValue(capValue(backgroundFill.y0));
      const y1Pixel = yScale.getPixelForValue(capValue(backgroundFill.y1));

      ctx.save();
      ctx.fillStyle = backgroundFill.backgroundColor;
      ctx.fillRect(
        chart.chartArea.left,
        y1Pixel,
        chart.chartArea.right - chart.chartArea.left,
        y0Pixel - y1Pixel
      );
      ctx.restore();
    }
  },
};

ChartJS.register(
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  htmlLegendPlugin,
  customBackgroundFillPlugin
);

export const fontConfig = {
  size: 12,
};

export const BarChart = ({
  className,
  chartData,
  chartOptions,
  showLegend = true,
  showTitle = true,
}) => {
  const options = useMemo(() => {
    const dev =
      (Math.abs(chartOptions.mmaxY - chartOptions.mminY) * 10.0) / 100;
    const backgroundFills =
      chartData.datasets.length === 1
        ? [
            {
              y0: chartOptions.minY,
              y1: chartOptions.maxY,
              backgroundColor: "rgba(190, 232, 190, 0.4)",
            },
          ]
        : [];

    const scaleYBounds =
      chartData.datasets.length === 1
        ? {
            min: chartOptions.mminY - dev,
            max: chartOptions.mmaxY + dev,
          }
        : {};

    return {
      plugins: {
        backgroundFills,
        htmlLegend: {
          containerID: "legend-container",
        },
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
      scales: {
        x: {
          type: "time",
          title: {
            display: showTitle,
            text: "Date",
            color: "rgb(159,157,179)",
            font: fontConfig,
          },
          time: {
            // parser: 'yyyy-mm-dd',
            tooltipFormat: "MMM do yy",
            unit: "day",
            displayFormats: {
              day: "MMM dd, yy",
            },
          },
          ticks: {
            display: true,
            source: "data",
            maxTicksLimit: 12,
          },
          stacked: true,
          border: {
            display: false,
          },
          grid: {
            display: false, // Remove grid lines
          },
        },
        y: {
          ...scaleYBounds,
          title: {
            display: showTitle,
            text: "Value",
            color: "rgb(159,157,179)",
            font: fontConfig,
            align: "end",
          },
          ticks: {
            display: true,
            maxTicksLimit: 8,
          },
          stacked: true,
          border: {
            dash: [2, 4],
            display: false,
          },
          grid: {
            color: "rgb(219,219,219)",
          },
        },
      },
    };
  }, [chartData, chartOptions]);

  return (
    <div className={classNames("chart", className)}>
      {showLegend && <div id="legend-container" />}
      <Line data={chartData} options={options} />
    </div>
  );
};
