import React, { useContext, useMemo } from "react";

import router from "../../router/index";
import Nav from "react-bootstrap/Nav";
import ActivityView from "./activity";

// Assets
import close_icon from "../../assets/close_black.svg";

import "./style.sass";

export const Memory = ({ children }) => {
  const onBack = () => router.history.back();

  return (
    <div className="memory p-4">
      <div className="config-header">
        <img
          className="config-close"
          src={close_icon}
          onClick={onBack}
          alt="Close App Settings"
        />
      </div>
      <p className="config-header">Memory</p>
      <Nav
        className="justify-content-center"
        activeKey="#memory"
        variant="underline"
      >
        <Nav.Link href="#memory">Activity</Nav.Link>
        <Nav.Link href="#actions">Actions</Nav.Link>
      </Nav>
      <ActivityView />
    </div>
  );
};

export default Memory;
