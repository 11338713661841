import { useState, useEffect, useCallback } from "react";

export const getLocationFromIpAddress = () => {
  return fetch("http://ip-api.com/json")
    .then((res) => res.json())
    .then((location) => {
      if (location.status === "success") {
        return {
          city: location.city,
          country: location.country,
          latitude: location.lat,
          longitude: location.lon,
          timezone: location.timezone,
          accuracy: location.accuracy,
        };
      }

      return Promise.reject("Failed to fetch current location.");
    });
};

function getLocationFromBrowser() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          // console.error("Error getting location from browser:", error);
          reject(error);
        }
      );
    } else {
      // console.error("Geolocation is not supported by this browser.");
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
}

export const getLocation = async () => {
  try {
    return await getLocationFromBrowser();
  } catch (e) {
    return await getLocationFromIpAddress();
  }
};

export const getOtherMetadata = () => {
  let timezone = null;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // Do nothing.
  }

  return {
    timezone: timezone,
    time: new Date().toISOString(),
  };
};

export const useGetLocation = () => {
  const [coords, setCoords] = useState(null);

  const resetLocation = useCallback(
    (error) => {
      console.log(error);

      setCoords(null);
    },
    [setCoords]
  );

  useEffect(() => {
    getLocation()
      .then((location) => {
        setCoords(location);
      })
      .catch(() => {
        console.log("Geolocation is not supported.");
      });
  }, [setCoords]);

  useEffect(() => {
    const identifier = setInterval(async () => {
      try {
        const location = await getLocation();
        setCoords(location);
      } catch (e) {
        resetLocation("Geolocation is not supported.");
      }
    }, 20 * 60 * 1000);

    return () => {
      clearInterval(identifier);
    };
  }, [resetLocation]);

  return {
    ...coords,
    ...getOtherMetadata(),
  };
};
