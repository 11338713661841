import React, { useContext, useEffect, useMemo, useState } from "react";

import { countBy } from "lodash";
import { useSelectPicker } from "../../filters/select-picker/SelectPicker";
import { AppStore } from "../../../Store";
import { useSearchField } from "../../filters/search/SearchField";

// Icons
import close_icon from "../../../assets/close_black.svg";

// Styles
import "./style.sass";
import "./../header.sass";

export const ReportViewHeader = ({ model }) => {
  const { filters, setFilters } = useContext(AppStore);
  const { local_files: localFiles } = model;

  const { component: SearchField, searchValue } = useSearchField({
    placeholder: "Search Biomarker",
  });

  useEffect(() => {
    setFilters((state) => ({
      ...state,
      markerSearchFilter: searchValue,
    }));
  }, [searchValue]);

  const labTypeOptions = useMemo(() => {
    if (!localFiles) return [];

    const labTypes = localFiles
      .map(({ metadata }) => metadata?.type)
      .filter((x) => x);

    return Object.entries(countBy(labTypes)).map(([labType, count]) => ({
      value: labType,
      label: labType,
      count,
    }));
  }, [localFiles]);

  const { component: selectPicker } = useSelectPicker({
    defaultSelectedValue: filters.labTestFilter,
    onSelectChange: (value) => {
      setFilters((state) => ({
        ...state,
        labTestFilter: value,
      }));
    },
    options: labTypeOptions,
  });

  const isFilterActive = useMemo(() => {
    return filters.length !== 0 && Object.values(filters).some((x) => !!x);
  }, [filters]);

  return (
    <div className="view-header-container">
      <div className="filters">
        <span className="filters-header">Filters</span>
        {SearchField}
        {isFilterActive && (
          <div
            className="filter-reset"
            onClick={() => {
              setFilters({});
            }}
          >
            <img
              className="filter-reset__icon"
              src={close_icon}
              alt="Clear Filters"
            />
            <span className="filter-reset__text">Clear Filters</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportViewHeader;
