// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend-item {
  margin-right: 4px;
}

.legend-item-box {
  display: inline-block;
  flex-shrink: 0;
  margin-right: 6px;
  width: 20px;
  height: 10px;
  border-radius: 4px;
}

.legend-item-text {
  margin: 0;
  padding: 0;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/ui/routes/memory/components/Chart.sass"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AACA;EACE,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAEF;;AAAA;EACE,SAAA;EACA,UAAA;EACA,eAAA;AAGF","sourcesContent":[".legend-item\n  margin-right: 4px\n\n.legend-item-box\n  display: inline-block\n  flex-shrink: 0\n  margin-right: 6px\n  width: 20px\n  height: 10px\n  border-radius: 4px\n\n.legend-item-text\n  margin: 0\n  padding: 0\n  font-size: 12px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
