import React, { useContext, useMemo } from "react";
import { AppStore } from "../../Store";
import ProgressBar from "../progress/bar";
import Action from "../action/action";

// Styles
import "./style.sass";

export const PlanView = () => {
  const { user } = useContext(AppStore);

  const userPlan = useMemo(() => {
    if (user == null) return null;

    return user.plans.find((plan) => plan.end_date == null);
  }, [user]);

  const limits = useMemo(() => {
    if (userPlan == null) return null;

    const { plan, states } = userPlan;
    const limitByIds = Object.fromEntries(
      plan.limits.map((limit) => [
        limit.id,
        {
          ...limit,
          isUpgradable: true,
          max: limit.value,
        },
      ])
    );

    return states.map((state) => {
      const limit = limitByIds[state.plan_limit_id];
      return {
        ...{
          ...state,
          current: state.used,
        },
        limitExceeded: limit.max !== -1 && state.used >= limit.max,
        ...limit,
      };
    });
  }, [userPlan]);

  if (userPlan == null) return null;

  const { plan } = userPlan;

  return (
    <div className="plan">
      <div className="plan-container">
        <p className="plan-header">{plan.title}</p>
        {limits.map((limit) => (
          <div key={limit.title} className="plan-limit">
            <p className="plan-info">{limit.title}</p>
            <ProgressBar
              current={limit.current}
              min={limit.current}
              max={limit.max}
            />
            <div className="limit-stats">
              {limit.limitExceeded ? (
                <span className="limit-stat warning">Limit Reached</span>
              ) : (
                <span className="limit-stat">Total Limit</span>
              )}
              <span className="limit-stat">
                {limit.current} of {limit.max}
              </span>
            </div>
          </div>
        ))}
        <Action
          to="/pricing"
          className="mt-2 w-100"
          btnClassName="outline expand mx-auto"
          text="Manage Plans"
        />
      </div>
    </div>
  );
};
