import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import chart_icon from "../../../assets/chart_icon.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { magicFile } from "../../../../api/query";
import { marked } from "marked";
import { useRouterState } from "@tanstack/react-router";
import { MetadataView } from "../snapshot/SnapshotView";
import { getReportData } from "../report/useReportData";
import AppStore from "../../../Store";
import TableView from "../../table/table";
import classNames from "classnames";
import useSearchField from "../../filters/search/SearchField";
import ToggleSwitch from "../../filters/toggle/ToggleFilter";
import { EmptyView } from "../report/EmptyView";
import { ArtifactViewMode, FileReportType } from "../../../../interfaces";

// Icons
import details_icon from "../../../assets/details_icon.svg";
import arrow_right from "../../../assets/arrow_right.svg";
import close_icon from "../../../assets/close_black.svg";

// Styles
import "./style.sass";

export const IsDocumentSideViewEnabled = false;

export const DocumentSideView = ({
  entity,
  onHide,
  reportsByType,
  ActionLink,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!!entity);
  }, [entity]);

  const handleClose = () => {
    onHide();
    setShow(false);
  };

  if (!IsDocumentSideViewEnabled) return null;

  return (
    <Offcanvas
      className="document-view-floating"
      show={show}
      onHide={handleClose}
      scroll
      backdrop={false}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{entity["title"]}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="card">
          <div className="card-body">
            <div className="card-header-full">
              <img className="card-icon" src={chart_icon} alt="Diagnosis" />
              <h5 className="card-title">Diagnosis</h5>
            </div>
            <div className="card-divider" />
            <div
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: Object.prototype.hasOwnProperty.call(
                  reportsByType,
                  FileReportType.Diagnosis
                )
                  ? marked.parse(
                      reportsByType[FileReportType.Diagnosis].response
                    )
                  : "-",
              }}
            />
            <ActionLink actionType={2} />
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <div className="card-header-full">
              <img
                className="card-icon"
                src={details_icon}
                alt="Recommendations"
              />
              <h5 className="card-title">Recommendations</h5>
            </div>
            <div className="card-divider" />
            <div
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: Object.prototype.hasOwnProperty.call(
                  reportsByType,
                  FileReportType.Recommendations
                )
                  ? marked.parse(
                      reportsByType[FileReportType.Recommendations].response
                    )
                  : "-",
              }}
            />
            <ActionLink actionType={3} />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const DocumentView = ({ onHide }) => {
  const { user } = useContext(AppStore);
  const { filters, setFilters } = useContext(AppStore);

  const isFilterActive = useMemo(() => {
    return filters.length !== 0 && Object.values(filters).some((x) => !!x);
  }, [filters]);

  const { component: SearchField, searchValue } = useSearchField({
    placeholder: "Search Biomarker",
  });

  useEffect(() => {
    setFilters((state) => ({
      ...state,
      markerSearchFilter: searchValue,
    }));
  }, [searchValue]);

  const { location } = useRouterState();
  const {
    data: { local_files: localFiles },
  } = useContext(AppStore);

  const documentsById = useMemo(() => {
    if (localFiles == null) return {};

    return Object.fromEntries(
      Object.entries(
        Object.groupBy(
          localFiles.filter(({ id }) => !!id),
          (x) => x.id
        )
      ).map(([key, values]) => [key, values?.[0]])
    );
  }, [localFiles]);

  const entity = useMemo(() => {
    const identifier = location.search?.id;
    if (identifier == null) return null;

    return documentsById?.[identifier];
  }, [location]);

  // TODO: Reports by type (0 -> diagnosis, 1 -> recommendations)
  const reportsByType = useMemo(() => {
    return Object.fromEntries(
      (entity?.other ?? []).map((report) => {
        return [report.type, report];
      })
    );
  }, [entity]);

  const mode = location.search?.mode || "diagnosis";

  const markers = useMemo(() => {
    const markers = Object.prototype.hasOwnProperty.call(
      reportsByType,
      FileReportType.Markers
    )
      ? getReportData(reportsByType[FileReportType.Markers].response)
      : null;

    if (!markers) return markers;

    const column = markers.otherColumns.find(
      ({ accessorKey }) => accessorKey === "Parameter"
    );

    column.cell = ({ row, getValue }) => {
      return (
        <div className="expand-container-root">
          <span
            className="expand-container"
            onClick={() => {
              row.toggleExpanded();
            }}
          >
            <img
              className={classNames("expand-icon", {
                expanded: row.getIsExpanded(),
              })}
              src={arrow_right}
              alt={row.getIsExpanded() ? "Collapse Row" : "Expand Row"}
            />
          </span>
          <span className="cell-text-accent">{getValue()}</span>
        </div>
      );
    };

    return markers;
  }, [reportsByType]);

  const actionCallback = useCallback(
    (actionType) => {
      magicFile({
        file_id: entity["id"],
        action_type: actionType,
      }).then((response) => {
        console.log("Response: ", response);
      });
    },
    [entity]
  );

  const ActionLink = ({ actionType }) =>
    user &&
    user.role === "admin" && (
      <span
        className="card-link"
        onClick={() => {
          actionCallback(actionType);
        }}
      >
        Retry Generation (Admin Only)
      </span>
    );

  const getSubRenderer = ({ row: { original: row } }) => {
    const insights = row?.["Insights"];
    return (
      <div>
        {insights && (
          <>
            <h5 className="card-title mt-3 ms-0">Insights</h5>
            <p className="card-text mt-1">{insights}</p>
          </>
        )}
      </div>
    );
  };

  const defaultHeaderComponent = (
    <div className="filters">
      {SearchField}
      <ToggleSwitch
        label="Show only abnormal markers"
        isOn={filters?.showAbnormalOnly ?? false}
        onToggle={() => {
          setFilters((state) => ({
            ...state,
            showAbnormalOnly: !(state?.showAbnormalOnly ?? false),
          }));
        }}
      />
      {isFilterActive && (
        <div
          className="filter-reset"
          onClick={() => {
            setFilters({});
          }}
        >
          <img
            className="filter-reset__icon"
            src={close_icon}
            alt="Clear Filters"
          />
          <span className="filter-reset__text">Clear Filters</span>
        </div>
      )}
    </div>
  );

  if (!entity) return null;

  if (mode === ArtifactViewMode.Diagnosis)
    return (
      <div className="document-view-container pad">
        <ActionLink actionType={2} />
        <div className="card">
          <div className="card-body">
            <div className="card-header-full">
              <h5 className="card-title">Diagnosis</h5>
            </div>
            <div
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: Object.prototype.hasOwnProperty.call(
                  reportsByType,
                  FileReportType.Diagnosis
                )
                  ? marked.parse(
                      reportsByType[FileReportType.Diagnosis].response
                    )
                  : "-",
              }}
            />
          </div>
          <div className="card-divider vertical" />
          <div className="card-body">
            <MetadataView
              className="tight"
              document={entity}
              includeDocumentLink={true}
            />
          </div>
        </div>
      </div>
    );

  if (mode === ArtifactViewMode.Biomarkers) {
    const data = Object.prototype.hasOwnProperty.call(
      reportsByType,
      FileReportType.Markers
    )
      ? marked.parse(reportsByType[FileReportType.Markers].response)
      : null;

    if (markers == null) {
      return (
        <div className="document-view-container">
          {data != null ? (
            <div
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: data,
              }}
            />
          ) : (
            <EmptyView
              isDescriptionVisible={false}
              actionBtnText="Re-generate"
              showBtnIcon={false}
              onTriggerUploadModal={() => {
                actionCallback(4);
              }}
            />
          )}
          <ActionLink actionType={4} />
        </div>
      );
    }

    return (
      <div className="document-view-container">
        <>
          <TableView
            className="white pill uppercase"
            columns={markers.otherColumns}
            data={markers.otherRows}
            defaultHeaderComponent={defaultHeaderComponent}
            hoverable={true}
            isExpandFullRow={true}
            getSubRenderer={getSubRenderer}
            columnVisibility={{
              ID: false,
              Date: false,
              ["Test Type"]: false,
              Insights: false,
            }}
            // onRowClick={onRowClick}
          />
        </>
        <ActionLink actionType={4} />
      </div>
    );
  }

  if (mode === ArtifactViewMode.Recommendations) {
    return (
      <div className="document-view-container pad">
        <ActionLink actionType={3} />

        <div className="card">
          <div className="card-body">
            <div className="card-header-full">
              <h5 className="card-title">Recommendations</h5>
            </div>
            <div
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: Object.prototype.hasOwnProperty.call(
                  reportsByType,
                  FileReportType.Recommendations
                )
                  ? marked.parse(
                      reportsByType[FileReportType.Recommendations].response
                    )
                  : "-",
              }}
            />
          </div>
          <div className="card-divider vertical" />
          <div className="card-body">
            <MetadataView
              className="tight"
              document={entity}
              includeDocumentLink={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <DocumentSideView
      entity={entity}
      onHide={onHide}
      reportsByType={reportsByType}
      ActionLink={ActionLink}
    />
  );
};

export default DocumentView;
