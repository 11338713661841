import { Action } from "../../action/action";

// Icons
import bio_icon from "../../../assets/menu/bio_icon.svg";
import add_icon from "../../../assets/add_icon.svg";

// Styles
import "./style.sass";

export const EmptyView = ({
  onTriggerUploadModal,
  isDescriptionVisible = true,
  actionBtnText = "Upload Lab Test",
  showBtnIcon = true,
}) => (
  <div className="empty-view">
    <img className="empty-view__icon" src={bio_icon} alt="Biomarkers" />
    <p className="empty-view__title">No Biomarkers available</p>
    {isDescriptionVisible && (
      <p className="empty-view__description">
        Start by uploading your lab tests and get an overview of your biomarkers
      </p>
    )}
    <Action
      className="warning"
      text={actionBtnText}
      size="large"
      icon={showBtnIcon ? add_icon : null}
      onClick={() => {
        if (!onTriggerUploadModal) return;

        onTriggerUploadModal();
      }}
      alt={actionBtnText}
    />
  </div>
);
