// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  background: white;
}

#root.app {
  border: 2px solid #001E36;
  border-radius: 8px;
}

.app-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex: 1 1;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/ui/App.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AADF;;AAGA;EACE,iBAAA;AAAF;;AAEA;EACE,yBAAA;EACA,kBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,uBAAA;EACA,YAAA;EACA,SAAA;AAEF;;AAAA;EACE,aAAA;EACA,gBAAA;AAGF","sourcesContent":["@import \"constants\"\n\n.app\n  display: flex\n  flex-direction: row\n  justify-content: flex-start\n  align-items: flex-start\n  height: 100%\n  width: 100%\n  overflow: hidden\n\n#root\n  background: white\n\n#root.app\n  border: 2px solid $primaryColor\n  border-radius: 8px\n\n.app-main\n  display: flex\n  flex-direction: column\n  justify-content: flex-start\n  align-items: flex-start\n  height: 100%\n  flex: 1\n\np\n  margin-top: 0\n  margin-bottom: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
