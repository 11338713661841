import React, { useState } from "react";

// Components
import TaskComponent, { TaskHeader } from "./TaskComponent";

import "./style.sass";

const KanbanView = ({ model, setModel }) => {
  const [tasks, setTasks] = useState(model);
  const [draggedTask, setDraggedTask] = useState(null);

  const moveTask = (taskId, newStatus) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, status: newStatus };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleDragStart = (taskId) => {
    setDraggedTask(taskId);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (newStatus) => {
    moveTask(draggedTask, newStatus);
    setDraggedTask(null);
  };

  const renderColumn = (status) => (
    <div className="column">
      <div>
        <h2 className="badge badge-pill badge-outline">{status}</h2>
      </div>
      <div
        className="column-body"
        key={status}
        onDragOver={(e) => handleDragOver(e)}
        onDrop={() => handleDrop(status)}
      >
        <div className="scrollable">
          <ul>
            {tasks
              ?.filter((task) => task.status === status)
              ?.map((task) => (
                <li
                  key={task.id}
                  draggable
                  onDragStart={() => handleDragStart(task.id)}
                >
                  <TaskHeader task={task} />
                  <TaskComponent task={task} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div className="kanban-board">
      {ProgressStatus.map((status) => renderColumn(status))}
    </div>
  );
};

export const ProgressStatus = ["To-do", "In Progress", "Done", "Unknown"];

export const useKanbanAdapter = (data, setData) => {
  console.log(data, setData);
  return [data, setData];
  // return [
  //   [
  //     {
  //       name: "Cristian Chivriga",
  //       description:
  //         "The client wants to use Cindi for sales and rule the sales market.",
  //       status: "To-do",
  //       leadStatus: null,
  //       type: "lead",
  //     },
  //     {
  //       name: "Cindi",
  //       description:
  //         "The client's brother's company, needs help with managing over 100 clients and sending birthday greetings.",
  //       status: "In Progress",
  //       leadStatus: "warm",
  //       type: "feature",
  //     },
  //     {
  //       name: "Cindi",
  //       description:
  //         "The client's brother wants to use only his mobile phone, feature requested for integration.",
  //       status: "In Progress",
  //       leadStatus: null,
  //       type: "feature",
  //     },
  //     {
  //       name: "Cindi",
  //       description: "The client's brother agreed to pay 300$ upfront.",
  //       status: "In Progress",
  //       leadStatus: null,
  //       type: "to-do",
  //     },
  //   ],
  //   setData,
  // ];

  // return [
  //   [
  //     { id: 1, name: "Lead Task 1", status: ProgressStatus[0], type: "lead" },
  //     { id: 2, name: "Sales Task 1", status: ProgressStatus[1], type: "sales" },
  //     {
  //       id: 3,
  //       name: "Feature Task 1",
  //       status: ProgressStatus[2],
  //       type: "feature",
  //     },
  //     // Add more tasks with different types as needed
  //   ],
  //   setData,
  // ];
};

export default KanbanView;
