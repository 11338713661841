import { BarChart } from "./components/Chart";
import { useChartDummyData } from "./components/useData";

export const ActivityView = () => {
  const chartData = useChartDummyData();
  return (
    <div className="chart-container mt-4">
      <BarChart chartData={chartData} />
    </div>
  );
};

export default ActivityView;
