import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { AppStore } from "../../Store";
import Dropdown from "react-bootstrap/Dropdown";
import { logout } from "../../../api/auth";
import classNames from "classnames";
import { getAuthorizeUrl } from "../../utils";
import { AppRoute } from "../../../interfaces";

// Icons
import arrow_right from "../../assets/arrow_right.svg";
import verified_icon from "../../assets/verified-fill.svg";

// Styles
import "./style.sass";

export const Persona = ({ className, onClickCallback, minimized = true }) => {
  const { user } = useContext(AppStore);
  const targetMenu = useRef(null);
  const navigate = useNavigate({});
  const [isMenuShown, setIsMenuShown] = useState(false);

  const RootView = ({ children, minimized, dropdown = false }) => {
    if (!dropdown)
      return (
        <div
          className={classNames("persona", { expanded: !minimized })}
          onClick={() => {
            if (!onClickCallback) {
              navigate({
                to: AppRoute.Account,
              });
            } else {
              onClickCallback();
            }
          }}
        >
          {!minimized && <div className="divider" />}
          <div className="persona-container">{children}</div>
        </div>
      );

    return (
      <Dropdown
        className="persona-container"
        drop="down"
        align="end"
        show={isMenuShown}
        onToggle={(x, y) => {
          setIsMenuShown(x);
        }}
      >
        <Dropdown.Toggle as="div">{children}</Dropdown.Toggle>

        <Dropdown.Menu target={targetMenu.current}>
          <Dropdown.Item
            onClick={() => {
              logout().then(() => {
                window.location = getAuthorizeUrl();
              });
            }}
          >
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  if (!(user && user.persona)) return null;

  return (
    <RootView minimized={minimized} dropdown={false}>
      <div
        className={classNames("persona-avatar", className)}
        ref={targetMenu}
        style={{ backgroundColor: user.persona.color }}
        onClick={() => {
          setIsMenuShown(true);
        }}
      >
        <span className="persona-initial">{user.persona.abbr}</span>
      </div>
      {!minimized && (
        <>
          <div className="persona-details">
            <span className="persona-detail__header">
              {user.firstName
                ? user.firstName
                : user.email.slice(0, user.email.indexOf("@"))}
              {user?.plan?.type !== "free" && (
                <img
                  className="persona-detail__account-type"
                  src={verified_icon}
                  alt="Premium Account"
                />
              )}
            </span>
            <span className="persona-detail__subheader">{user.email}</span>
          </div>
          <img className="persona-action" src={arrow_right} alt="Arrow Right" />
        </>
      )}
    </RootView>
  );
};
