import React from "react";
import logo_icon from "../../assets/brand_icon_v5.png";
import { Button } from "react-bootstrap";

// Styles
import "./style.sass";

export const Promotion = () => (
  <div className="promotion">
    <img className="promotion-logo" src={logo_icon} alt="Cindi Icon" />
    <p className="promotion-header">Sign in or Create Account</p>
    <p className="promotion-info">
      Generate 24/7 your lab data and get recommendations
    </p>
    <Button className="promotion-btn" variant="dark">
      Create Account
    </Button>
  </div>
);
