import { useState, useEffect, useCallback, useContext } from "react";
import { Messages } from "../types";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { MessageStatus } from "../interfaces";
import { runCommand } from "./utils";
import { AppStore } from "./Store";

import { RouterProvider } from "@tanstack/react-router";
import { useAppControls } from "./components/panel";
import router from "./router/index";

// Styles
import "./App.sass";
import "./index.sass";

export const App = () => {
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState([]);
  const [data, setData] = useState([]);
  const [activeSnapshot, setActiveSnapshot] = useState(null);
  const [settings, setSettings] = useState({});
  const [appControls, activeControl, toggleControl] = useAppControls();
  const [notifications, setNotifications] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [errors, setErrors] = useState([]);
  const [config, setConfig] = useState(null);
  const [snapshots, setSnapshots] = useState({});
  const [loaders, setLoaders] = useState({});
  const [token, setToken] = useState(null);
  const [filters, setFilters] = useState({});

  const setLoader = useCallback(
    (obj) => {
      setLoaders((state) => ({
        ...state,
        ...obj,
      }));
    },
    [setLoaders]
  );

  useEffect(() => {
    runCommand(Messages.Ready);
  }, []);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (event, arg) => {
      const notification = JSON.parse(arg);

      setNotifications((notifications) => [...notifications, notification]);
    };

    window.ipcRenderer.on(Messages.Notification, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.Notification, listener);
    };
  }, [setNotifications]);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (event, arg) => {
      const { status, workspaces } = arg;
      if (status === MessageStatus.Error) {
        const { id, error } = arg;

        setErrors((errors) => [...errors, { id, error }]);
      } else {
        const { id } = arg;
        setWorkspaces(workspaces);
        setJobs((jobs) => [...jobs, { id }]);
      }
    };

    window.ipcRenderer.on(Messages.Sync, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.Sync, listener);
    };
  }, [setWorkspaces]);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (event, arg) => {
      setJobs((jobs) => [...jobs, arg]);
    };

    window.ipcRenderer.on(Messages.Job, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.Job, listener);
    };
  }, [setJobs]);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (event, arg) => {
      setConfig(arg.config);
    };

    window.ipcRenderer.on(Messages.AppConfig, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.AppConfig, listener);
    };
  }, [setConfig]);

  return (
    <AppStore.Provider
      value={{
        user,
        setUser,
        notifications,
        workspaces,
        jobs,
        errors,
        config,
        appControls,
        activeControl,
        toggleControl,
        chat,
        setChat,
        data,
        setData,
        settings,
        setSettings,
        snapshots,
        setSnapshots,
        activeSnapshot,
        setActiveSnapshot,
        loaders,
        setLoader,
        token,
        setToken,
        filters,
        setFilters,
      }}
    >
      <div className="app">
        <RouterProvider router={router} />
      </div>
    </AppStore.Provider>
  );
};

export default App;
