import React, { useContext, useEffect } from "react";
import { getUser } from "../../../api/auth";
import { getAuthorizeUrl, runCommand } from "../../utils";
import { Messages } from "../../../types";
import { Link, useNavigate } from "@tanstack/react-router";
import Accordion from "react-bootstrap/Accordion";
import classNames from "classnames";

// Icons
import icon_insights from "../../assets/home/icon_insights.svg";
import icon_lock from "../../assets/home/icon_lock.svg";
import icon_time from "../../assets/home/icon_time.svg";
import checkmark_icon from "../../assets/welcome/checkmark.svg";
import logo_v5 from "../../assets/brand_icon_v5.png";
import home_promotion from "../../assets/home_promotion.png";

import lab_cat_1 from "../../assets/lab/image.png";
import lab_cat_2 from "../../assets/lab/image-1.png";
import lab_cat_3 from "../../assets/lab/image-2.png";
import lab_cat_4 from "../../assets/lab/image-3.png";
import lab_cat_5 from "../../assets/lab/image-4.png";
import lab_cat_6 from "../../assets/lab/image-5.png";

// Components
import ChatApp from "../../components/chat";
import { AppStore } from "../../Store";
import Action from "../../components/action/action";
import { HeaderBrand } from "../../components/header";

import "./style.sass";
import { AuthorizeMode } from "../../../interfaces";

export const insightsSteps = [
  {
    title: "Upload your lab test",
    description: "Upload PDFs, images, or snapshots of your test results",
  },
  {
    title: "Get AI-Powered Analysis",
    description: "Let AI evaluate your biomarkers and detect patterns",
  },
  {
    title: "Receive Actionable Insights",
    description: "Get a detailed report with key findings and recommendations",
  },
];

export const labCategories = [
  {
    asset: lab_cat_1,
    title: "Blood Test",
    description: "Monitor overall health, immune system, and organ function",
  },
  {
    asset: lab_cat_2,
    title: "Urine Test",
    description: "Analyze kidney health, urinary tract, and detect infections",
  },
  {
    asset: lab_cat_3,
    title: "Swab Test",
    description: "Identify bacterial, viral, and fungal infections",
  },
  {
    asset: lab_cat_4,
    title: "Stool Test",
    description: "Evaluate digestive health, detect infections and parasites",
  },
  {
    asset: lab_cat_5,
    title: "Pap Smear",
    description: "Screen for cervical health, HPV, and precancerous conditions",
  },
  {
    asset: lab_cat_6,
    title: "Semen Analysis",
    description: "Assess sperm health, fertility, and motility",
  },
];

export const pricingPlans = [
  {
    id: 1,
    title: "FREE",
    price: "0",
    currency: "$",
    period: "month",
    benefits: [
      { title: "Upload up to 10 lab tests" },
      { title: "Up to daily 20 messages via our Health Assistant" },
    ],
    callToAction: "Get Free",
  },
  {
    id: 2,
    className: "accent",
    title: "Health Optimizer",
    price: "5",
    currency: "$",
    period: "month",
    benefits: [
      { title: "Upload unlimited lab tests" },
      { title: "Unlimited messages via our Health Assistant" },
      { title: "Full integration support with healthcare services" },
    ],
    callToAction: "Get Started",
  },
  {
    id: 3,
    className: "accent",
    title: "Family Pack",
    price: "15",
    currency: "$",
    period: "month",
    benefits: [
      { title: "Support for up to 5 family members" },
      { title: "Upload unlimited lab tests" },
      { title: "Unlimited messages via our Health Assistant" },
      { title: "Full integration support with healthcare services" },
    ],
    callToAction: "Get Started",
  },
];

export const footerOptions = [
  {
    title: "About us",
    to: "#",
  },
  {
    title: "Privacy Policy",
    to: "#",
  },
  {
    title: "Terms of Use",
    to: "#",
  },
];

export const homeOptions = [
  {
    title: "What is Cindi?",
    subtitle:
      "Cindi is your AI health assistant, helping you analyze lab tests, track health, and securely store medical documents—all in one place.",
  },
  {
    title: "How does the AI analyze my lab test results?",
    subtitle: "",
  },
  {
    title: "What if I don't understand my report?",
    subtitle: "",
  },
  {
    title: "Is this analysis a medical diagnosis?",
    subtitle: "",
  },
  {
    title: "What should I do after receiving my report?",
    subtitle: "",
  },
  {
    title: "How is my health data protected?",
    subtitle: "",
  },
  {
    title: "What types of lab tests are supported?",
    subtitle: "",
  },
  {
    title: "How fast will I get my report?",
    subtitle: "",
  },
];

export const Footer = () => (
  <div className="footer">
    <p className="footer__credits">
      © 2025. Cindi Software LTD. All rights reserved.
    </p>
    <div className="footer__links">
      {footerOptions.map((option) => (
        <Link key={option.title} to={option.to}>
          {option.title}
        </Link>
      ))}
    </div>
  </div>
);

export const Home = () => {
  const { setUser, loaders, token } = useContext(AppStore);
  const navigate = useNavigate({});

  useEffect(() => {
    getUser(false).then((user) => {
      setUser(user);
    });

    runCommand(Messages.AppConfig);
  }, [navigate]);

  const progressSteps = [
    "Scanning lab data...",
    "Creating overview for you...",
    "Generating tailored recommendations.",
  ];

  return (
    <>
      <div className="app-main">
        <HeaderBrand hasLinks={true} />
        {loaders.isFirstPrompt ? (
          <div className="home-container">
            <div className="home-progress-container">
              <img className="header-icon v2" src={logo_v5} alt="logo" />
              <div className="progress">
                <div
                  className="progress-bar progress-bar-animated"
                  role="progressbar"
                  style={{ width: `120px` }}
                  aria-valuenow={20}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <p>Generating the overview can take up to 2 mins</p>
              <div className="home-progress-steps">
                {progressSteps.map((progressStep, idx) => (
                  <div key={idx} className="home-progress-step">
                    <img src={checkmark_icon} alt="Checkmark" />
                    <p>{progressStep}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="home-container">
            <div className="home-inner-container">
              <p className="home-helper-title">
                Easily Store, Analyze, and Interpret
                <wbr /> Your Medical Lab Tests
              </p>
              <p className="home-helper-text">
                Securely upload your results to one platform. Get detailed
                biomarker insights <wbr />
                and turn them into actionable health plans
              </p>
            </div>
            <ChatApp isHeadless={true} isMultiStep={true} />
            <div className="home-tools">
              <div className="home-tools-container">
                <div className="home-tool-item">
                  <img src={icon_time} alt="Analyse" />
                  <p>
                    Jargon-Free explanations to understand complex lab results
                  </p>
                </div>
                <div className="home-tool-item">
                  <img src={icon_lock} alt="Summarize" />
                  <p>
                    Your health data is safe and confidential - Privacy First
                  </p>
                </div>
                <div className="home-tool-item">
                  <img src={icon_insights} alt="Recommend" />
                  <p>Access anytime your health insights, wherever you are</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="home-insights">
          <div>
            <p className="home-insight-title">
              Get Health Insights in 3 Easy Steps
            </p>
            {insightsSteps.map((step, index) => (
              <div key={step.title} className="insight-container">
                <p className="insight-title">
                  {String(index + 1).padStart(2, "0")} {step.title}
                </p>
                <p className="insight-description">{step.description}</p>
              </div>
            ))}
          </div>
          <div>
            <img
              className="home-insights-asset"
              src={home_promotion}
              alt="Home WelcomePromotion"
            />
          </div>
        </div>
        <div className="home-categories">
          <p className="home-category-title">Supported Lab Test Types</p>
          <p className="home-category-subtitle">
            We support a variety of lab test types our software can analyze
          </p>
          <div className="home-categories-sub">
            {labCategories.map((labCat) => (
              <div key={labCat.title} className="home-category">
                <img
                  className="lab-category-asset"
                  src={labCat.asset}
                  alt={labCat.title}
                />
                <div className="lab-category-details">
                  <p className="lab-category-details__details">
                    {labCat.title}
                  </p>
                  <span className="lab-category-details__description">
                    {labCat.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="home-call-to-action">
          <HeaderBrand className="mb-2" />
          <p className="home-call-to-action__title">
            Keep your health recommendations always within reach
          </p>
          <Action
            className="mt-2"
            to={getAuthorizeUrl(AuthorizeMode.SignUp)}
            text="Try For Free"
          />
        </div>
        <div className="home-pricing">
          <p className="home-pricing__title">Our Pricing</p>
          <p className="home-pricing__subtitle">
            Keep your health recommendations always within reach. Start free.
          </p>
          <div className="home-pricing-plans">
            {pricingPlans.map((pricingPlan) => (
              <div
                key={pricingPlan.id}
                className={classNames(
                  "home-pricing-plan",
                  pricingPlan.className
                )}
              >
                <p className="home-pricing-plan__title">{pricingPlan.title}</p>
                <p className="home-pricing-plan__pricing">
                  <span className="home-pricing-plan__pricing__price">
                    {pricingPlan.price}
                    {pricingPlan.currency}
                  </span>{" "}
                  per {pricingPlan.period}
                </p>
                <div className="home-pricing-plan__benefits mb-2">
                  {pricingPlan.benefits.map((benefit) => (
                    <div
                      key={benefit.title}
                      className="home-pricing-plan__benefit"
                    >
                      <img
                        className="plan-benefit__checkmark"
                        src={checkmark_icon}
                        alt="Checkmark"
                      />
                      <span className="plan-benefit__title">
                        {benefit.title}
                      </span>
                    </div>
                  ))}
                </div>
                <Action
                  className="mt-auto w-100"
                  btnClassName="expand"
                  to={getAuthorizeUrl(AuthorizeMode.SignUp, pricingPlan.id)}
                  text={pricingPlan.callToAction}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="home-faq">
          <p className="home-faq__title">
            We don’t support your document format? Don’t worry, we can help!
          </p>
          <p className="home-faq__subtitle">
            Have questions? Let’s find answers
          </p>
          <Accordion className="home-faq__items" defaultActiveKey={["0"]}>
            {homeOptions.map((homeOption, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>{homeOption.title}</Accordion.Header>
                <Accordion.Body>{homeOption.subtitle}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
