import Spinner from "react-bootstrap/Spinner";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import drag_n_drop from "../../assets/drag_n_drop.svg";
import drag_n_drop_v2 from "../../assets/drag_n_drop_v2.svg";
import classNames from "classnames";
import file_icon from "../../assets/tree/files/file_icon.svg";
import close_icon from "../../assets/close_black.svg";
import delete_icon from "../../assets/delete_bin.svg";
import React, { useEffect, useRef } from "react";
import { allowedFileTypes, maxTotalFileSize } from "../../config";
import Action from "../action/action";

export const TargetBox = (props) => {
  const {
    onDrop,
    onRemove,
    droppedFiles,
    handleFilesUpload,
    setDroppedFiles,
    isFilePickerEnabled,
    isDropped,
    totalFileSize,
    className,
    isDisabled,
    isCommit,
  } = props;

  const fileInputRef = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop() {
        return !isDisabled;
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        if (item) {
          console.log("collect", item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  useEffect(() => {
    const callback = () => {
      // Do nothing.
    };
    const inputRef = fileInputRef.current;
    if (!inputRef) return;

    inputRef.addEventListener("cancel", callback);
    return () => {
      inputRef.removeEventListener("cancel", callback);
    };
  }, [fileInputRef.current]);

  const renderDroppedElements = () => {
    if (!isCommit) {
      return (
        <div className="container-inner">
          <Spinner
            className="me-2"
            animation="border"
            variant="light"
            size="sm"
          />
          <span>Processing {droppedFiles.length} items</span>
        </div>
      );
    }

    const style = "trash";
    if (droppedFiles && droppedFiles.length > 0) {
      return (
        <div className="container-inner">
          {droppedFiles.map((file) => {
            return (
              // TODO: Not the best to have the name as key, because of dups.
              <div key={file.name} className="file-preview">
                {style === "inline" ? (
                  <>
                    <img
                      className="file-preview__btn-remove remove"
                      src={close_icon}
                      alt="Remove File"
                      onClick={(event) => {
                        event.stopPropagation();
                        onRemove(file);
                      }}
                    />
                    <img
                      className="file-preview__img"
                      src={file_icon}
                      alt={file.name}
                    />
                    <span className="file-preview__text">{file.name}</span>
                  </>
                ) : (
                  <>
                    <span className="file-preview__text">{file.name}</span>
                    <img
                      className="file-preview__btn-remove delete"
                      src={delete_icon}
                      alt="Remove File"
                      onClick={(event) => {
                        event.stopPropagation();
                        onRemove(file);
                      }}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const getElementsContainer = ({ isRoot }) => {
    if (isRoot)
      return (
        <div className="container-elements">
          {isDropped ? (
            renderDroppedElements()
          ) : isActive ? (
            <div className="container-inner-elements">
              <img className="upload-icon" src={drag_n_drop} alt="All files" />
              <p className="m-0">Release to drop</p>
              <p className="m-0 small" />
            </div>
          ) : (
            <div className="container-inner-elements">
              <img className="upload-icon" src={drag_n_drop} alt="All files" />
              <p className="m-0">Drag anything here</p>
              <p className="m-0 small">Organize and clean</p>
            </div>
          )}
        </div>
      );

    return (
      <div className="container-elements">
        <div className="container-inner-elements">
          <img className="upload-icon" src={drag_n_drop_v2} alt="All files" />
          <p className="fw-bold">Drag & drop or click to add your lab test</p>
          <p className="small">
            Upload the lab test as PDF, PNG, JPEG or JPEG, max 15MB.
          </p>
        </div>
      </div>
    );
  };

  const isLimitReached = totalFileSize > maxTotalFileSize;
  const isActive = (canDrop && isOver) || isDropped;
  const isRoot = !className.includes("multi-step");
  return (
    <div
      ref={drop}
      className={classNames(className, {
        active: isActive,
        disabled: isDisabled,
        "is-over": isOver,
        "is-dropped": isDropped,
      })}
    >
      <div
        className="sync-container"
        onClick={() => {
          if (isFilePickerEnabled) fileInputRef.current.click();
        }}
      >
        {getElementsContainer(isRoot)}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept={allowedFileTypes.join(",")}
          onChange={(event) => {
            const selectedFiles = Array.from(event.target.files);
            if (selectedFiles.length > 0) {
              setDroppedFiles(selectedFiles);
            }
          }}
          multiple
        />
      </div>

      {renderDroppedElements()}
      {droppedFiles.length > 0 && (
        <div className="container-attachments-action">
          <span className="container-attachments-action__stats">
            Attached {droppedFiles.length} items.{" "}
            {(totalFileSize / 1024 / 1024).toFixed(2)} MB attachment size.
          </span>{" "}
          {isLimitReached && (
            <span className="container-attachments-action__stats error">
              Exceeds {maxTotalFileSize / 1024 / 1024} MB limit
            </span>
          )}
          <div className="container-attachments-action__btn-area">
            <Action
              disabled={isLimitReached}
              onClick={() => {
                handleFilesUpload();
              }}
            >
              {className.includes("normal-flow")
                ? "Upload Lab Test"
                : "Explore your biomarkers"}
            </Action>
          </div>
        </div>
      )}
    </div>
  );
};
