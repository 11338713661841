// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kanban-board {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
.kanban-board .column {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 10px;
  margin: 0 10px;
}
.kanban-board .column-body {
  flex: 1 1;
  border-radius: 5px;
}
.kanban-board ul {
  list-style-type: none;
  padding: 0;
}
.kanban-board li {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.kanban-board li:hover {
  background-color: #f0f0f0;
}
.kanban-board .card-body {
  padding: 10px;
}
.kanban-board .card-title {
  margin-bottom: 5px;
}
.kanban-board .badge {
  margin-left: 5px;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  min-height: 0;
  font-weight: 600;
}
.kanban-board .badge.badge-pill {
  background: #007bff !important;
  color: white !important;
  border-radius: 12px;
}
.kanban-board .badge.badge-outline {
  background: rgba(0, 123, 255, 0.1);
  color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/views/kanban/style.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,cAAA;AACJ;AACE;EACE,SAAA;EACA,kBAAA;AACJ;AACE;EACE,qBAAA;EACA,UAAA;AACJ;AAEE;EACE,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;AAAJ;AAEI;EACE,8BAAA;EACA,uBAAA;EACA,mBAAA;AAAN;AAEI;EACE,kCAAA;EACA,cAAA;AAAN","sourcesContent":[".kanban-board\n  width: 100%\n  height: 100%\n  display: flex\n  justify-content: space-around\n  padding: 20px\n\n  .column\n    display: flex\n    flex-direction: column\n    flex: 1\n    padding: 10px\n    margin: 0 10px\n\n  .column-body\n    flex: 1\n    border-radius: 5px\n\n  ul\n    list-style-type: none\n    padding: 0\n\n\n  li\n    background-color: #fff\n    border: 1px solid #ccc\n    border-radius: 5px\n    padding: 10px\n    margin-bottom: 5px\n    cursor: pointer\n\n  li:hover\n    background-color: #f0f0f0\n\n  .card-body\n    padding: 10px\n\n  .card-title\n    margin-bottom: 5px\n\n  .badge\n    margin-left: 5px\n    color: #fff\n    padding: 4px 8px\n    border-radius: 4px\n    min-height: 0\n    font-weight: 600\n\n    &.badge-pill\n      background: #007bff !important\n      color: white !important\n      border-radius: 12px\n\n    &.badge-outline\n      background: transparentize(#007bff, 0.9)\n      color: #007bff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
