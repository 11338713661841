import React, { useContext, useEffect, useMemo, useState } from "react";

import Form from "react-bootstrap/Form";
import { marked } from "marked";
import { BarChart } from "../../../routes/memory/components/Chart";
import TableView from "../../table/table";
import Select from "react-select";
import { testAPI } from "../../../../api/query";
import useReportData from "./useReportData";
import ReportViewDetails from "./ReportViewDetails";
import { EmptyView } from "./EmptyView";
import { parseStrToInt } from "../../../utils";

// Icons
import report_icon from "../../../assets/chart_icon.svg";
import arrow_right_icon from "../../../assets/arrow_right_long.svg";

// Styles
import "./style.sass";

const parseDate = (value) => {
  let startTime = new Date(value);
  startTime = new Date(
    startTime.getTime() - startTime.getTimezoneOffset() * 60000
  );
  return startTime.toLocaleString();
};

const ReportSelection = ({ reports, reportId, onChange }) => {
  return (
    <Form.Select
      aria-label="Medical report selection"
      onChange={onChange}
      value={reportId}
    >
      {reports.map((report) => (
        <option key={report.id} value={report.id}>
          {report.title} {parseDate(report["started_at"])}
        </option>
      ))}
    </Form.Select>
  );
};

export const Overview = ({ overview, showRaw = false }) => {
  if (!overview) return null;

  return (
    <div className="overview">
      <div className="mt-4">
        <b>{overview.title}</b>
      </div>
      <div>Generated on: {parseDate(overview.started_at)}</div>
      <div>
        Generation time:{" "}
        {(
          (new Date(overview.finished_at).getTime() -
            new Date(overview.started_at).getTime()) /
          1000.0
        ).toFixed(0)}
        s
      </div>
      {showRaw && (
        <div
          dangerouslySetInnerHTML={{
            __html: marked.parse(overview.response),
          }}
        />
      )}
    </div>
  );
};

export const GeneralReportView = ({ reportData }) => {
  const {
    parameters,
    selectedParameters,
    setSelectedParameters,
    overview,
    reportTable,
    chart,
    reports,
    reportId,
    setReportId,
  } = reportData;

  return (
    <div style={{ padding: "0 24px" }}>
      <div className="marker-selection">
        <p>Choose Marker:</p>
        <Select
          className="dropdown"
          placeholder="Select Option"
          value={selectedParameters}
          options={parameters}
          onChange={(e) => {
            setSelectedParameters(Array.isArray(e) ? e.map((x) => x) : []);
          }}
          isMulti
          isClearable
        />
      </div>
      {chart && (
        <div className="mt-2">
          <BarChart
            className="chart-report"
            chartData={chart.data}
            chartOptions={chart.options}
          />
        </div>
      )}
      <div className="mt-4">
        <p>Choose Report:</p>
        {reports && reports.length > 0 ? (
          <ReportSelection
            reports={reports}
            reportId={reportId ?? undefined}
            onChange={(event) => {
              setReportId(parseStrToInt(event.target.value));
            }}
          />
        ) : (
          "-"
        )}
      </div>
      <button
        className="btn-overview mt-2"
        onClick={() => {
          testAPI().finally(() => {
            console.log("Test completed.");
          });
        }}
      >
        <img
          className="btn-overview-icon"
          src={report_icon}
          alt="Report overview"
        />
        <span>Generate overview</span>
      </button>
      <Overview overview={overview} />
      {reportTable && (
        <TableView
          className="white uppercase"
          columns={reportTable.otherColumns}
          data={reportTable.otherRows}
          hoverable={true}
          // onRowClick={onRowClick}
        />
      )}
    </div>
  );
};

export const ReportView = ({ model, setModel, onTriggerUploadModal }) => {
  const { local_files: localFiles } = model;
  const localFilesByType = useMemo(() => {
    if (localFiles == null) return {};

    return Object.groupBy(localFiles, (x) => x.type);
  }, [localFiles]);

  const reportData = useReportData({
    model,
    localFilesByType,
    isGrouped: true,
  });

  const [selectedMarker, setSelectedMarker] = useState(null);
  const isVisible = useMemo(() => {
    return selectedMarker != null;
  }, [selectedMarker]);

  const {
    parameters,
    selectedParameters,
    setSelectedParameters,
    overview,
    reportTable,
    chart,
    reports,
    reportId,
    setReportId,
  } = reportData;

  const [isGrouped, setIsGrouped] = useState(true);

  return (
    <div className="view-container">
      {(parameters == null || parameters.length === 0) && (
        <EmptyView onTriggerUploadModal={onTriggerUploadModal} />
      )}
      <ReportViewDetails
        isVisible={isVisible}
        parameter={selectedMarker}
        onHide={() => {
          setSelectedMarker(null);
        }}
      />
      {!isGrouped ? (
        <GeneralReportView reportData={reportData} />
      ) : (
        <div className="parameters-view">
          {parameters.map((parameter) => {
            // First report (i.e., sorted).
            const report = parameter.reports[0];
            return (
              <div
                key={parameter.value}
                className="parameter-container"
                onClick={() => {
                  setSelectedMarker(parameter);
                }}
              >
                <div className="parameter-graph">
                  {parameter.chart && parameter.chart.data && (
                    <BarChart
                      className="chart-report simple"
                      chartData={parameter.chart.data}
                      chartOptions={parameter.chart.options}
                      showLegend={false}
                      showTitle={false}
                    />
                  )}
                </div>
                <div className="parameter-details">
                  <p className="parameter-details__label">{parameter.value}</p>
                  {report["Reference Range"] && (
                    <p className="parameter-details__ref">
                      Ref range: {report["Reference Range"]}
                    </p>
                  )}
                  <p className="parameter-details__value">
                    {report["Result"]} {report["Unit"]}
                  </p>
                  <p className="parameter-details__date">
                    Updated:{" "}
                    {new Date(report["Date"]).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </div>
                <div className="parameter-action">
                  <span className="parameter-action__count">
                    ({parameter.reports.length})
                  </span>
                  <img
                    className="parameter-action__expand"
                    src={arrow_right_icon}
                    alt="Click to expand"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReportView;
