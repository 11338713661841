import React from "react";

import "./style.sass";

export const ProgressBar = ({ current, min, max }) => {
  const perc = (current / max) * 100.0;
  const width = `${perc}%`;

  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width }}
        aria-valuenow={perc}
        aria-valuemin={min}
        aria-valuemax={max}
      />
    </div>
  );
};

export default ProgressBar;
