import React, { useEffect, useContext } from "react";
import { useNavigate } from "@tanstack/react-router";
import useModal from "../../components/modal/modal";

// Components
import Header from "../../components/header";
import Body from "../../components/body";
import Footer from "../../components/footer";
import { Menu } from "../../components/menu/menu";
import { AppStore } from "../../Store";
import ChatApp from "../../components/chat";
import { getAuthorizeUrl } from "../../utils";

export const Main = () => {
  const { user } = useContext(AppStore);
  const navigate = useNavigate();

  const uploadFilesModal = useModal();
  const { setShow: setShowModal, ModalWithChildren } = uploadFilesModal;

  const onTriggerUploadModal = () => {
    if (!uploadFilesModal) return;

    const { setShow: setShowModal, setModalOptions } = uploadFilesModal;

    setShowModal(true);
    setModalOptions({
      className: "modal-upload",
      secondaryAction: null,
      primaryAction: null,
      header: "Upload your Lab Test",
    });
  };

  useEffect(() => {
    if (!user) {
      navigate({
        to: getAuthorizeUrl(),
      });
    }
  }, [user, navigate]);

  return (
    <>
      <Menu />
      <ModalWithChildren>
        <ChatApp
          isHeadless={true}
          isMultiStep={true}
          isUpload={true}
          syncClassName="normal-flow"
          onFilesUploadCallback={() => {
            setShowModal(false);
          }}
        />
      </ModalWithChildren>
      <div className="app-main">
        <Header onTriggerUploadModal={onTriggerUploadModal} />
        <Body onTriggerUploadModal={onTriggerUploadModal} />
        <Footer />
      </div>
    </>
  );
};

export default Main;
