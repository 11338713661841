import React, { useContext, useState, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { AppStore } from "../../Store";

// API
import { testAPI } from "../../../api/query";

// Styles
import "./style.sass";

export const SHOW_TEST_BTN = true;

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div>
    <span
      className="settings-link"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </span>
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export const Settings = () => {
  const { user, settings } = useContext(AppStore);

  const activeModels = useMemo(() => {
    if (settings == null || settings.llm == null) return null;

    return new Set(Object.values(settings.llm.active));
  });

  const title = useMemo(() => {
    if (activeModels == null) return null;
    if (activeModels.size > 1) return "Active Models: Mixed";

    const key = Object.values(settings.llm.active)[0];
    return settings.llm.models.find(({ alias }) => alias === key).name;
  }, [activeModels]);

  if (settings == null || settings.llm == null) return null;

  return (
    <div className="settings-container">
      <Dropdown className="settings-dropdown">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {title}
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          {settings.llm.models.map((model) => (
            <Dropdown.Item
              key={model.alias}
              active={activeModels.has(model.alias)}
            >
              {model.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
