// Components
import AppControls from "../panel";
import { MagicView } from "../views";
import Notification from "../notification";
import Sync from "../sync";
import ChatApp from "../chat";

// Styles
import "./style.sass";

const isSyncEnabled = false;

export const NotificationPanel = () => {
  return (
    <div>
      <small className="ms-2 text-muted">Latest:</small>
      <Notification />
    </div>
  );
};

export const Body = ({ onTriggerUploadModal }) => {
  return (
    <div className="app-body position-relative">
      {isSyncEnabled && <Sync className="mb-3" />}
      <div className="app-split-view d-flex flex-row">
        <MagicView onTriggerUploadModal={onTriggerUploadModal} />
        <ChatApp isHidden={false} isFloating={true} />
      </div>
      {/*<NotificationPanel />*/}
      <AppControls />
    </div>
  );
};

export default Body;
