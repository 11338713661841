import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Action from "../action/action";
import classNames from "classnames";

// Icons
import warning_icon from "../../assets/warning_icon.svg";

// Styles
import "./style.sass";

export const ModalActions = (props) => {
  const {
    btnClassName = null,
    primaryAction = "Confirm",
    primaryActionCallback = null,
    secondaryAction = "Cancel",
    secondaryActionCallback = null,
  } = props;

  return (
    <div className="modal-body__actions">
      {secondaryAction && (
        <Action
          btnClassName={classNames("outline", btnClassName)}
          onClick={secondaryActionCallback}
          text={secondaryAction}
        />
      )}
      {primaryAction && (
        <Action
          btnClassName={classNames("warning", btnClassName)}
          onClick={primaryActionCallback}
          text={primaryAction}
        />
      )}
    </div>
  );
};

export const useModal = () => {
  const [modalOptions, setModalOptions] = useState(null);
  const updateModalOptions = useCallback(
    (state) => {
      setModalOptions((prevState) => ({
        ...prevState,
        ...state,
      }));
    },
    [setModalOptions]
  );

  const {
    handleClose,
    className = null,
    error = null,
    header = null,
    subheader = null,
    title = null,
    subtitle = null,
    backdrop = true,
    primaryAction = "Confirm",
    primaryActionCallback = null,
    secondaryAction = "Cancel",
    secondaryActionCallback = null,
  } = modalOptions ?? {};

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!error) return;

    const timeoutId = setTimeout(() => {
      updateModalOptions({ error: null });
    }, 3 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error, updateModalOptions]);

  const Component = ({ children }) => (
    <>
      {error && (
        <Alert className="alert-message py-2 px-3" variant="danger">
          <img src={warning_icon} alt="Alert Error" />
          <span className="alert-message__description">{error}</span>
        </Alert>
      )}
      <Modal
        key={title}
        className={classNames(className)}
        show={show}
        onHide={() => {
          setShow(false);

          if (handleClose) handleClose();
        }}
        backdrop={backdrop}
      >
        {title && (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            {subtitle && <Modal.Title>{subtitle}</Modal.Title>}
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="modal-body__content">
            {header && <p className="modal-body__content__header">{header}</p>}
            {subheader && (
              <div className="modal-body__content__subheader">{subheader}</div>
            )}
            {children}
          </div>
          <ModalActions
            secondaryAction={secondaryAction}
            secondaryActionCallback={() => {
              if (secondaryActionCallback) {
                secondaryActionCallback();
              } else {
                setShow(false);
              }
            }}
            primaryAction={primaryAction}
            primaryActionCallback={() => {
              if (primaryActionCallback) {
                primaryActionCallback();
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );

  return {
    show,
    setShow,
    modal: <Component children={null} />,
    ModalWithChildren: Component,
    setModalOptions,
    updateModalOptions,
  };
};

export default useModal;
