import Card from "react-bootstrap/Card";

import download_icon from "../../../assets/download_icon.svg";

import { ActionType } from "../../../../types";

// Assets
import rename_icon from "../../../assets/actions/rename.svg";
import move_icon from "../../../assets/actions/move_item.svg";
import click_icon from "../../../assets/actions/click.svg";
import content_paste_icon from "../../../assets/actions/content_paste.svg";

export const ActionIcon = {
  [ActionType.RENAME]: rename_icon,
  [ActionType.MOVE]: move_icon,
  [ActionType.CLICK]: click_icon,
  [ActionType.COPY]: content_paste_icon,
};

export const MagicNotification = ({ notification, actions }) => {
  const actionMove = notification.actions.find(
    (action) => action.type === ActionType.MOVE
  );

  return (
    <Card.Body>
      <Card.Title className="d-flex align-items-center h6">
        <img
          className="event-icon me-2"
          src={download_icon}
          alt="Download Event"
        />
        {notification.title}
      </Card.Title>
      <Card.Text className="card-text">
        {notification.actions.map((action) => {
          const icon = ActionIcon[action.type];
          return (
            <div className="small mb-2">
              <img className="me-2" src={icon} alt={action.type} />
              <span>{action.description}</span>{" "}
              <span className="highlight">{action.value}</span>
            </div>
          );
        })}
      </Card.Text>
      <div className="card-actions">
        <div>
          {actionMove && (
            <Card.Link
              href="#"
              onClick={() => actions.openDir(actionMove.value)}
            >
              Open Folder
            </Card.Link>
          )}
          <Card.Link
            href="#"
            onClick={() => actions.openFile(notification.path)}
          >
            Preview
          </Card.Link>
        </div>
        <div>
          <Card.Link href="#" onClick={() => actions.undo()}>
            Undo
          </Card.Link>
        </div>
      </div>
    </Card.Body>
  );
};
