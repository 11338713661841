import { Data } from "../data";

export const useChartDummyData = () => {
  return {
    labels: Data.map((data) => data.day),
    datasets: [
      {
        label: "Work",
        data: Data.map((data) => data.work),
        backgroundColor: "#8F59A6",
        borderColor: "black",
        borderWidth: 2,
      },
      {
        label: "Leisure",
        data: Data.map((data) => data.leisure),
        backgroundColor: "#A0E810",
        borderColor: "black",
        borderWidth: 2,
      },
      // {
      //   label: "Other",
      //   data: Data.map((data) => data.userLost),
      //   backgroundColor: "#d0712a",
      //   borderColor: "black",
      //   borderWidth: 2,
      // },
    ],
  };
};
