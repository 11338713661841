import React, { useContext, useMemo, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import { deleteFile, toggleFileStatus, magicFile } from "../../../../api/query";
import { AppStore } from "../../../Store";
import TableView from "../../table/table";
import DocumentView from "../document/DocumentView";
import Snapshot from "../snapshot/SnapshotView";
import useModal from "../../modal/modal";
import { ViewType } from "../../../../interfaces";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import classNames from "classnames";
import { IsColumnIdentifierShown } from "../../../config";
import { EmptyView } from "./EmptyView";

// Icons
import info_icon from "../../../assets/info_icon.svg";
import share_icon from "../../../assets/share_icon.svg";
import chat_star_icon from "../../../assets/chat_star_icon.svg";
import document_scanner_icon from "../../../assets/document_scanner_icon.svg";
import redo_icon from "../../../assets/redo_icon.svg";
import trash_icon from "../../../assets/delete_bin.svg";
import file_icon from "../../../assets/file_icon.svg";
import icon_settings from "../../../assets/more_vert.svg";

// Styles
import "./style.sass";

export const DocumentLink = ({ className, document, onClick }) => {
  if (!document) return null;

  if (!document.ext) {
    return null;
  }

  const ext = document.ext.slice(1);
  return (
    <div
      className={classNames("attachment-container", className)}
      onClick={onClick}
    >
      <div className={classNames("attachment-file", ext)}>
        <img
          className="entity-snapshot-result"
          src={file_icon}
          alt="Document File"
        />
        <span className="entity-snapshot-caption">{ext.toUpperCase()}</span>
      </div>
      <span className="attachment-title">{document.title}</span>
    </div>
  );
};

export const ArtifactsView = ({ model, onTriggerUploadModal }) => {
  const navigate = useNavigate();
  const { location } = useRouterState();
  const { local_files: localFiles } = model;
  const { filters, user, setActiveSnapshot } = useContext(AppStore);
  const { setShow, modal, setModalOptions, updateModalOptions } = useModal();

  const updateFileStatus = (entity) => {
    toggleFileStatus({
      file_id: entity["id"],
    }).then((response) => {
      if (response.status) {
        setShow(false);
      } else {
        updateModalOptions({
          error: response.message,
        });
      }
    });
  };

  const contextMenuItems = useMemo(
    () => [
      {
        onClick: (entity) => {
          navigate({
            search: (state) => ({ ...state, id: entity["id"] }),
            replace: false,
            hash: location.hash,
          });
        },
        icon: info_icon,
        alt: "Document Overview",
        title: "Overview",
      },
      {
        onClick: (entity) => {
          magicFile({
            file_id: entity["id"],
            action_type: 0,
          }).then((response) => {
            console.log("Response: ", response);
          });
        },
        icon: document_scanner_icon,
        alt: "Scan Document",
        title: "Scan",
      },
      {
        onClick: (entity) => {
          magicFile({
            file_id: entity["id"],
            action_type: 1,
          }).then((response) => {
            console.log("Response: ", response);
          });
        },
        icon: redo_icon,
        alt: "Redo Document Parsing",
        title: "Parse",
      },
      {
        onClick: () => {
          console.log();
        },
        icon: share_icon,
        alt: "Share Document",
        title: "Share",
      },
      {
        onClick: updateFileStatus,
        icon: chat_star_icon,
        alt: "Disable Chat Memory File",
        title: "Disable File",
        isVisible: (entity) => entity.isEnabled,
      },
      {
        onClick: updateFileStatus,
        icon: chat_star_icon,
        alt: "Enable Chat Memory File",
        title: "Enable File",
        isVisible: (entity) => !entity.isEnabled,
      },
      {
        onClick: (entity) => {
          const callback = () => {
            deleteFile({
              file_id: entity["id"],
            }).then((response) => {
              if (response.status) {
                setShow(false);
              } else {
                updateModalOptions({
                  error: response.message,
                });
              }
            });
          };

          setShow(true);
          setModalOptions({
            header: "Are you sure to delete the following file?",
            subheader:
              "The file will be deleted forever with all its references and it cannot be undone.",
            primaryActionText: "Confirm",
            primaryActionCallback: callback,
          });
        },
        icon: trash_icon,
        alt: "Delete File",
        title: "Delete File",
      },
    ],
    [navigate]
  );

  const data = useMemo(() => {
    if (!localFiles) return [];

    let data = localFiles
      .filter(({ type }) => type === ViewType.File)
      .map(
        ({
          id,
          ext,
          title,
          status,
          file_upload_at,
          is_enabled,
          other,
          file_name,
          metadata,
          contents = "",
        }) =>
          metadata
            ? {
                id,
                ext,
                title,
                metadata: metadata,
                date: metadata.date,
                type: metadata.type,
                other,
                isEnabled: is_enabled ?? true,
                file_upload_at,
                file_name,
                attachments: "",
                provider: metadata.provider,
                contents,
                status,
              }
            : {
                id,
                ext,
                title,
                metadata: null,
                date: "",
                type: "",
                other,
                isEnabled: is_enabled ?? true,
                file_upload_at,
                file_name: null,
                attachments: "",
                provider: "",
                contents,
                status,
              }
      )
      .filter((x) => x)
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    if (filters.labTestFilter) {
      data = data.filter(({ type }) =>
        filters.labTestFilter.some((x) => x.value === type)
      );
    }

    if (filters.dateFilter) {
      data = data.filter(
        ({ date }) =>
          filters.dateFilter.from <= new Date(date) &&
          new Date(date) <= filters.dateFilter.to
      );
    }

    return data;
  }, [localFiles, filters]);

  const columns = React.useMemo(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: () => "#",
        cell: (cellData) => {
          const { row, getValue } = cellData;
          return (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>{getValue()}</Tooltip>}
            >
              <span
                onClick={() => {
                  try {
                    navigator.clipboard.writeText(getValue());
                  } catch {
                    // DO nothing.
                  }
                }}
              >
                {row.id}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: "date",
        accessorKey: "date",
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>Date</span>,
        sortingFn: "datetime",
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      },
      {
        accessorKey: "provider",
        cell: (info) => {
          if (info.getValue() == null) return null;

          return <div className="snapshot__container">{info.getValue()}</div>;
        },
        header: () => <span>Laboratory</span>,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
      },
      {
        accessorKey: "type",
        header: () => <span>Test Type</span>,
        cell: (info) => <div>{info.getValue()}</div>,
      },
      {
        accessorKey: "attachments",
        header: () => <span>Document</span>,
        cell: ({ row: { original: entity } }) => {
          return (
            <DocumentLink
              document={entity}
              onClick={(event) => {
                event.stopPropagation();

                setActiveSnapshot(entity);
              }}
            />
          );
        },
      },
      {
        accessorKey: "status",
        header: () => <span>Status</span>,
        cell: ({ row: { original: entity }, getValue }) => {
          const value = getValue() ?? 1;

          const getStatusStyle = (status) => {
            if (entity.isEnabled != null && !entity.isEnabled)
              return {
                style: "disabled",
                tooltip:
                  "Document insights have no effect in the overall health insights generation",
                value: "Disabled",
              };

            switch (status) {
              case 0:
                return {
                  style: "loading",
                  tooltip: "Loading and analysing the document",
                  value: (
                    <>
                      <Spinner className="me-1" size="xs" />
                      Loading
                    </>
                  ),
                };
              case 1:
                return {
                  style: "success",
                  tooltip:
                    "Document analysis completed with insights generated",
                  value: "Interpreted",
                };
              case 2:
                // TODO: If having a bad doc have like a sibling UI element with more details
                return {
                  style: "warning",
                  tooltip: "Document analysis failed",
                  value: "Invalidated",
                };
              case -1:
                return {
                  style: "disabled",
                  tooltip:
                    "Document insights have no effect in the overall health insights generation",
                  value: "Disabled",
                };
              default:
                return null;
            }
          };

          const status = getStatusStyle(value);
          return (
            status && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>{status.tooltip}</Tooltip>}
              >
                <div
                  className={classNames(
                    "badge-marker clickable accent",
                    status.style
                  )}
                >
                  {status.value}
                </div>
              </OverlayTrigger>
            )
          );
        },
      },
      {
        accessorKey: "actions",
        header: () => <span>Actions</span>,
        cell: ({ getValue, row: { original: entity } }) => {
          const value = getValue();

          return (
            <div className="status-container">
              {value === -1 && (
                <div className="d-inline-flex">
                  <Spinner text="In-progress" size="sm" />
                  <span className="ms-2">Loading</span>
                </div>
              )}
              <Dropdown
                className="table-details-dropdown"
                drop="down"
                align="end"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Dropdown.Toggle as="div">
                  <img
                    className="table-details-icon"
                    src={icon_settings}
                    alt="Icon Settings"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {contextMenuItems
                    .filter((x) => x.isVisible == null || x.isVisible(entity))
                    .map((contextMenuItem) => (
                      <Dropdown.Item
                        key={contextMenuItem.title}
                        onClick={() => {
                          contextMenuItem.onClick(entity);
                        }}
                      >
                        <img
                          src={contextMenuItem.icon}
                          alt={contextMenuItem.alt}
                        />
                        <span>{contextMenuItem.title}</span>
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
        enableSorting: false,
      },
    ],
    []
  );

  const onRowClick = (entity) => {
    navigate({
      search: (state) => ({ ...state, id: entity["id"] }),
      replace: false,
      hash: location.hash,
    });
  };

  const defaultSortBy = useMemo(() => {
    return [
      {
        id: "date",
        desc: true,
      },
    ];
  }, []);

  return (
    <div className="view-container">
      <Snapshot />
      {(data == null || data.length === 0) && (
        <EmptyView onTriggerUploadModal={onTriggerUploadModal} />
      )}
      <TableView
        className="white uppercase"
        columns={columns}
        columnVisibility={{
          id: user?.role === "admin" && IsColumnIdentifierShown,
        }}
        initialState={{
          sorting: defaultSortBy,
        }}
        data={data}
        hoverable={true}
        onRowClick={onRowClick}
      />
      <DocumentView />
      {modal}
    </div>
  );
};

export default ArtifactsView;
