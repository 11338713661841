import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./style.sass";
import classNames from "classnames";
import { AppStore } from "../../Store";
import Search from "../search";
import { magic } from "../../../api/query";
import { OsEventType } from "../../../types";
import { marked } from "marked";
import { IS_PROMPT_STATE_ENABLED } from "../../config";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Dropdown from "react-bootstrap/Dropdown";
import { getDateNowFormatted, parseDate } from "../../utils";
import { isUserAdmin } from "../../utils";

// Icons
import magic_icon from "../../assets/brand_icon_v6.svg";
import undo_icon from "../../assets/undo_icon.svg";
import delete_bin from "../../assets/delete_bin.svg";

export const MAX_TIME_GAP_MIN = 40;

export const ActionBar = ({ itemKey, message }) => {
  const { user, setChat, setData, setSnapshots } = useContext(AppStore);
  const tooltipMessage = useMemo(() => {
    return !isUserAdmin(user) ? "Revert" : `Revert (${message?.id})`;
  }, [user, message]);

  return (
    <div className="chat-action-bar">
      <OverlayTrigger overlay={<Tooltip>{tooltipMessage}</Tooltip>}>
        <img
          src={undo_icon}
          alt="Revert before the focused message."
          onClick={() => {
            magic({
              event: OsEventType.Revert,
              key: itemKey,
            }).then((response) => {
              setChat(response?.["chat"] ?? []);
              setSnapshots(response?.["snapshots"] ?? []);
              setData(response?.["state"] ?? []);
            });
          }}
        />
      </OverlayTrigger>
    </div>
  );
};

export const getMessageComponent = (message, key, isLast) => {
  const isActionBarVisible = message.direction === "incoming";
  switch (message.type) {
    case "html": {
      return [
        <Message model={message}>
          <Message.HtmlContent html={message.html} />
          {isActionBarVisible && <ActionBar itemKey={key} message={message} />}
        </Message>,
      ];
    }
    default: {
      return [
        message.timeBreak && (
          <MessageSeparator
            key={message.createdAt}
            className="time-break"
            as="div"
            content={getDateNowFormatted(message.createdAt)}
          />
        ),
        <Message
          key={key}
          model={message}
          className={classNames({ last: isLast })}
        >
          <Message.CustomContent
            className={classNames("custom-message", message.direction)}
          >
            <div className="avatar">
              <div
                className="avatar-persona"
                style={{
                  backgroundColor: message.color,
                  boxShadow: `0 0 1px 1px ${message.color}`,
                  WebkitBoxShadow: `0 0 1px 1px ${message.color}`,
                }}
              >
                {message.abbr}
              </div>
              <span>{message.sender}</span>
            </div>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="left"
              delay={300}
              overlay={
                <Tooltip show={!!message.createdAt}>
                  {getDateNowFormatted(message.createdAt)}
                </Tooltip>
              }
            >
              <div
                className="message-content"
                dangerouslySetInnerHTML={{
                  __html: marked.parse(message.message),
                }}
              />
            </OverlayTrigger>
            {isActionBarVisible && (
              <ActionBar itemKey={key} message={message} />
            )}
          </Message.CustomContent>
        </Message>,
      ];
    }
  }
};

const parseMessage = (message) => {
  // TODO: Messages can have injected actions.
  const baseMessage = {
    id: message.id,
    message: message.output.raw,
    sender: message.sender,
    createdAt: message.created_at,
    timeBreak: false,
    direction: message.direction,
    type: message.type,
    html: message?.html,
    abbr: message?.abbr,
    color: message?.color,
  };

  return baseMessage;
};

const Chat = ({ chat, actions, isLoading }) => {
  const messages = useMemo(() => {
    const messages = chat.map((message) => parseMessage(message));

    let prevTime = new Date(-8640000000000000).getTime();
    messages.forEach((message) => {
      const currTime = parseDate(message.createdAt)?.getTime();
      if (currTime == null) return;

      if (Math.abs(currTime - prevTime) > MAX_TIME_GAP_MIN * 60 * 1000) {
        message.timeBreak = true;
      }

      prevTime = currTime;
    });

    return messages;
  }, [chat]);

  const indicator = isLoading && (
    <TypingIndicator content="Cindi is working on it / typing" />
  );

  return (
    <div className="chat-container">
      <MainContainer>
        <ChatContainer>
          <MessageList typingIndicator={indicator}>
            {messages.map((message, index) =>
              getMessageComponent(message, index, messages.length - 1 === index)
                .filter((x) => x)
                .flat()
            )}
          </MessageList>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export const ChatApp = ({
  isFloating = false,
  isHidden = false,
  isHeadless = false,
  isMultiStep = false,
  isUpload = false,
  syncClassName = null,
  onFilesUploadCallback,
}) => {
  const { chat, setChat, setData, setSettings, setSnapshots, setUser } =
    useContext(AppStore);
  const [isSearchDisabled, setSearchIsDisabled] = useState(false);
  const [isChatShown, setIsChatShown] = useState(false);

  const appendMessage = useCallback(
    (event, message) => {
      setChat((messages) => [...messages, message]);
    },
    [chat]
  );

  useEffect(() => {
    if (!IS_PROMPT_STATE_ENABLED) return;
    if (isUpload) return;

    magic({
      event: OsEventType.State,
    }).then((response) => {
      console.log("Prompt Response: ", response);

      const user = response?.["user"] ?? null;
      if (user != null) {
        setUser(user);
      }

      setSettings(response?.["settings"] ?? null);
      setChat(response?.["chat"] ?? []);
      setSnapshots(response?.["snapshots"] ?? []);
      setData(response?.["state"] ?? []);
    });
  }, []);

  if (isHidden) return null;

  const RootView = ({ children }) =>
    isFloating ? (
      <Dropdown
        className="chat-dropdown"
        drop="up"
        align="end"
        show={isChatShown}
        onToggle={(x) => {
          setIsChatShown(x);
        }}
      >
        <Dropdown.Toggle as="div">
          <div
            className={classNames("chat-floating-btn", { active: isChatShown })}
          >
            <img src={magic_icon} alt="Magic Icon" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    ) : (
      <>{children}</>
    );

  return (
    <RootView>
      <div className="chat-app">
        {!isHeadless && (
          <>
            {/*<Settings />*/}
            {!isFloating && (
              <div className="chat-actions">
                <div
                  className="chat-reset"
                  onClick={() => {
                    magic({
                      event: OsEventType.Clean,
                    }).then((response) => {
                      console.log("Prompt Response: ", response);

                      setSettings(response?.["settings"] ?? null);
                      setChat(response?.["chat"] ?? []);
                      setSnapshots(response?.["snapshots"] ?? []);
                      setData(response?.["state"] ?? []);
                    });
                  }}
                >
                  <img src={delete_bin} alt="Restart" />
                  <span>Reset</span>
                </div>
                <p className="chat-history small">Load previous chat history</p>
              </div>
            )}
            <div className="chat-body">
              <Chat chat={chat} isLoading={isSearchDisabled} />
            </div>
          </>
        )}
        <Search
          className={classNames("mt-4", { emphasis: isHeadless })}
          syncClassName={classNames(
            isMultiStep ? "multi-step" : "floating",
            syncClassName
          )}
          appendMessage={appendMessage}
          isDisabled={isSearchDisabled}
          setIsDisabled={setSearchIsDisabled}
          isHeadless={isHeadless}
          onFilesUploadCallback={onFilesUploadCallback}
        />
        {!isHeadless && (
          <span className="chat-info-disclaimer">
            AI can make mistakes. Check important info with your doctor.
          </span>
        )}
      </div>
    </RootView>
  );
};

export default ChatApp;
