export const Data = [
  {
    id: 1,
    day: "Sat 21th",
    leisure: 46.78,
    work: 56.89,
  },
  {
    id: 2,
    day: "Sun 22th",
    leisure: 23.49,
    work: 91.24,
  },
  {
    id: 3,
    day: "Mon 23th",
    leisure: 23.42,
    work: 87.22,
  },
  {
    id: 4,
    day: "Tue 24th",
    leisure: 58.84,
    work: 173.61,
  },
  {
    id: 5,
    day: "Wed 25th",
    leisure: 36.78,
    work: 46.89,
  },
];
