import React, { useContext, useEffect, useMemo, useState } from "react";

import { marked } from "marked";
import { countBy } from "lodash";
import { AppStore } from "../../../Store";
import { FileReportType, ViewType } from "../../../../interfaces";

export const MarkerStatus = ({ value }) => {
  if (!value) return null;
  value = value.toLowerCase();

  let innerComponent = <span>{value}</span>;
  if (value.includes("slightly")) {
    innerComponent = (
      <>
        <i className="bi bi-activity warning" /> Slight
      </>
    );
  } else if (value.includes("elevated")) {
    innerComponent = (
      <>
        <i className="bi bi-activity warning" /> Elevated
      </>
    );
  } else if (value.includes("critical")) {
    innerComponent = (
      <>
        <i className="bi bi-x-circle-fill critical" /> Bad
      </>
    );
  } else if (value.includes("low")) {
    innerComponent = (
      <>
        <i className="bi bi-activity warning" /> Low
      </>
    );
  } else if (value.includes("unknown")) {
    innerComponent = <>N/A</>;
  } else if (value.includes("normal")) {
    innerComponent = (
      <>
        <i className="bi bi-check-circle-fill normal" /> Normal
      </>
    );
  }

  return <span className="badge-marker">{innerComponent}</span>;
};

const parseTable = (element) => {
  const rowsData = [];
  const columns = [];

  // Get all rows of the table
  const rows = element.getElementsByTagName("tr");

  // Initialize columns arrays based on the number of cells in the first row
  const firstRowCells =
    rows[0].getElementsByTagName("th").length > 0
      ? rows[0].getElementsByTagName("th")
      : rows[0].getElementsByTagName("td");

  for (let j = 0; j < firstRowCells.length; j++) {
    columns.push(firstRowCells[j].textContent.trim());
  }

  for (let i = 1; i < rows.length; i++) {
    const row = rows[i];
    const cells =
      row.getElementsByTagName("th").length > 0
        ? row.getElementsByTagName("th")
        : row.getElementsByTagName("td");

    const rowData = [];
    for (let j = 0; j < cells.length; j++) {
      const cellText = cells[j].textContent.trim();
      rowData.push(cellText);
    }

    rowsData.push(rowData);
  }

  const otherColumns = columns.map((column) => {
    let cell;
    if (column === "Notes/Interpretation") {
      cell = ({ getValue, row: { original: entity } }) => {
        let value = getValue();
        return <MarkerStatus value={value} />;
      };
    } else {
      cell = (info) => <span>{info.getValue()}</span>;
    }

    return {
      accessorKey: column,
      cell,
      header: () => <span>{column}</span>,
    };
  });

  const otherRows = rowsData.map((row) => {
    return Object.fromEntries(row.map((cell, idx) => [columns[idx], cell]));
  });

  return {
    rows: rowsData,
    columns,
    otherColumns,
    otherRows,
  };
};

const colorBar = [
  "#e60049",
  "#0bb4ff",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

export const getChartData = ({
  filterBy,
  reportTable,
  accentEnabled = false,
}) => {
  const emptyChart = null;
  if (!reportTable || !reportTable.columns) return emptyChart;

  try {
    const colParamIdx = reportTable.columns.indexOf("Parameter");
    const colDateIdx = reportTable.columns.indexOf(
      reportTable.columns.find((x) => x.toLowerCase().includes("date"))
    );
    const colRefIdx = reportTable.columns.indexOf("Reference Range");
    const colValueIdx = reportTable.columns.indexOf("Result");
    const colUnitIdx = reportTable.columns.indexOf("Unit");

    const data = reportTable.rows
      .filter(
        (row) =>
          filterBy.some((x) => row[colParamIdx] === x) &&
          !row[colUnitIdx].includes("%")
      )
      .sort(
        (row1, row2) => new Date(row1[colDateIdx]) - new Date(row2[colDateIdx])
      );

    if (data.length === 0) return emptyChart;

    const numParser = (num) => {
      try {
        return parseFloat(num.match(/([0-9.]+)/)[1]);
      } catch (e) {
        return null;
      }
    };

    let options = {};
    const ref = data[0][colRefIdx].replace("-", "—").replace("–", "—");
    if (ref.includes("—")) {
      const tokens = ref.split("—");

      const maxV = data.reduce(
        (acc, x) => Math.max(numParser(x[colValueIdx]), acc),
        Number.MIN_SAFE_INTEGER
      );

      const minV = data.reduce(
        (acc, x) => Math.min(numParser(x[colValueIdx]), acc),
        Number.MAX_SAFE_INTEGER
      );

      const minY = parseFloat(tokens[0]);
      const maxY = parseFloat(tokens[1]);
      options = {
        minY,
        maxY,
        mminY: Math.min(minY, minV),
        mmaxY: Math.max(maxY, maxV),
      };
    }

    if (!ref) return emptyChart;

    const groups = Object.groupBy(data, (x) => x[colParamIdx]);
    const entries = Object.entries(groups);
    if (entries.length === 0) return emptyChart;

    const datasets = entries.map(([key, grp], grpIdx) => {
      const color = colorBar[grpIdx];
      const data = grp.map((row) => ({
        x: row[colDateIdx],
        y: numParser(row[colValueIdx]),
      }));

      return {
        label: key,
        data,
        borderColor: function () {
          if (accentEnabled) return "transparent";

          return color;
        },
        backgroundColor: function (context) {
          const index = context.dataIndex;
          if (accentEnabled)
            return index === data.length - 1
              ? "#FF584A"
              : "rgba(67, 58, 57, 0.6)";

          return color;
        },
        pointRadius: 4,
        borderWidth: 2,
      };
    });

    return {
      data: { datasets, options },
      options,
    };
  } catch (err) {
    console.log("Graph Exception: ", err);
    return emptyChart;
  }
};

export const getReportData = (dataStr) => {
  const data = marked.parse(dataStr);
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");

  const element = doc.querySelector("table");
  const parsedTable = parseTable(element);
  parsedTable.rows = parsedTable.rows.sort(
    (a, b) => new Date(b[1]) - new Date(a[1])
  );

  return parsedTable;
};

export const parseMarkdownToTable = (value) => {
  const data = marked.parse(value);
  const parser = new DOMParser();
  return parser.parseFromString(data, "text/html");
};

export const useReportData = ({
  model,
  localFilesByType,
  isFilterEnabled = true,
  isGrouped = false,
}) => {
  const { local_files: localFiles } = model;
  const { filters } = useContext(AppStore);

  const [reportId, setReportId] = useState(null);
  const [selectedParameters, setSelectedParameters] = useState([]);

  const reports = useMemo(() => {
    if (!localFiles) return null;
    return localFiles
      .filter(({ response }) => response)
      .filter(({ type }) => type === ViewType.Report)
      .sort((a, b) => b["id"] - a["id"]);
  }, [localFiles]);

  const files = useMemo(() => {
    if (!localFiles) return null;
    return localFiles
      .filter(({ type }) => type === ViewType.File)
      .sort((a, b) => b["id"] - a["id"]);
  }, [localFiles]);

  useEffect(() => {
    if (reportId === null && reports && reports.length > 0)
      setReportId(reports[0]["id"]);
  }, [reports, reportId, setReportId]);

  const [report, reportTable] = useMemo(() => {
    const defaultValue = [null, null];
    if (!files) return defaultValue;

    const reports = files
      .map((file) => {
        const markers = file.other.filter(
          (item) => item.type === FileReportType.Markers
        );

        if (markers.length === 0) return null;

        // Filter the earliest report.
        return markers.reduce((acc, cur) => {
          return Date.parse(acc) > Date.parse(cur) ? cur : acc;
        });
      })
      .filter((x) => x);

    const tables = reports
      .map((report) => {
        try {
          const doc = parseMarkdownToTable(report.response);
          const element = doc.querySelector("table");
          return parseTable(element);
        } catch (e) {
          console.log("Failed to parse the report: ", e);
        }

        return null;
      })
      .filter((x) => x);

    if (tables.length === 0) return defaultValue;

    // TODO: Handle malformed tables (i.e., columns).
    const table = tables.reduce(
      (acc, curr) => {
        acc.rows.push(curr.rows);
        acc.otherRows.push(curr.otherRows);

        return acc;
      },
      {
        rows: [],
        otherRows: [],
      }
    );

    return [
      null,
      {
        ...tables[0],
        rows: table.rows.flat().sort((a, b) => new Date(b[1]) - new Date(a[1])),
        otherRows: table.otherRows.flat(),
      },
    ];
  }, [files]);

  // const [report, reportTable] = useMemo(() => {
  //   const defaultValue = [null, null];
  //   if (!reports) return defaultValue;
  //   if (reportId == null) return defaultValue;
  //
  //   const report = reports.find(({ id }) => id === reportId);
  //   if (!report) return defaultValue;
  //
  //   const doc = parseMarkdownToTable(report.response);
  //   const element = doc.querySelector("table");
  //   const parsedTable = parseTable(element);
  //   parsedTable.rows = parsedTable.rows.sort(
  //     (a, b) => new Date(b[1]) - new Date(a[1])
  //   );
  //
  //   console.log(report.response, data);
  //   // TODO: ID, Date, Test Type can be optimized even more, by using helper table.
  //   // TODO: Probably best is to parse each document once more, and have the lab results per document, and then aggregated.
  //
  //   return [report, parsedTable];
  // }, [reports, reportId]);

  const chart = useMemo(() => {
    const filterBy = selectedParameters.map(({ value }) => value);

    return getChartData({
      filterBy,
      reportTable,
    });
  }, [reportTable, selectedParameters]);

  const parameters = useMemo(() => {
    if (!reportTable || !reportTable.columns) return [];

    const colParamIdx = reportTable.columns.indexOf("Parameter");
    let parameters = reportTable.rows.map((row) => row[colParamIdx]);

    const groups = Object.groupBy(reportTable.rows, (x) => x[colParamIdx]);
    const reports = Object.fromEntries(
      Object.entries(groups).map(([key, reports]) => [
        key,
        reports.map((report) =>
          Object.fromEntries(
            report.map((value, idx) => [reportTable.columns[idx], value])
          )
        ),
      ])
    );

    if (isFilterEnabled) {
      if (filters.markerSearchFilter && filters.markerSearchFilter.length > 0) {
        parameters = parameters.filter((label) =>
          label.toLowerCase().includes(filters.markerSearchFilter.toLowerCase())
        );
      }

      if (filters.showAbnormalOnly != null && filters.showAbnormalOnly) {
        parameters = parameters.filter((label) => {
          const labelReports = reports[label];
          if (labelReports && labelReports[0]) {
            return (
              labelReports[0]["Notes/Interpretation"].toLowerCase() !== "normal"
            );
          }

          return true;
        });
      }
    }

    return Object.entries(countBy(parameters)).map(([label, count]) => {
      if (isGrouped) {
        const chart = getChartData({
          filterBy: [label],
          reportTable,
          accentEnabled: true,
        });

        const labelReports = reports[label];

        // Add document reference to the table.
        return {
          chart,
          value: label,
          count: count,
          report: report,
          reports: labelReports.map((report) => ({
            ...report,
            File: (localFilesByType?.file ?? [])[report["ID"]],
          })),
        };
      }

      return {
        value: label,
        label: `${label} - ${count}`,
      };
    });
  }, [
    isFilterEnabled,
    localFilesByType,
    report,
    filters,
    reportTable,
    isGrouped,
  ]);

  return {
    parameters,
    selectedParameters,
    setSelectedParameters,
    overview: report,
    reportTable,
    chart,
    reports,
    reportId,
    setReportId,
  };
};

export default useReportData;
