import React, { useContext } from "react";
import { AppStore } from "../../../Store";
import Modal from "react-bootstrap/Modal";
import { marked } from "marked";
import classNames from "classnames";
import { DocumentLink } from "../artifacts/ArtifactsView";
import Spinner from "react-bootstrap/Spinner";
import { getResourceDataUrl } from "../../../utils";
import { ResourceDataType } from "../../../../interfaces";

// Icons
import calendar_icon from "../../../assets/filter/calendar_icon.svg";

import "./style.sass";

export const MetadataView = ({
  className,
  document,
  includeDocumentLink = false,
}) => {
  const { setActiveSnapshot } = useContext(AppStore);

  if (document == null || document?.metadata == null) return;

  return (
    <div className={classNames("account-cat-options", className)}>
      {Object.entries(document?.metadata).map(([metaKey, metaValue]) => (
        <div key={metaKey} className="account-cat-option">
          <span className="account-cat-option__title">{metaKey}</span>
          <span className="account-cat-option__value">
            {metaKey === "date" && metaValue && (
              <img
                className="account-cat-option__icon"
                src={calendar_icon}
                alt="Date of the test taken"
              />
            )}
            {metaValue ?? "-"}
          </span>
        </div>
      ))}
      {includeDocumentLink && (
        <div key="document" className="account-cat-option">
          <span className="account-cat-option__title">Document</span>
          <DocumentLink
            className="small"
            document={document}
            onClick={(event) => {
              event.stopPropagation();

              setActiveSnapshot(document);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const sourceToHtml = (src) => {
  if (src == null) return null;

  return marked.parse(src);
};

export const SnapshotView = () => {
  const { activeSnapshot: snapshot, setActiveSnapshot } = useContext(AppStore);
  const handleClose = () => {
    setActiveSnapshot(null);
  };

  const parsedContent = sourceToHtml(snapshot?.contents);

  return (
    <>
      <Modal
        show={snapshot !== null}
        onHide={handleClose}
        dialogClassName="snapshot-view"
        backdrop={true}
      >
        {snapshot?.domain ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{snapshot && snapshot.domain}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {snapshot && (
                <>
                  <div className="d-flex flex-row gap-4">
                    <div className="flex-container d-flex">
                      <img
                        className="entity__snapshot__preview"
                        src={getResourceDataUrl(
                          snapshot.id,
                          ResourceDataType.Scraped
                        )}
                        alt={snapshot.id}
                      />
                    </div>

                    <div className="flex-container d-flex flex-column">
                      <h3>
                        <b>Summary</b>
                      </h3>
                      {snapshot.id}
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{snapshot && snapshot.title}</Modal.Title>
              <span className="modal-subtitle">
                {snapshot && new Date(snapshot.file_upload_at).toDateString()}
              </span>
            </Modal.Header>
            <Modal.Body>
              {snapshot && (
                <>
                  <div className="d-flex flex-row gap-4 h-100">
                    <div className="snapshot-preview-container flex-container d-flex">
                      {snapshot.ext.includes("pdf") ? (
                        <iframe
                          className="entity__snapshot__preview auto-scale"
                          src={getResourceDataUrl(
                            snapshot.file_name,
                            ResourceDataType.User
                          )}
                        />
                      ) : (
                        <img
                          className="entity__snapshot__preview"
                          src={getResourceDataUrl(
                            snapshot.file_name,
                            ResourceDataType.User
                          )}
                          alt={snapshot.title}
                        />
                      )}
                    </div>

                    <div className="flex-container d-flex flex-column">
                      <div className="snapshot-view__container">
                        <p className="snapshot-view__header">Overview</p>
                        <div className="snapshot-view__contents">
                          {snapshot?.metadata == null ? (
                            <div className="d-inline-flex">
                              <Spinner text="In-progress" size="sm" />
                              <span className="ms-2">Loading</span>
                            </div>
                          ) : (
                            <MetadataView
                              document={snapshot}
                              includeDocumentLink={false}
                            />
                          )}
                        </div>
                      </div>
                      <div className="snapshot-view__container fit">
                        <p className="snapshot-view__header">Parsed Document</p>
                        {parsedContent == null ? (
                          <div className="d-inline-flex">
                            <Spinner text="In-progress" size="sm" />
                            <span className="ms-2">Loading</span>
                          </div>
                        ) : (
                          <div
                            className="snapshot-view__contents"
                            dangerouslySetInnerHTML={{
                              __html: parsedContent,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default SnapshotView;
