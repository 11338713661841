import React, { useState, useMemo, useContext } from "react";
import classNames from "classnames";
import { Link } from "@tanstack/react-router";
import { AppStore } from "../../Store";
import { HeaderBrand } from "../header";
import { Promotion } from "../promotion/promotion";
import { PlanView } from "../plan/plan";
import { Persona } from "../persona/persona";
import useReportData from "../views/report/useReportData";
import { AppRoute, ViewType } from "../../../interfaces";
import { testAPI } from "../../../api/query";
import { SHOW_TEST_BTN } from "../settings";
import Action from "../action/action";

// Icons
import double_arrow_left_icon from "../../assets/double_arrow_left_icon.svg";
import person_icon from "../../assets/menu/person_icon.svg";
import sentiment_icon from "../../assets/sentiment.svg";
import settings_icon from "../../assets/settings.svg";
import bio_icon from "../../assets/menu/bio_icon.svg";
import home_icon from "../../assets/menu/home_icon.svg";
import integration_icon from "../../assets/menu/integration_icon.svg";
import lab_research_icon from "../../assets/menu/lab_research_icon.svg";
import arrow_right_icon from "../../assets/arrow_right.svg";

// Import styles
import "./style.sass";

export const getMenuItems = ({ labReportCount, markerCount }) => [
  {
    title: "Dashboard",
    isShown: true,
    icon: home_icon,
    to: AppRoute.Overview,
  },
  {
    title: "Overview",
    isShown: false,
    icon: person_icon,
    to: AppRoute.Overview,
  },
  {
    title: "Lab tests & Reports",
    icon: lab_research_icon,
    isShown: true,
    render: () => (
      <>
        Lab tests & Reports{" "}
        <span className="item-count">({labReportCount})</span>
        <img
          className="item-right"
          src={arrow_right_icon}
          alt="Click to expand"
        />
      </>
    ),
    to: AppRoute.Artifacts,
  },
  {
    title: "Biomarkers",
    render: () => (
      <>
        Biomarkers <span className="item-count">({markerCount})</span>
        <img
          className="item-right"
          src={arrow_right_icon}
          alt="Click to expand"
        />
      </>
    ),
    isShown: true,
    icon: bio_icon,
    to: AppRoute.Reports,
  },
  {
    title: "Integrations",
    icon: integration_icon,
    isShown: false,
    to: AppRoute.Integrations,
  },
  {
    title: "Settings",
    icon: settings_icon,
    isShown: false,
    className: "bottom highlight",
    to: "#",
  },
  {
    title: "Support",
    icon: sentiment_icon,
    isShown: false,
    className: "highlight",
    to: AppRoute.Support,
  },
];

export const Menu = () => {
  const { user, data } = useContext(AppStore);

  const [componentIndex, setComponentIndex] = useState(0);
  const [menuIsCollapsed, setMenuIsCollapsed] = useState(true);

  const { parameters } = useReportData({
    model: data,
    isFilterEnabled: false
  });

  const menuItems = useMemo(() => {
    const { local_files: localFiles } = data;

    const labReportCount = (localFiles ?? []).filter(
      ({ type }) => type === ViewType.File
    ).length;
    const markerCount = parameters.length;

    return getMenuItems({
      labReportCount,
      markerCount,
    });
  }, [data, parameters]);

  return (
    <div className={classNames("menu", { collapse: false })}>
      <div className="menu-container">
        <div
          className="menu-collapse"
          onClick={() => {
            setMenuIsCollapsed(true);
          }}
        >
          <img src={double_arrow_left_icon} alt="Menu Collapse" />
        </div>
        <HeaderBrand className="fill" hasLinks={false} />
        <div className="menu-components">
          {menuItems.map(
            (item, index) =>
              item.isShown && (
                <Link
                  key={item.title}
                  to={item.to}
                  className={classNames("component-item", item?.className, {
                    active: index === componentIndex,
                  })}
                  onClick={() => setComponentIndex(index)}
                >
                  <div className="component-item__container">
                    <img
                      className="item-asset"
                      src={item.icon}
                      alt={item.title}
                    />
                    <div className="item-name">
                      {item.render ? item.render() : item.title}
                    </div>
                  </div>
                </Link>
              )
          )}
        </div>
      </div>
      {user && user.role === "admin" && SHOW_TEST_BTN && (
        <Action
          className="outline mx-auto"
          size="sm"
          onClick={() => {
            testAPI().finally(() => {
              console.log("Test completed.");
            });
          }}
          text="Admin Only (Don't Click)"
        />
      )}
      <PlanView />
      {!user && <Promotion />}
      <Persona minimized={false} />
    </div>
  );
};

export default Menu;
