import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";

import { Tree } from "react-arborist";
import FileNode from "./fileNode";
import { AppFileDir, Messages, WatchActionType } from "../../../types";
import { useDynamicTree } from "./tree";

// Assets
import desktop_icon from "../../assets/tree/desktop.svg";
import home_icon from "../../assets/tree/home.svg";
import root_icon from "../../assets/tree/root.svg";

// Styles
import "./style.sass";

const FileTree = () => {
  const [term, setTerm] = useState("");
  const [data, controller] = useDynamicTree([]);
  const [fileTreeId, setFileTreeId] = useState(null);
  const treeRef = useRef(null);
  console.log(data, fileTreeId);

  const onRename = ({ id, name }) => {
    const node = treeRef.current.get(id);
    if (node) {
      node.data.name = name;
    }
  };

  useEffect(() => {
    treeRef.current.handle = (node) => {
      const { id } = node.data;
      let action = WatchActionType.ExcludeFile;
      if (!node.data.isChecked) {
        action = WatchActionType.IncludeFile;
      }

      console.log("On Toggle: ", action);
      window.ipcRenderer.send(Messages.ListFileDir, {
        id,
        dirPath: id,
        type: AppFileDir.Watch,
        action,
      });
    };
  }, [treeRef]);

  const onDelete = ({ ids }) => {
    console.log("onDelete");
  };
  const onCreate = ({ parentId, index, type }) => {
    console.log("onCreate");
  };
  const onMove = ({ dragIds, parentId, index }) => {
    console.log("onMove");
  };

  useEffect(() => {
    if (!window.ipcRenderer) return;

    window.ipcRenderer.send(Messages.ListFileDir, { type: AppFileDir.Home });
  }, []);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (_, data) => {
      console.log("Incoming data: ", data);
      if (data.type === AppFileDir.Any) {
        controller.onCreate({ parentId: data.id, children: data.tree });
      } else {
        controller.setData(data.tree);
        setFileTreeId(data.id);
      }
    };

    window.ipcRenderer.on(Messages.ListFileDir, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.ListFileDir, listener);
    };
  }, [setFileTreeId, controller]);

  const onChangeLocation = (type) => {
    window.ipcRenderer.send(Messages.ListFileDir, { type });
  };

  return (
    <div className="file-tree">
      <div className="file-tree-header">
        <img
          className="file-tree-icon"
          src={root_icon}
          onClick={() => onChangeLocation(AppFileDir.Root)}
          alt="Root Folder"
          title="Root Directory"
        />
        <img
          className="file-tree-icon"
          src={home_icon}
          onClick={() => onChangeLocation(AppFileDir.Home)}
          alt="Home Folder"
          title="Home Directory"
        />
        <img
          className="file-tree-icon"
          src={desktop_icon}
          onClick={() => onChangeLocation(AppFileDir.Desktop)}
          alt="Desktop Directory"
          title="Desktop Directory"
        />
      </div>
      <input
        type="text"
        placeholder="Search..."
        className="file-tree-input"
        value={fileTreeId}
        onChange={(e) => setTerm(e.target.value)}
      />
      <Tree
        className="file-tree-view"
        data={data}
        ref={treeRef}
        width="100%"
        padding={12}
        indent={16}
        rowHeight={20}
        openByDefault={false}
        selection={fileTreeId}
        onToggle={(id) => {
          const node = treeRef.current.get(id);
          if (
            node &&
            node.data.isDirectory &&
            node.data.children.length === 0
          ) {
            window.ipcRenderer.send(Messages.ListFileDir, {
              type: AppFileDir.Any,
              dirPath: id,
            });
          }
        }}
        onSelect={(nodes) => {
          if (nodes.length === 1) {
            setFileTreeId(nodes[0].id);
          }
        }}
        searchTerm={term}
        searchMatch={(node, term) =>
          node.data.name.toLowerCase().includes(term.toLowerCase())
        }
        onRename={onRename}
        onDelete={onDelete}
        onCreate={onCreate}
        onMove={onMove}
      >
        {FileNode}
      </Tree>
      <div className="file-tree-footnote">
        <span className="muted">
          Check the specific directories to be synced and indexed for search.
        </span>
      </div>
      <div className="file-tree-footer">
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            window.ipcRenderer.send(Messages.SaveBrowseSystem);
          }}
        >
          Save
        </Button>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            window.ipcRenderer.send(Messages.CancelBrowseSystem);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default FileTree;
