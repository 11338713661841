export const IS_PROMPT_STATE_ENABLED = true;

export const allowedFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
];

export const maxTotalFileSize = 15 * 1024 * 1024; // 10MB

export const IsColumnIdentifierShown = false;

// This is for native Desktop App support.
export const IS_HASH_HISTORY_ENABLED = false;
