export enum OperatingSystem {
  ANY = "ANY",
  LINUX = "Linux",
  WINDOWS = "Windows",
  MAC = "Mac",
  NOT_SUPPORTED = "NOT_SUPPORTED",
}

// TODO: It's very hard to have a clear decision boundary (e.g., Linux <-> Windows), to be updated...
export enum FileType {
  Regular = "Regular",
  Directory = "Directory",
  Executable = "Executable",
  Shortcut = "Shortcut",
  System = "System",
  Device = "Device",
  Link = "Link",
}

export enum SpecialFileType {
  Text = "text",
  Image = "image",
  Video = "video",
  Archive = "archive",
  Font = "font",
  None = "none",
}

export interface ISystemFile {
  // Files can be transferred from one operating system to another.
  systemType: OperatingSystem;
  fileType: FileType;
}

export interface IMetadataRaw {
  uid: number | string;
  gid: number | string;
  uuid?: number | string;
  guid?: number | string;
  created: number;
  modified: number;
  accessTime: number;
  birthTime: number;

  // File size.
  size: string;
}

export type ParseEntity = string | Buffer;

export enum MessageStatus {
  Pending = -1,
  Error = 0,
  Success = 1,
}

export interface IBaseMessage {
  id: string;
  status: MessageStatus;
  error?: string | null;
}

export interface Workspace {
  name: string;
  asset: string;
  path: string;
  description: string;
}

export interface ISyncMessage extends IBaseMessage {
  workspaces?: Workspace[];
}

export enum IJobPriority {
  Default = 0,
  Block = 1,
}

export interface IJobMessage extends IBaseMessage {
  title: string;
  priority?: IJobPriority;
}

export interface IAppConfig {
  watchFiles: string[];
  excludedFiles: string[];
  sizeDir?: number | string;
}

export interface IConfigMessage extends IBaseMessage {
  config: IAppConfig;
}

export interface IProgressMessage extends IBaseMessage {
  index: number;
  total: number;
  message: string;
}

export enum Intent {
  Magic = 0,
}

export interface IIntentMessage extends Partial<IBaseMessage> {
  type: Intent;
  createdAt: Date;
}

export type WinMessages =
  | ISyncMessage
  | IJobMessage
  | IConfigMessage
  | IProgressMessage
  | IIntentMessage;

export enum IPublisherMessage {
  Result,
  Progress,
}

export type IPublisher = (type: IPublisherMessage, message: string) => void;

export enum AppControlKeys {
  CreateView,
  Kanban,
  Database,
  Report,
  Artifacts,
  Account,
}

// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/explicit-module-boundary-types
export const enumValues = <T extends object>(e: T) => {
  const values = Object.values(e);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isNumEnum = e[e[values[0]]] === values[0];
  return isNumEnum ? values.slice(values.length / 2) : values;
};

export enum ViewType {
  File = "file",
  Report = "report",
  Knowledge = "knowledge_base",
}

export enum ResourceDataType {
  Scraped = 0,
  User = 1,
}

export enum AuthorizeMode {
  SignIn = 0,
  SignUp = 1,
  SignUpTwoStep = 2,
  ResetPassword = 3,
  ResetPasswordTwoStep = 4,
  ResetPasswordRedirect = 5,
  SignUpTwoRedirect = 6,
}

export enum AppRoute {
  Main = "/",
  Welcome = "/welcome",
  Config = "/config",
  Browse = "/browse",
  Memory = "/memory",
  Authorize = "/authorize",
  Account = "/#account",
  Overview = "/#overview",
  Artifacts = "/#artifacts",
  Reports = "/#reports",
  Integrations = "/#integrations",
  Support = "/support",
}

export enum ArtifactViewMode {
  Diagnosis = "diagnosis",
  Biomarkers = "biomarkers",
  Recommendations = "recommendations",
}

export enum FileReportType {
  Diagnosis = 0,
  Recommendations = 1,
  Markers = 2,
}

export enum SocketMessageType {
  TaskProgress = "task_progress",
}
