import React, { useState, useEffect, useContext, useMemo } from "react";
import { Persona } from "../../persona/persona";
import Action from "../../action/action";
import useModal from "../../modal/modal";
import { logout, deleteAccount } from "../../../../api/auth";
import { AppStore } from "../../../Store";

// Icons
import verified_icon from "../../../assets/verified-fill.svg";

import "./style.sass";
import { AuthorizeMode } from "../../../../interfaces";
import { getAuthorizeUrl } from "../../../utils";

export const getAccountOptions = ({
  user,
  userPlan,
  setShow,
  setModalOptions,
  updateModalOptions,
}) => {
  const planType = userPlan?.plan?.type;
  return [
    {
      category: "User Details",
      items: [
        {
          title: "Username",
          value: user?.firstName ?? "-",
        },
        {
          title: "E-mail",
          value: user?.email,
        },
      ],
    },
    {
      category: "Account Information",
      items: [
        {
          title: "Subscription",
          render: (key) => (
            <span key={key} className="account-cat-option__value account-plan">
              {planType || "Premium"} Plan
              {planType !== "free" && (
                <img
                  className="persona-detail__account-type"
                  src={verified_icon}
                  alt="Premium Account"
                />
              )}
            </span>
          ),
        },
        {
          title: "Export all my data from my account (Coming Soon)",
          render: (key) => (
            <Action
              key={key}
              disabled={true}
              btnClassName="outline"
              text="Export Data"
            />
          ),
        },
        {
          title: "Delete all data & close the account",
          render: (key) => (
            <Action
              key={key}
              btnClassName="outline warning"
              text="Delete Account"
              onClick={() => {
                setShow(true);
                setModalOptions({
                  header: "Are you sure to delete your account?",
                  subheader: (
                    <>
                      Deleting your account is permanent and cannot be undone.
                      <ul>
                        <li>All your data will be deleted imminently</li>
                        <li>
                          All your active subscription plans will be canceled on
                          short notice
                        </li>
                      </ul>
                      <b>
                        Thank you for trusting us and giving our product a
                        chance during the trial!
                      </b>
                    </>
                  ),
                  primaryActionText: "Confirm",
                  primaryActionCallback: () => {
                    deleteAccount()
                      .then(() => {
                        window.location = getAuthorizeUrl(AuthorizeMode.SignUp);
                      })
                      .catch((err) => {
                        updateModalOptions({
                          error: err.toString(),
                        });
                      });
                  },
                });
              }}
            />
          ),
        },
        {
          title: "Log out on this device",
          render: (key) => (
            <Action
              key={key}
              btnClassName="outline"
              text="Log out"
              onClick={() => {
                logout().then(() => {
                  window.location = getAuthorizeUrl();
                });
              }}
            />
          ),
        },
      ],
    },
  ];
};

const AccountView = ({ model, setModel }) => {
  const { user } = useContext(AppStore);
  const { setShow, modal, setModalOptions, updateModalOptions } = useModal();

  const userPlan = useMemo(() => {
    if (user == null) return null;

    return user.plans.find((plan) => plan.end_date == null);
  }, []);

  const accountOptions = useMemo(() => {
    return getAccountOptions({
      user,
      userPlan,
      setShow,
      setModalOptions,
      updateModalOptions,
    });
  }, [user, userPlan, setShow, setModalOptions, updateModalOptions]);

  return (
    <div className="account-container">
      {modal}
      <Persona
        minimized={true}
        onClickCallback={() => {
          // Do nothing for now.
          // TODO: User profile icon change-up.
        }}
      />
      {accountOptions.map((accountOption) => (
        <div
          key={accountOption.category}
          className="account-category-container"
        >
          <p className="account-category">{accountOption.category}</p>
          <div className="account-cat-options">
            {accountOption.items.map((item, index) => (
              <React.Fragment key={item.title}>
                <span
                  key={`${item.title}-title`}
                  className="account-cat-option__title"
                >
                  {item.title}
                </span>
                {item.render ? (
                  item.render(`${item.title}-value`)
                ) : (
                  <span
                    key={`${item.title}-value`}
                    className="account-cat-option__value"
                  >
                    {item.value}
                  </span>
                )}
                {index < accountOption.items.length - 1 && (
                  <div
                    key={`${item.title}-divider`}
                    className="account-cat-option__divider"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccountView;
