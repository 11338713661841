import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

import download_icon from "../../../assets/download_icon.svg";

export const InfoIcon = () => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
      </symbol>
    </svg>
    <svg
      className="bi flex-shrink-0 me-2"
      width="16"
      height="16"
      role="img"
      aria-label="Info:"
    >
      <use xlinkHref="#info-fill" />
    </svg>
  </>
);

export const DownloadNotification = ({ notification, actions }) => {
  const span = notification.newTitle ? (
    <span>
      , renamed to <span className="highlight">{notification.newTitle}</span>
    </span>
  ) : null;

  return (
    <Card.Body>
      <Card.Title className="d-flex align-items-center">
        <img
          className="event-icon me-2"
          src={download_icon}
          alt="Download Event"
        />
        New File Downloaded
      </Card.Title>
      <Card.Text className="card-text">
        New file downloaded -{" "}
        <span className="highlight">{notification.title}</span>
        {span} and moved to{" "}
        <span className="highlight">{notification.newDir}</span>.
      </Card.Text>

      {notification.description && (
        <Alert className="py-2 px-3" variant="info">
          <InfoIcon />
          <span
            className="notification-description"
            dangerouslySetInnerHTML={{ __html: notification.description }}
          />
        </Alert>
      )}

      <Card.Link href="#" onClick={() => actions.openDir(notification.path)}>
        Open Folder
      </Card.Link>
      <Card.Link href="#" onClick={() => actions.openFile(notification.path)}>
        Preview
      </Card.Link>
      <Card.Link href="#" onClick={() => actions.undo()}>
        Undo
      </Card.Link>
    </Card.Body>
  );
};
