import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import i18next from "i18next";
import XHR from "i18next-xhr-backend";

// import common_ro from "./translations/ro/common.json";
// import faq_ro from "./translations/ro/faq.json";
// import accelerator_ro from "./translations/ro/accelerator.json";
//
// import common_ru from "./translations/ru/common.json";
// import faq_ru from "./translations/ru/faq.json";
// import accelerator_ru from "./translations/ru/accelerator.json";

export const supportedLanguages = {
  en: "English",
  ro: "Română",
  ru: "Pусский",
  de: "Deutsch",
};

// Internationalization
i18next
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocalStorageBackend, XHR],
      backendOptions: [
        {
          projectId: "cindi",
        },
        {
          loadPath: "/translations/{{lng}}/{{ns}}.json",
        },
      ],
    },
    resources: {
      ro: {
        // translation: common_ro,
        // faq: faq_ro,
        // accelerator: accelerator_ro,
      },
      ru: {
        // translation: common_ru,
        // faq: faq_ru,
        // accelerator: accelerator_ru,
      },
    },
    lng: "en", // Default language
    fallbackLng: Object.keys(supportedLanguages), // Fallback language
    supportedLngs: Object.keys(supportedLanguages), // Supported language codes
    objectNotation: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
