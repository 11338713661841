// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: -8px;
}

.settings-dropdown {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 8px;
  border: none;
}

.settings-link {
  cursor: pointer;
  font-size: 14px;
  color: #7D7D7D;
  text-decoration: none;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/settings/style.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,iBAAA;AADF;;AAGA;EACE,qBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AAAF;;AAGA;EACE,eAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;EACA,gBAAA;AAAF","sourcesContent":["@import \"../../constants\"\n\n.settings-container\n  display: flex\n  flex-direction: row\n  align-items: center\n  justify-content: flex-start\n  margin-left: -8px\n\n.settings-dropdown\n  display: inline-block\n  padding: $marginDivider $marginParent\n  border-radius: 8px\n  border: none\n\n\n.settings-link\n  cursor: pointer\n  font-size: 14px\n  color: #7D7D7D\n  text-decoration: none\n  font-weight: 700\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
