import { basicFetch, Endpoints } from "./index";

export interface User {
  username: string;
}

export const getUser = async (redirect = true): Promise<User> => {
  return basicFetch(
    Endpoints.User,
    {
      method: "GET",
    },
    redirect
  );
};

export interface ILoginParams {
  email: string;
  password: string;
  token: string;
  mode: number;
  other?: string;
}

export interface IAuthorizeResponse {
  reset_in?: string;
}

export const authorize = async (
  params: ILoginParams,
  throwReq: boolean
): Promise<IAuthorizeResponse> => {
  return basicFetch(
    Endpoints.Authorize,
    {
      method: "POST",
      body: JSON.stringify(params),
    },
    false,
    throwReq
  );
};

export const logout = async (): Promise<void> => {
  return basicFetch(Endpoints.Logout, {
    method: "POST",
  });
};

export const deleteAccount = async (): Promise<void> => {
  return basicFetch(Endpoints.DeleteAccount, {
    method: "DELETE",
  });
};
