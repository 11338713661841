import { useContext, useEffect } from "react";
import io from "socket.io-client";
import { severBaseURL } from "../../api";
import { magic } from "../../api/query";
import { OsEventType } from "../../types";
import { AppStore } from "../Store";
import { SocketMessageType } from "../../interfaces";

const URI = process.env.NODE_ENV === "production" ? "/socket.io" : "/socket.io";
const isDev = process.env.NODE_ENV !== "production";

const socket = io(severBaseURL, {
  path: URI,
  withCredentials: true,
});

export const useSocket = () => {
  const { setUser, setChat, setData, setSettings, setSnapshots } =
    useContext(AppStore);

  useEffect(() => {
    const onConnect = () => {
      if (isDev) console.log("onConnect");
    };

    const onDisconnect = () => {
      if (isDev) console.log("onDisconnect");
    };

    const onMessage = (data) => {
      if (isDev) console.log("onMessage", data);

      switch (data.taskName) {
        // Very dirty but cheap.
        case "create_file_overview":
        case "create_overview":
        case "delete_file":
        case "toggle_file_state":
        case "upload_files":
        case "parse_files": {
          magic({
            event: OsEventType.State,
          }).then((response) => {
            console.log("Prompt Response: ", response);

            const user = response?.["user"] ?? null;
            if (user != null) {
              setUser(user);
            }

            setSettings(response?.["settings"] ?? null);
            setChat(response?.["chat"] ?? []);
            setSnapshots(response?.["snapshots"] ?? []);
            setData(response?.["state"] ?? []);
          });
        }
      }
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on(SocketMessageType.TaskProgress, onMessage);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off(SocketMessageType.TaskProgress, onMessage);
    };
  }, [setChat, setData, setSettings, setSnapshots]);

  return socket;
};

export default useSocket;
