import { sleep } from "../utils/chunkify";
import { ActionType, OsEventType } from "../types";
import { IParserItem } from "../parsers";
import { basicFetch } from "./index";

export enum QueryEndpoints {
  Sync = "sync",
  Query = "query",
  ParserLink = "parser/link",
  Magic = "magic",
  Complete = "complete",
  Init = "init",
  Terminate = "terminate",
  Group = "group",
  DisableFile = "disable_file",
  DeleteFile = "delete_file",
  UploadFiles = "upload_files",
  MagicFile = "magic_file",
  Test = "test",
}

export interface ISyncParams {
  path: string;
}

// curl -X POST -H 'Content-Type: application/json' -d '{"path":"/home/domainflag/Desktop/cindi/app/cache/6a0374ac-6e5d-4c4e-8446-a3c54e384cbf.json"}' http://localhost:5000/api/sync
export const sync = async (params: ISyncParams) => {
  return basicFetch(QueryEndpoints.Sync, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export interface IQueryParams {
  query: string;
}

// curl -X POST -H 'Content-Type: application/json' -d '{"query":"xy traian chivriga incubator"}' http://localhost:5000/api/query
export const query = async (params: IQueryParams) => {
  return basicFetch(QueryEndpoints.Query, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export interface IParseLinkParams {
  path: string;
}

export const parseLinkAsync = async (params: IParseLinkParams) => {
  return basicFetch(QueryEndpoints.ParserLink, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export interface IAction {
  type: ActionType;
  value: string;
  description: string;
}

export interface IMagicResponse {
  title: string;
  body: string;
  description: string;
  actions: IAction[];
  chat?: any[];
}

export interface IMagicPrompt {
  raw: string;
}

export interface IMagicRequest {
  event: OsEventType;
  key?: string | number;
  item?: IParserItem;
  prompt?: IMagicPrompt;
  createdAt?: string;
  forced?: boolean;
}

// curl -X POST -H 'Content-Type: application/json' -d '{"event":"Download", "item": {}}' http://localhost:5000/api/magic
export const magic = async (params: IMagicRequest): Promise<IMagicResponse> => {
  return basicFetch(QueryEndpoints.Magic, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export interface IMagicAutoComplete {
  query: string;
}

export const magicAutoComplete = async (
  params: IMagicAutoComplete
): Promise<any> => {
  return basicFetch(QueryEndpoints.Complete, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export interface IAppInitRequest {
  path: string;
  check: boolean;
}

export interface IAppInitResponse {
  ready: boolean;
}

// curl -X POST -H 'Content-Type: application/json' -d '{"path":"", "check": true}' http://localhost:5000/api/init
export const initializeApp = async (
  params: IAppInitRequest,
  logger?: (value: string) => void
): Promise<IAppInitResponse> => {
  let timeout = 0;
  for (let g = 0; g < 20; g++) {
    timeout += g * 1000;

    await sleep(timeout);

    try {
      return await basicFetch(QueryEndpoints.Init, {
        method: "POST",
        body: JSON.stringify(params),
      });
    } catch (e) {
      if (logger) logger(`Retrying: ${g} ${timeout}`);
    }
  }
};

export interface IGroupRequestParams {
  path: string;
}

export interface IGroupResponse {
  path: string;
}

// curl -X POST -H 'Content-Type: application/json' -d '{"path":"bundle.json"}' http://localhost:5000/api/group
export const group = async (
  params: IGroupRequestParams
): Promise<IGroupResponse> => {
  return basicFetch(QueryEndpoints.Group, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

// curl -X POST http://localhost:5000/api/terminate`
export const terminateApp = async (): Promise<void> => {
  return basicFetch(QueryEndpoints.Terminate, {
    method: "POST",
  }).then(() => Promise.resolve());
};

export interface IUploadFilesResponse {
  upload_session_id?: string;
  upload_session_expire_in?: string;
}

export const uploadFiles = async (
  formData: FormData
): Promise<IUploadFilesResponse> => {
  return basicFetch(QueryEndpoints.UploadFiles, {
    method: "POST",
    body: formData,
    headers: {
      // Override default JSON headers.
      "Access-Control-Allow-Credentials": "*",
    },
  });
};

export interface IMagicFileRequest {
  file_id: string;
  action_type: number;
}

export const magicFile = async (params: IMagicFileRequest): Promise<void> => {
  return basicFetch(QueryEndpoints.MagicFile, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export const testAPI = async (): Promise<void> => {
  return basicFetch(QueryEndpoints.Test, {
    method: "GET",
  }).then(() => Promise.resolve());
};

export interface IFileRequest {
  file_id: string;
}

export interface IDeleteFileResponse {
  status: boolean;
  message: string;
}

export const deleteFile = async (
  params: IFileRequest
): Promise<IDeleteFileResponse> => {
  return basicFetch(QueryEndpoints.DeleteFile, {
    method: "DELETE",
    body: JSON.stringify(params),
  });
};

export interface IDisableFileResponse {
  status: boolean;
  message: string;
}

export const toggleFileStatus = async (
  params: IFileRequest
): Promise<IDisableFileResponse> => {
  return basicFetch(QueryEndpoints.DisableFile, {
    method: "POST",
    body: JSON.stringify(params),
  });
};
