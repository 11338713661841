import React, { useState, useEffect } from "react";
import { Messages } from "../../../types";
import { MessageStatus } from "../../../interfaces";

import "./style.sass";

export const AppProgress = () => {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (!window.ipcRenderer) return;

    const listener = (event, arg) => {
      if (arg.status !== MessageStatus.Pending) {
        setState(null);
      } else {
        setState({
          index: arg.index,
          total: arg.total,
          message: arg.message,
        });
      }
    };

    window.ipcRenderer.on(Messages.Progress, listener);

    return () => {
      window.ipcRenderer.removeListener(Messages.Progress, listener);
    };
  }, [setState]);

  if (!state) {
    return null;
  }

  const perc = (state.index / state.total) * 100.0;
  const width = `${perc}%`;

  return (
    <div className="progress-container">
      <span className="progress-message">{state.message}</span>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={{ width }}
          aria-valuenow={perc}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  );
};

export default AppProgress;
