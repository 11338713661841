import { useMemo, useState } from "react";
import { SimpleTree } from "react-arborist";

export const useDynamicTree = (initialData) => {
  const [data, setData] = useState(initialData);
  const tree = useMemo(() => new SimpleTree(data), [data]);

  const onCreate = ({ parentId, children }) => {
    for (let g = 0; g < children.length; g++) {
      tree.create({ parentId, index: g, data: children[g] });
    }

    setData(tree.data);

    return data;
  };

  const controller = {
    setData,
    onCreate,
  };

  return [data, controller];
};
