// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-body {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  width: 100%;
  background-color: rgb(254, 251, 252);
}

.app-split-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/body/style.sass"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;EACA,oCAAA;AAFF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AADF","sourcesContent":["@import \"../../constants\"\n\n\n.app-body\n  display: flex\n  flex-direction: column\n  flex: 1\n  overflow: hidden\n  width: 100%\n  background-color: rgba(254, 251, 252, 1)\n\n.app-split-view\n  width: 100%\n  height: 100%\n  overflow: hidden"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
