import React from "react";
import {
  Outlet,
  createRouter,
  createRoute,
  createRootRoute,
  createHashHistory,
  createBrowserHistory,
} from "@tanstack/react-router";
import { IS_HASH_HISTORY_ENABLED } from "../config";

// Routes
import { Main } from "../routes/main";
import AppConfig from "../routes/config";
import AppStatus from "../routes/status";
import FileTree from "../routes/tree";
import Memory from "../routes/memory";
import AppLogin from "../routes/login";
import Home from "../routes/home";

import { AppRoute } from "../../interfaces";
import { DefaultComponent } from "../components/notfound/notfound";

const rootRoute = createRootRoute({
  component: () => (
    <AppStatus>
      <Outlet />
    </AppStatus>
  ),
  errorComponent: (props) => <DefaultComponent type="error" {...props} />,
  notFoundComponent: (props) => <DefaultComponent type="notfound" {...props} />,
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: AppRoute.Main,
  component: Main,
});

const welcomeRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: AppRoute.Welcome,
  component: Home,
});

const configRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: AppRoute.Config,
  component: AppConfig,
});

const fileTreeRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: AppRoute.Browse,
  component: FileTree,
});

const memoryRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: AppRoute.Memory,
  component: Memory,
});

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: AppRoute.Authorize,
  component: AppLogin,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  welcomeRoute,
  loginRoute,
  // configRoute,
  fileTreeRoute,
  memoryRoute,
]);

export const history = IS_HASH_HISTORY_ENABLED
  ? createHashHistory()
  : createBrowserHistory();

export const router = createRouter({
  routeTree,
  history,
});

export default router;
