import React, { useState } from "react";

// Styles
import "./style.sass";

const ToggleSwitch = ({ isOn = false, onToggle, label }) => {
  return (
    <div className="toggle-switch">
      {label && <span className="toggle-label">{label}</span>}
      <div
        className={`switch ${isOn ? "on" : "off"}`}
        onClick={onToggle}
        role="button"
        aria-pressed={isOn}
      >
        <div className="toggle-ball"></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
