import React, { useState, useEffect, useContext, useMemo } from "react";
import WelcomeView from "../../welcome/welcome";
import logo from "../../../assets/brand_icon_v3.png";
import Snapshot from "../snapshot/SnapshotView";
import { AppStore } from "../../../Store";
import { magic, testAPI } from "../../../../api/query";
import { OsEventType } from "../../../../types";
import report_icon from "../../../assets/chart_icon.svg";

// Styles
import "./style.sass";

const Table = ({ columns, rows }) => {
  return (
    <div className="table-container db">
      <div className="table">
        <div className="table-header">
          {columns.map(({ title }, index) => (
            <div key={index}>{title}</div>
          ))}
        </div>
        <div className="body">
          {rows.map((row, rowIndex) => {
            return (
              <div key={rowIndex} className="t-row">
                {columns.map((column, colIndex) => (
                  <div key={colIndex}>
                    {column.render(row[column.name], row)}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const DatabaseView = ({ model, setModel }) => {
  const { chat_state: chatState, entities } = model;
  const { setActiveSnapshot } = useContext(AppStore);
  const [stateData, setStateData] = useState(null);

  const generateOverview = ({ forced = false }) => {
    return magic({
      event: OsEventType.Status,
      forced,
    }).then((response) => {
      console.log("Prompt Response: ", response);
      setStateData(response);
    });
  };

  useEffect(() => {
    // generateOverview({});
  }, [setStateData]);

  const columns = useMemo(() => {
    try {
      const rows = [
        {
          name: "Entity",
          title: "Entity",
          render: (_, entity) => (
            <a href={entity.Link} target="_blank" rel="noopener noreferrer">
              {entity.Entity}
            </a>
          ),
        },
        {
          name: "History",
          title: "Latest Interactions",
          render: (value) => (
            <div>
              <img
                className="entity__interaction__origin__img"
                src={logo}
                alt="Cindi"
              />
              {value}
            </div>
          ),
        },
        // {
        //   name: "Status",
        //   title: "Status",
        //   render: (value, entity) => entity.Entity === "Cristian Chivriga" ? "-" : value,
        // },
        {
          name: "Snapshot",
          title: "Snapshot Cache",
          render: (value) => {
            if (value == null) return null;

            return (
              <div className="snapshot__container">
                {/*{value.results.map((snapshot) => (*/}
                {/*  <img*/}
                {/*    className="entity-snapshot-result"*/}
                {/*    src={*/}
                {/*      snapshot.favicon.includes("http")*/}
                {/*        ? snapshot.favicon*/}
                {/*        : `https://${snapshot.favicon}`*/}
                {/*    }*/}
                {/*    alt={snapshot.domain}*/}
                {/*    onClick={() => {*/}
                {/*      setActiveSnapshot(snapshot);*/}
                {/*    }}*/}
                {/*  />*/}

                {/*  <OverlayTrigger*/}
                {/*    key={snapshot.url}*/}
                {/*    overlay={<Tooltip>{snapshot.domain}</Tooltip>}*/}
                {/*  >*/}
                {/*    <a*/}
                {/*      href={snapshot.url}*/}
                {/*      target="_blank"*/}
                {/*      rel="noopener noreferrer"*/}
                {/*    >*/}
                {/*      <img*/}
                {/*        className="entity-snapshot-result"*/}
                {/*        src={*/}
                {/*          snapshot.favicon.includes("http")*/}
                {/*            ? snapshot.favicon*/}
                {/*            : `https://${snapshot.favicon}`*/}
                {/*        }*/}
                {/*        alt={snapshot.domain}*/}
                {/*        onClick={() => {*/}
                {/*          setActiveSnapshot(snapshot);*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    </a>*/}
                {/*  </OverlayTrigger>*/}
                {/*))}*/}
                {/*<div className="entity__snapshot__expand">*/}
                {/*  <img*/}
                {/*    className="entity__snapshot"*/}
                {/*    src={snapshot}*/}
                {/*    alt="Snapshot"*/}
                {/*  />*/}
                {/*  <span>More (6+)</span>*/}
                {/*</div>*/}
              </div>
            );
          },
        },
      ];

      if (
        !Object.values(chatState).some(
          // eslint-disable-next-line no-prototype-builtins
          (obj) => obj.hasOwnProperty("crawl") && obj["crawl"].length > 0
        )
      ) {
        return rows;
      }

      return [
        ...rows,
        {
          name: "Scraping",
          title: "Snapshots",
          render: (value) => value,
        },
      ];
    } catch (e) {
      console.log(e);
      return [];
    }
  }, [chatState]);

  const rows = useMemo(() => {
    return Object.entries(chatState ?? {})
      .map(([key, { name, chat, crawl = "", snapshot }]) => {
        return chat.map(({ req: { raw: reply } }, idx) =>
          idx === 0
            ? {
                Entity: name,
                Link: key,
                History: reply,
                Scraping: crawl,
                Status: "Cold Lead",
                Snapshot: snapshot,
              }
            : {
                Entity: "",
                History: reply,
                Scraping: "",
                Status: "",
                Snapshot: null,
              }
        );
      })
      .flat();
  }, [chatState]);

  return (
    <div className="view-container">
      <WelcomeView />
      <Snapshot />
      <div className="view-container-overview">
        <button
          className="btn-overview"
          onClick={() => {
            generateOverview({ forced: true }).finally(() => {
              console.log("Test completed.");
            });
          }}
        >
          <img
            className="btn-overview-icon"
            src={report_icon}
            alt="Report overview"
          />
          <span>Re-generate overview</span>
        </button>
        {/*<Table columns={columns} rows={rows} />*/}
        {/*{entities &&*/}
        {/*  JSON.stringify(Object.values(entities).map((entity) => entity.profile))}*/}
        {stateData?.results && (
          <div
            dangerouslySetInnerHTML={{
              __html: stateData.results.replaceAll("\\n", ""),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DatabaseView;
