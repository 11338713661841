import { useContext, useEffect, useMemo } from "react";
import { useRouterState } from "@tanstack/react-router";
import KanbanView, { useKanbanAdapter } from "./kanban/KanbanView";
import DatabaseView from "./database/DatabaseView";
import ReportView from "./report/ReportView";
import ReportViewHeader from "./report/ReportViewHeader";
import AccountView from "./account/AccountView";
import ArtifactsView from "./artifacts/ArtifactsView";
import ArtifactViewHeader from "./artifacts/ArtifactViewHeader";
import { AppControlKeys, AppRoute } from "../../../interfaces";
import { AppStore } from "../../Store";

import "./style.sass";
import { matchUrl } from "../../utils";

export const ViewMapping = {
  [AppControlKeys.Kanban]: {
    type: AppControlKeys.Kanban,
    title: "Kanban View",
    component: (data, setData) => {
      const [model, setModel] = useKanbanAdapter(data, setData);
      return <KanbanView model={model} setModel={setModel} />;
    },
  },
  [AppControlKeys.Account]: {
    type: AppControlKeys.Account,
    title: "Account",
    match: AppRoute.Account,
    component: (data, setData) => {
      return <AccountView model={data} setModel={setData} />;
    },
  },
  [AppControlKeys.Database]: {
    type: AppControlKeys.Database,
    title: "Dashboard",
    match: AppRoute.Overview,
    component: (data, setData) => {
      return <DatabaseView model={data} setModel={setData} />;
    },
  },
  [AppControlKeys.Report]: {
    type: AppControlKeys.Report,
    title: "Biomarkers",
    match: AppRoute.Reports,
    header: (data, setData) => {
      return <ReportViewHeader model={data} setModel={setData} />;
    },
    component: (data, setData, onTriggerUploadModal) => {
      return (
        <ReportView
          model={data}
          setModel={setData}
          onTriggerUploadModal={onTriggerUploadModal}
        />
      );
    },
  },
  [AppControlKeys.Artifacts]: {
    type: AppControlKeys.Artifacts,
    title: "Lab tests & Reports",
    match: AppRoute.Artifacts,
    secondary: ({ location, documentsById }) => {
      if (location.search && location.search.id) {
        return documentsById?.[location.search.id]?.title;
      }

      return null;
    },
    header: (data, setData) => {
      return <ArtifactViewHeader model={data} setModel={setData} />;
    },
    component: (data, setData, onTriggerUploadModal) => {
      return (
        <ArtifactsView
          model={data}
          setModel={setData}
          onTriggerUploadModal={onTriggerUploadModal}
        />
      );
    },
  },
  [AppControlKeys.CreateView]: {
    type: AppControlKeys.CreateView,
    title: "Create View",
    component: () => null,
  },
};

export const MagicView = ({ onTriggerUploadModal, isHeadless = false }) => {
  const { data, setData, activeControl, appControls, toggleControl } =
    useContext(AppStore);

  const { location } = useRouterState();
  useEffect(() => {
    if (!appControls) return null;

    const appControl = Object.values(ViewMapping).find(({ match }) =>
      matchUrl(match, true, location)
    );

    if (
      activeControl != null &&
      appControl != null &&
      activeControl !== appControl.type
    )
      toggleControl(appControl.type);
  }, [location, appControls, toggleControl]);

  const view = useMemo(() => {
    return ViewMapping?.[activeControl];
  }, [activeControl]);

  if (!view) return null;

  if (isHeadless) return view.component(data, setData);

  return (
    <div className="magic-view">
      {view.header && view.header(data, setData)}
      {/*<div className="magic-view-header">{view.title}</div>*/}
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      {view.component(data, setData, onTriggerUploadModal)}
    </div>
  );
};
