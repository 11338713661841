import { createContext } from "react";

export const AppStore = createContext({
  user: null,
  notifications: [],
  workspaces: [],
  jobs: [],
  errors: [],
  config: null,
  settings: null,
  snapshots: {},
  activeSnapshot: null,
  chat: [],
  data: [],
  loaders: {},
  token: null,
  filters: {},
});

export default AppStore;
