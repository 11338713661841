import React from "react";
import { Component } from "react";
import i18next, { supportedLanguages } from "../../lang";
import arrow from "../../assets/arrow_drop_down.svg";
import language from "../../assets/language.svg";
import classNames from "classnames";

import "./style.sass";

export class Lang extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: i18next.language,
    };
  }

  changeLanguage = (language) => {
    i18next.changeLanguage(language);

    this.setState({
      lang: i18next.language,
    });
  };

  render = () => (
    <div className="lang">
      {/*<img className="lang-asset" src={language} alt="Language" />*/}
      <div className="lang-main">
        <p className="lang-name lang-primary">{this.state.lang}</p>
        <img className="lang-arrow" src={arrow} alt="Lang Selection" />
      </div>
      <div className="lang-menu">
        {i18next.languages.sort().map((lang) => (
          <p
            key={lang}
            className={classNames('lang-name', {'active': this.state.lang === lang })}
            onClick={() => this.changeLanguage(lang)}
          >
            {supportedLanguages[lang]}
          </p>
        ))}
      </div>
    </div>
  );
}
