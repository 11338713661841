import React, { useContext, useEffect, useMemo } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AppStore } from "../../Store";
import { groupBy } from "lodash";
import { useSocket } from "../../hooks/useSocket";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { getUser } from "../../../api/auth";
import { getAuthorizeUrl, runCommand } from "../../utils";
import { Messages } from "../../../types";
import { AppRoute } from "../../../interfaces";

// Assets
import brand_icon_white from "../../assets/brand_icon_white.png";

import "./style.sass";

export const AppStatus = ({ children }) => {
  const { jobs, setUser, setToken } = useContext(AppStore);
  const { location } = useRouterState();
  const navigate = useNavigate({});

  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search);
    const token = searchQuery.get("token");

    if (token) {
      setToken(token);
      localStorage.setItem("token", token);
      navigate({
        to: AppRoute.Welcome,
      });
    } else {
      getUser()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {
          navigate({
            to: getAuthorizeUrl(),
          });
        });

      runCommand(Messages.AppConfig);
    }
  }, [navigate]);

  // Initialize real-time connection with the server.
  useSocket();

  const jobGroups = useMemo(() => {
    return groupBy(jobs, ({ id }) => id);
  }, [jobs]);

  const pendingJobs = useMemo(() => {
    return Object.values(jobGroups).filter((group) =>
      group.every(({ status }) => status === -1)
    );
  }, [jobGroups]);

  const isDisabled = useMemo(() => {
    if (pendingJobs.length === 0) return null;

    return pendingJobs[0].filter(({ status }) => status === -1)[0];
  }, [pendingJobs]);

  if (isDisabled)
    return (
      <div className="status">
        <img src={brand_icon_white} alt="Cindi Icon" />
        <div className="d-flex flex-column align-items-center mt-2 w-100">
          <div className="mb-2 text-center">
            <p className="text-white">Hang on tight, getting the app ready</p>
          </div>
          <ProgressBar className="w-50" animated variant="primary" now={45} />
        </div>
      </div>
    );

  return children;
};

export default AppStatus;
