import { Action } from "../../action/action";

// Icons
import lab_research_icon from "../../../assets/menu/lab_research_icon.svg";
import add_icon from "../../../assets/add_icon.svg";

// Styles
import "./style.sass";

export const EmptyView = ({ onTriggerUploadModal }) => (
  <div className="empty-view">
    <img
      className="empty-view__icon"
      src={lab_research_icon}
      alt="Lab tests & Reports"
    />
    <p className="empty-view__title">No Lab tests uploaded yet</p>
    <p className="empty-view__description">
      Start by uploading your lab tests to get a comprehensive analysis and
      personalized recommendations
    </p>
    <Action
      className="warning"
      text="Upload Lab Test"
      size="large"
      icon={add_icon}
      onClick={() => {
          if (!onTriggerUploadModal) return;

          onTriggerUploadModal();
      }}
      alt="Add Lab Test"
    />
  </div>
);
