import { get } from "lodash";

const waitTimeMs = 10;

export const sleep = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export type AsyncJob = () => Promise<any>;

export const chunkPromises = async (
  jobs: AsyncJob[],
  chunkSize = 25,
  callback?: (data: any) => void
) => {
  const result = [];
  for (let i = 0; i < jobs.length; i += chunkSize) {
    console.log("Processing chunk", i, jobs.length);
    const chunk = jobs.slice(i, i + chunkSize);
    const [settled] = await Promise.all([
      // Download a chunk
      Promise.allSettled(
        chunk.map(async (job) => {
          return Promise.resolve(job());
        })
      ),

      // Make sure at least a few ms passes before the next chunk is processed.
      sleep(waitTimeMs),
    ]);

    if (callback) {
      callback({ index: i, total: jobs.length });
    }

    // Add the chunk to the result
    result.push(...settled.map((s) => get(s, "value", {})).filter((s) => s));
  }

  return result;
};
