// Node.js localhost fails (DNS).
// export const severBaseURL = "http://127.0.0.1:5000";
import { isDevRun } from "../config";
import { getAuthorizeUrl, matchUrl } from "../ui/utils";
import { AppRoute } from "../interfaces";

// export const severBaseURL = isDevRun ? "http://domainflag.ddns.net:52822" : "https://api.cindi.md";
// export const severBaseURL = "https://api.cindi.md";
export const severBaseURL = "http://domainflag.ddns.net:52822";
export const baseUrl = `${severBaseURL}/api`;

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Credentials": "*",
};

export const defaultParams = {
  headers: defaultHeaders,
  method: "GET",
  credentials: "include",
};

export const simpleFetch = async (
  endpoint: string,
  init?: RequestInit
): Promise<Response> => {
  const path = `${baseUrl}/${endpoint}`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return fetch(path, {
    ...defaultParams,
    ...init,
  });
};

export const refresh = async (): Promise<Response> => {
  return simpleFetch(Endpoints.Refresh, {
    method: "POST",
  });
};

export const rejectionMessage =
  "Either the username or password is incorrect. Please try again.";

export const handleErrorMsg = async (response: Response) => {
  if (response.status < 400) return;

  let errMsg = null;
  try {
    const data = await response.json();
    errMsg = data.msg;
  } catch (e) {
    // Do nothing.
  }

  if (errMsg != null) throw new Error(errMsg);

  // const msg = await response.text();
  // if (msg) {
  //   throw new Error(msg);
  // }

  if (response.statusText) {
    throw new Error(response.statusText);
  }

  throw new Error("Request failed: " + response.status);
};

export const basicFetch = async <T>(
  endpoint: string,
  init?: RequestInit,
  redirect = true,
  throwReq = false
): Promise<T> => {
  if (isDevRun) console.log(endpoint, redirect, throwReq);

  const isAnonymous =
    matchUrl(AppRoute.Welcome) || matchUrl(AppRoute.Authorize);

  return simpleFetch(endpoint, init)
    .then(async (response) => {
      if (response.status === 401) {
        if (!redirect || isAnonymous) {
          if (throwReq) return Promise.reject(rejectionMessage);
          else {
            return;
          }
        }

        // Re-try again the same request.
        return refresh().then(() =>
          simpleFetch(endpoint, init).then(async (response) => {
            if (response.status == 401) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.location = getAuthorizeUrl();
              if (throwReq) return Promise.reject(rejectionMessage);
              return;
            }

            await handleErrorMsg(response);

            return response;
          })
        );
      } else {
        await handleErrorMsg(response);
      }

      return response;
    })
    .then((response) => {
      return response?.json();
    });
};

export enum Endpoints {
  User = "user",
  Authorize = "authorize",
  Logout = "logout",
  DeleteAccount = "delete_account",
  Refresh = "refresh",
}
