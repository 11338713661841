import React, { useState } from "react";
import classNames from "classnames";

// Icons
import search_black from "../../../assets/search-line.svg";

// Styles
import "./style.sass";

export const useSearchField = (props) => {
  const { placeholder } = props;
  const [searchValue, setSearchValue] = useState(false);
  const component = (
    <div className="search-root">
      <img
        className={classNames("search-filter__icon")}
        src={search_black}
        alt="Search Icon"
      />
      <input
        className="search-filter__input"
        type="text"
        value={searchValue.value}
        onChange={(event) => {
          const value = event.target.value;
          setSearchValue(value);
        }}
        placeholder={placeholder}
        aria-label="Search"
      />
    </div>
  );

  return {
      component,
      searchValue,
      setSearchValue
  }
};

export default useSearchField;
