import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

// Styles
import "./style.sass";

export const NotificationPanel = ({
  container,
  show,
  target,
  setShow,
  errors,
}) => {
  return (
    <Overlay
      rootClose
      onHide={() => setShow(false)}
      show={show}
      target={target}
      placement="bottom"
      container={container}
      containerPadding={40}
    >
      <Popover>
        <Popover.Body>
          <div className="notifications-container mb-4 gap-2">
            {errors.map(({ error }) => {
              return (
                <div className="notification">
                  <span className="m-0">{error ?? "Unknown error."}</span>
                </div>
              );
            })}
          </div>
        </Popover.Body>
      </Popover>
    </Overlay>
  );
};
