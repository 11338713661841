import { useState, useContext, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { AppStore } from "../../Store";
import { getTimeAgo, runCommand } from "../../utils";
import reaction_dislike_icon from "../../assets/reaction_dislike_icon.svg";
import reaction_like_icon from "../../assets/reaction_like_icon.svg";
import { Messages, NotificationType } from "../../../types";
import { DownloadNotification } from "./types/Download";
import { MagicNotification } from "./types/Magic";

import "./style.sass";

export const NotificationBody = (notification) => {
  const openDir = (path) => {
    runCommand(Messages.OpenDir, { path });
  };

  const openFile = (path) => {
    runCommand(Messages.OpenFile, { path });
  };

  const undo = () => {
    runCommand(Messages.Undo, { id: notification.id });
  };

  const actions = { openDir, openFile, undo };

  switch (notification.type) {
    case NotificationType.Download:
      return (
        <DownloadNotification notification={notification} actions={actions} />
      );
    case NotificationType.Magic:
      return (
        <MagicNotification notification={notification} actions={actions} />
      );
    default: {
      return null;
    }
  }
};

export const Notification = () => {
  const [timeAgo, setTimeAgo] = useState(null);
  const { notifications } = useContext(AppStore);
  console.log("Debug: ", notifications);

  const notification = notifications?.[notifications?.length - 1];

  useEffect(() => {
    if (!notification) {
      setTimeAgo(null);
      return;
    }

    const callback = () => setTimeAgo(getTimeAgo(notification.timestamp));
    callback();
    const identifier = setInterval(callback, 1000);

    return () => {
      clearInterval(identifier);
    };
  }, [notification, setTimeAgo]);

  if (!(notifications && notifications.length > 0))
    return <div className="ms-2 text-muted">-</div>;

  const body = NotificationBody(notification);

  return (
    <Card>
      {body}
      <Card.Footer className="d-flex flex-row justify-content-between align-items-center">
        <small className="notification-description">
          Last updated {timeAgo}
        </small>

        <div className="d-inline-flex align-items-center notification-description">
          <p className="m-0 me-2 fw-bold">Was this helpful?</p>
          <div className="d-flex gap-1">
            <span className="reaction-icon">
              <img src={reaction_like_icon} alt="Thumbs Up" />
            </span>
            <span className="reaction-icon">
              <img src={reaction_dislike_icon} alt="Thumbs Down" />
            </span>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default Notification;
