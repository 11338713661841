import React, { useContext, useMemo, useState } from "react";

import { BarChart } from "../../../routes/memory/components/Chart";
import { MarkerStatus } from "./useReportData";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { DocumentLink } from "../artifacts/ArtifactsView";
import classNames from "classnames";
import { getDateNowFormatted } from "../../../utils";
import { useNavigate } from "@tanstack/react-router";
import { AppStore } from "../../../Store";
import { marked } from "marked";
import { AppRoute } from "../../../../interfaces";

// Styles
import "./style.sass";

export const ReportViewDetails = ({ isVisible, onHide, parameter }) => {
  const navigate = useNavigate({});
  const [expandedCells, setExpandedCells] = useState(new Set([0]));

  const { data } = useContext(AppStore);
  const { local_files: localFiles } = data;
  const localFilesByType = useMemo(() => {
    if (localFiles == null) return {};

    return Object.groupBy(localFiles, (x) => x.type);
  }, [localFiles]);

  if (parameter == null) return;

  const getLast = (x) => (x == null || x.length === 0 ? null : x?.[x.length - 1]);
  const knowledgeBase = getLast(getLast(parameter?.reports)?.File?.other)?.matches;
  console.log(localFilesByType);
  console.log(parameter);
  console.log(knowledgeBase);

  const getKnowledgeItem = (key) => {
    const innerKey = knowledgeBase?.[key];
    if (innerKey == null) return null;

    return localFilesByType.knowledge_base?.[0].data?.[innerKey]?.["response"];
  };

  const knowledgeItem = getKnowledgeItem(parameter.value);

  return (
    <Offcanvas
      className="report-view-details"
      show={isVisible}
      onHide={() => {
        setExpandedCells(new Set([0]));
        onHide();
      }}
      scroll
      backdrop={true}
      placement="end"
    >
      <Offcanvas.Header closeButton />
      <Offcanvas.Body>
        <div className="report-view-overview">
          <p className="report-view-overview__title">{parameter.value}</p>
          {knowledgeItem && (
            <div
              className="report-view-overview__description"
              dangerouslySetInnerHTML={{
                __html: marked.parse(knowledgeItem),
              }}
            />
          )}
          {parameter.refs && (
            <p className="report-view-overview__refs">External References</p>
          )}
        </div>
        {parameter.reports.map((marker, index) => (
          <div
            key={index}
            className={classNames("card card-report-details", {
              expanded: expandedCells.has(index),
            })}
            onClick={() => {
              const getRemovedState = (state) => {
                state.delete(index);
                return new Set([...state]);
              };

              setExpandedCells((state) =>
                state.has(index)
                  ? getRemovedState(state)
                  : new Set([...state, index])
              );
            }}
          >
            <div className="card-body">
              <p className="card-header-minimized">
                {getDateNowFormatted(marker["Date"])}
              </p>
              <span className="card-report-details__floating">
                {marker["Result"]} {marker["Unit"]}
              </span>
              <div className="card-header-full">
                {index === 0 && (
                  <p className="card-report-details__header">Latest result</p>
                )}
                <p className="card-report-details__helper">
                  Updated: {getDateNowFormatted(marker["Date"])}
                </p>
                {marker["Reference Range"] && (
                  <p className="card-report-details__helper">
                    Reference:{" "}
                    <span className="card-report-details__helper accent">
                      {marker["Reference Range"]} {marker["Unit"]}
                    </span>
                  </p>
                )}
                <p className="card-report-details__helper mt-2">
                  Status:{" "}
                  <MarkerStatus value={marker["Notes/Interpretation"]} />
                </p>
                {marker["File"] && (
                  <div className="card-report-details__reference">
                    <p className="card-report-details__subheader">Reference</p>
                    <DocumentLink
                      className="small"
                      document={marker["File"]}
                      onClick={(event) => {
                        event.stopPropagation();

                        navigate({
                          to: `${AppRoute.Artifacts}?id=${marker["File"]["id"]}&mode=diagnosis`,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="card-divider" />
              <div className="card-subbody">
                {marker["Insights"] && (
                  <p className="card-subbody__description">
                    {marker["Insights"]}
                  </p>
                )}
                {parameter.chart && parameter.chart.data && (
                  <BarChart
                    className="chart-report simple mt-2"
                    chartData={parameter.chart.data}
                    chartOptions={parameter.chart.options}
                    showLegend={false}
                    showTitle={false}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ReportViewDetails;
