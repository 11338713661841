import React, { useEffect, useMemo, useState } from "react";

// Components
import FilterAction from "../filter-action/FilterAction";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";

// Icons
import lab_research_icon from "../../../assets/filter/lab_research_icon.svg";
import checkmark_icon from "../../../assets/welcome/checkmark.svg";

// Styles
import "./style.sass";

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    display: "none",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  input: () => ({ display: "none" }),
  menu: () => ({
    boxShadow: "none",
  }),
  menuList: () => ({
    padding: "8px 16px",
  }),
  option: (provided) => ({
    ...provided,
    maxWidth: 540,
    width: 280,
    "&:hover": { backgroundColor: "#F3F3F4" },
    backgroundColor: "white",
    borderRadius: 6,
    cursor: "pointer",
    padding: `4px 12px`,
    height: 36,
    overflow: "auto",
    display: "flex",
    alignItems: "center",
  }),
};

export const useSelectPicker = ({
  defaultSelectedValue,
  onSelectChange,
  options,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  useEffect(() => {
    onSelectChange(selectedValue);
  }, [selectedValue]);

  const selectedOptionIds = useMemo(() => {
    return new Set((selectedValue ?? []).map(({ value }) => value));
  }, [selectedValue]);

  const component = (
    <Dropdown
      className="select-dropdown-root"
      drop="down"
      align="start"
      autoClose="outside"
      onClick={(event) => {
        // event.stopPropagation();
      }}
    >
      <Dropdown.Toggle as="div">
        <FilterAction
          icon={lab_research_icon}
          alt="Filter by Lab Test"
          text="Test Type"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Select
          className="select-dropdown"
          autoFocus
          components={{
            IndicatorSeparator: () => null,
          }}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen={true}
          isSearchable={false}
          formatOptionLabel={(option) => (
            <div className="option-label">
              {option.label} ({option.count}){" "}
              {selectedOptionIds.has(option.value) && (
                <img
                  className="option-checked"
                  src={checkmark_icon}
                  alt="Checked"
                />
              )}
            </div>
          )}
          onChange={(newValue) => {
            setSelectedValue(
              Array.isArray(newValue) ? newValue.map((x) => x) : null
            );
          }}
          isMulti={true}
          options={options}
          styles={selectStyles}
          tabSelectsValue={false}
          value={selectedValue}
        />
      </Dropdown.Menu>
    </Dropdown>
  );

  return {
    component,
    selectedValue,
  };
};

export default useSelectPicker;
