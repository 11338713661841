// Styles
import "./style.sass";

export const FilterAction = ({ onClickCallback, icon, alt, text }) => {
  return (
    <div className="filter-action" onClick={onClickCallback}>
      <img className="filter-icon" src={icon} alt={alt} />
      <span className="filter-text">{text}</span>
    </div>
  );
};

export default FilterAction;
