import React from "react";
import classNames from "classnames";
import { Link } from "@tanstack/react-router";
import Button from "react-bootstrap/Button";

// Styles
import "./style.sass";

export const Action = ({
  children,
  className,
  btnClassName,
  disabled,
  size = "normal", // small, normal, large
  to,
  icon,
  alt,
  onClick,
  text,
}) => {
  const RootView = ({ children }) =>
    to ? (
      <Link
        className={classNames("btn-action-root btn-link", className)}
        to={to}
      >
        {children}
      </Link>
    ) : (
      children
    );

  return (
    <RootView>
      <Button
        className={classNames("btn-container", btnClassName, size, {
          [className]: to == null,
          "btn-action-root": to == null,
        })}
        disabled={disabled}
        onClick={onClick}
        type="submit"
        variant="dark"
      >
        {icon && <img className="btn-icon" src={icon} alt={alt} />}
        {children ? children : text}
      </Button>
    </RootView>
  );
};

export default Action;
