import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import { AppControlKeys, enumValues } from "../../../interfaces";
import { AppStore } from "../../Store";

// Assets
import view_kanban from "../../assets/views/view_kanban.svg";
import database from "../../assets/views/database.svg";
import add_view from "../../assets/views/add.svg";

// Styles
import "./style.sass";

export const AppBtn = ({
  id,
  children,
  className,
  count,
  onClick,
  isActive,
  isDisabled,
}) => {
  const isCountIndicatorEnabled = !!count && count > 0;
  return (
    <div
      id={id}
      className={classNames("player-btn", className, {
        active: isActive,
        disabled: isDisabled,
      })}
      onClick={onClick}
    >
      {children}
      {isCountIndicatorEnabled && (
        <span className="count-indicator">{count}</span>
      )}
    </div>
  );
};

export const useAppControls = () => {
  const [appControls, setAppControls] = useState(
    enumValues(AppControlKeys).reduce(
      (acc, value) => ({
        ...acc,
        [value]: {
          isActive: value === AppControlKeys.Report,
        },
      }),
      {}
    )
  );

  const toggleControl = useCallback(
    (controlKey) => {
      setAppControls((state) => ({
        ...Object.fromEntries(
          Object.entries(state).map(([key, values]) => [
            key,
            {
              ...values,
              isActive: false,
            },
          ])
        ),
        [controlKey]: {
          ...state[controlKey],
          isActive: !state[controlKey].isActive,
        },
      }));
    },
    [setAppControls]
  );

  const activeControl = useMemo(() => {
    const control = Object.entries(appControls).find(
      ([_, value]) => !!value.isActive
    );

    return Number(control?.[0]);
  }, [appControls]);

  return [appControls, activeControl, toggleControl];
};

export const AppControls = () => {
  const { appControls, toggleControl } = useContext(AppStore);
  const isAppControlsEnabled = false;

  if (!isAppControlsEnabled) return null;

  return (
    <div className="app-controls">
      {/*<AppBtn*/}
      {/*  isActive={appControls[AppControlKeys.Kanban].isActive}*/}
      {/*  onClick={() => toggleControl(AppControlKeys.Kanban)}*/}
      {/*>*/}
      {/*  <img src={view_kanban} alt="Kanban View" />*/}
      {/*</AppBtn>*/}
      <AppBtn
        isActive={appControls[AppControlKeys.Report].isActive}
        onClick={() => toggleControl(AppControlKeys.Report)}
      >
        <img src={view_kanban} alt="Report View" />
      </AppBtn>
      <AppBtn
        isActive={appControls[AppControlKeys.Database].isActive}
        onClick={() => toggleControl(AppControlKeys.Database)}
      >
        <img src={database} alt="Database View" />
      </AppBtn>
      <AppBtn
        className="floating-btn"
        isActive={appControls[AppControlKeys.CreateView].isActive}
        onClick={() => toggleControl(AppControlKeys.CreateView)}
      >
        <img src={add_view} alt="Add View" />
      </AppBtn>
    </div>
  );
};

export default AppControls;
