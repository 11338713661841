import Button from "react-bootstrap/Button";
import { useContext } from "react";
import { AppStore } from "../../Store";
import AppProgress from "../progress";

import "./style.css";

export const Footer = () => {
  const { notifications } = useContext(AppStore);
  const isMobile = !navigator.userAgent.includes("MobileApp");

  if (isMobile) return null;

  return (
    <div className="footer py-2">
      {/*<Button variant="link">History ({notifications?.length ?? 0})</Button>*/}
      {/*<Button variant="link">Recover</Button>*/}

      {isMobile && <div></div>}

      <AppProgress />
    </div>
  );
};

export default Footer;
