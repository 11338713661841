import React, { useEffect, useState } from "react";
import classNames from "classnames";

// Components
import { DayPicker } from "react-day-picker";
import FilterAction from "../filter-action/FilterAction";

// Icons
import calendar_icon from "../../../assets/filter/calendar_icon.svg";

// Styles
import "react-day-picker/style.css";
import "./style.sass";
import Dropdown from "react-bootstrap/Dropdown";
import lab_research_icon from "../../../assets/filter/lab_research_icon.svg";
import { ModalActions } from "../../modal/modal";
import { ToggleMetadata } from "@restart/ui/Dropdown";

export const currentTime = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

export const dateFilterTypes = [
  {
    type: "Today",
    value: "Today",
    from: new Date(currentTime.getTime()),
    to: new Date(currentTime.getTime()),
  },
  {
    type: "LastWeek",
    value: "Last Week",
    from: new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000),
    to: new Date(currentTime.getTime()),
  },
  {
    type: "LastMonth",
    value: "Last Month",
    from: new Date(currentTime.getTime() - 31 * 24 * 60 * 60 * 1000),
    to: new Date(currentTime.getTime()),
  },
  {
    type: "LastYear",
    value: "Last Year",
    from: new Date(currentTime.getTime() - 365 * 24 * 60 * 60 * 1000),
    to: new Date(currentTime.getTime()),
  },
];

export const getDateRange = (selected) => {
  const fromStr = selected.from.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  if (selected.from === selected.to) return fromStr;

  const toStr = selected.to.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return `${fromStr} - ${toStr}`;
};

export const CalendarPicker = (props) => {
  const { defaultSelected, onSelectChange } = props;
  const [selected, setSelected] = useState(defaultSelected);
  const [show, setShow] = useState(false);
  const [stagedSelected, setStagedSelected] = useState(defaultSelected);
  const handleSelect = (newSelected) => {
    setStagedSelected(newSelected);
  };

  useEffect(() => {
    onSelectChange(selected);
  }, [selected]);

  return (
    <>
      <Dropdown
        className="calendar-dropdown-root"
        show={show}
        drop="down"
        align="start"
        autoClose="outside"
        onToggle={(nextShow, meta) => {
          if (!nextShow) setShow(false);
        }}
        onClick={(event) => {
          // event.stopPropagation();
        }}
      >
        <Dropdown.Toggle
          as="div"
          onClick={() => {
            setShow(true);
          }}
        >
          <FilterAction
            icon={calendar_icon}
            alt="Filter by Date"
            text={selected != null ? getDateRange(selected) : "Date of Test"}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="filter-options">
            {dateFilterTypes.map((filterType) => (
              <div
                key={filterType.type}
                onClick={() => {
                  setStagedSelected((selected) =>
                    selected === filterType ? null : filterType
                  );
                }}
                className={classNames("filter-option", {
                  active:
                    selected &&
                    selected.from.getTime() === filterType.from.getTime() &&
                    selected.to.getTime() === filterType.to.getTime(),
                })}
              >
                {filterType.value}
              </div>
            ))}
          </div>
          <DayPicker
            mode="range"
            selected={stagedSelected}
            onSelect={handleSelect}
            styles={{
              table: {
                maxWidth: "none",
              },
            }}
          />
          <ModalActions
            btnClassName="small"
            secondaryAction="Clear"
            secondaryActionCallback={() => {
              setStagedSelected(null);
            }}
            primaryAction="Apply"
            primaryActionCallback={() => {
              setSelected(stagedSelected);
              setShow(false);
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CalendarPicker;
