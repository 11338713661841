import React, { useEffect } from "react";
import getCaretCoordinates from "textarea-caret";
import { useMemo, useState } from "react";

// Styles
import "./style.sass";
import { magicAutoComplete } from "../../../api/query";

import { useRef } from "react";

export const usePrevious = ({ value }) => {
  const currentRef = useRef(value);
  const previousRef = useRef();
  if (currentRef.current !== value) {
    previousRef.current = currentRef.current;
    currentRef.current = value;
  }
  return previousRef.current;
};

export const getActiveToken = (input, cursorPosition) => {
  const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
    const previous = acc[index - 1];
    const start = index === 0 ? index : previous.range[1] + 1;
    const end = start + word.length;

    return acc.concat([{ word, range: [start, end] }]);
  }, []);

  if (cursorPosition === undefined) {
    return undefined;
  }

  const activeToken = tokenizedQuery.find(
    ({ range }) => range[0] < cursorPosition && range[1] >= cursorPosition
  );

  return activeToken;
};

export const replaceAt = (str, replacement, index, length = 0) => {
  const prefix = str.substr(0, index);
  const suffix = str.substr(index + length);

  return prefix + replacement + suffix;
};

export const isValidTwitterUsername = (username) => {
  return /^@\w{1,15}$/.test(username);
};

export const useAutocomplete = (props) => {
  const [state, setState] = useState(() => ({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: "",
    activeItemId: null,
    status: "idle",
  }));

  const autocomplete = useMemo(
    () => ({
      ...props,
      setIsOpen: (shouldOpen) =>
        setState((state) => ({
          ...state,
          isOpen: shouldOpen,
        })),
      onStateChange(params) {
        props.onStateChange?.(params);
        setState(params.state);
      },
      setCollections(collections) {
        setState((state) => ({ ...state, collections }));
      },
    }),
    []
  );

  return { autocomplete, state };
};

export const AutoComplete = ({ inputRef, query, setQuery }) => {
  const getCaretPosition = () => {
    const selection = document.getSelection();
    const element = inputRef.current;
    if (!selection || !element) return null;
    if (selection.rangeCount === 0)
      return null;

    selection.collapseToEnd();
    const range = selection.getRangeAt(0);
    const clone = range.cloneRange();
    clone.selectNodeContents(element);
    clone.setEnd(range.startContainer, range.startOffset);
    return clone.toString().length;
  };

  const getCaretPos = () => {
    return inputRef.current?.selectionEnd || getCaretPosition() || 0;
  };

  const caretPos = usePrevious({ value: getCaretPos() });

  const setCaret = (offset) => {
    if (!inputRef.current) return;

    if (
      !inputRef.current.childNodes?.[0] &&
      inputRef.current.childNodes.length > 1
    )
      return;

    const range = document.createRange();
    const sel = window.getSelection();

    range.setStart(inputRef.current.childNodes[0], offset);
    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);
  };

  const { autocomplete, state } = useAutocomplete({
    id: "cindi-autocomplete",
    defaultActiveItemId: 0,
    onSelect: ({ query, item, caretPos }) => {
      const activeToken = getActiveToken(query, caretPos);

      const isValid =
        !!activeToken?.word && isValidTwitterUsername(activeToken?.word || "");

      if (isValid) {
        const [index] = activeToken.range;
        const replacement = `@${item.handle} `;
        const newQuery = replaceAt(
          query,
          replacement,
          index,
          activeToken.word.length
        );

        // setQuery(newQuery);
        inputRef.current.innerText = newQuery;
        setCaret(index + replacement.length);

        autocomplete.setIsOpen(false);
      }
    },
    getSources: ({ activeToken }) => {
      magicAutoComplete({
        query: activeToken.word.slice(1),
        params: {
          hitsPerPage: 8,
        },
      }).then((response) => {
        autocomplete.setCollections(response);
      });
    },
  });

  function onInputNavigate(query) {
    const activeToken = getActiveToken(query, caretPos);
    const shouldOpen = isValidTwitterUsername(activeToken?.word || "");

    if (shouldOpen) {
      autocomplete.getSources({ activeToken });
    }

    autocomplete.setIsOpen(shouldOpen);
  }

  useEffect(() => {
    onInputNavigate(query);
  }, [query]);

  if (inputRef.current) {
    inputRef.current.value = query;
  }

  const { top, height } = inputRef.current
    ? getCaretCoordinates(inputRef.current, caretPos)
    : { left: 0, top: 0, height: 0 };

  return (
    <div
      className="autocomplete-panel"
      style={{
        left: `96px`,
        top: `${top + height}px`,
        display: state.isOpen ? "initial" : "none",
      }}
    >
      {state.status === "stalled" && !state.isOpen && (
        <div className="autocomplete-loading">
          <svg
            className="autocomplete-loading-icon"
            viewBox="0 0 100 100"
            fill="currentColor"
          >
            <circle
              cx="50"
              cy="50"
              r="35"
              fill="none"
              stroke="currentColor"
              strokeDasharray="164.93361431346415 56.97787143782138"
              strokeWidth="6"
            >
              <animateTransform
                attributeName="transform"
                dur="1s"
                keyTimes="0;0.40;0.65;1"
                repeatCount="indefinite"
                type="rotate"
                values="0 50 50;90 50 50;180 50 50;360 50 50"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      )}
      {state.isOpen &&
        state.collections.map(({ source, items }) => {
          return (
            <div
              key={`source-${source.sourceId}`}
              className={[
                "autocomplete-source",
                state.status === "stalled" && "autocomplete-source-stalled",
              ]
                .filter(Boolean)
                .join(" ")}
            >
              {items.length > 0 && (
                <ul className="autocomplete-items">
                  {items.map((item) => {
                    return (
                      <li
                        key={item.handle}
                        onClick={(event) => {
                          event.stopPropagation();
                          autocomplete.onSelect({ query, item, caretPos });
                        }}
                      >
                        <div
                          className={[
                            "autocomplete-item",
                            // itemProps["aria-selected"] &&
                            //   "autocomplete-item-selected",
                          ]
                            .filter(Boolean)
                            .join(" ")}
                        >
                          <AccountItem hit={item} />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
    </div>
  );
};

function AccountItem({ hit }) {
  return (
    <div className="account-body">
      <div className="account-avatar">
        <img src={hit.image} alt="" />
      </div>
      <div>
        <div className="account-name">
          <Highlight hit={hit} attribute="name" />
        </div>
        <div className="account-handle">
          @<Highlight hit={hit} attribute="handle" />
        </div>
      </div>
    </div>
  );
}

function Highlight({ hit, attribute }) {
  return (
    <>
      {hit.value}
      {/*{parseAlgoliaHitHighlight({*/}
      {/*    hit,*/}
      {/*    attribute,*/}
      {/*}).map(({value, isHighlighted}, index) => {*/}
      {/*    if (isHighlighted) {*/}
      {/*        return (*/}
      {/*            <mark key={index} className="account-highlighted">*/}
      {/*                {value}*/}
      {/*            </mark>*/}
      {/*        );*/}
      {/*    }*/}

      {/*    return <Fragment key={index}>{value}</Fragment>;*/}
      {/*})}*/}
    </>
  );
}
